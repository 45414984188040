import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import ApplicationTerms from "../../components/applicantComponents/applicationTerms";
import { ApplicationOutput } from "../../models/company.models";
import ApplicantService from "../../services/applicant";

const ApplicationTermsPage: FC = () => {
  const { internship_id } = useParams();

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  const onApply = async (successCallback: (application: ApplicationOutput) => void, errCallback: (err: Error) => void) => {
    try {
      const application = await applicantService.createApplication({ internshipId: internship_id! });
      successCallback(application);
    } catch (error) {
      errCallback(error as Error);
    }
  };

  return <ApplicationTerms onApply={onApply} />;
};

export default ApplicationTermsPage;
