import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";
import { DeleteNotificationInput, MarkAsReadNotificationInput, NotificationOutput } from "../models/notification.models";
  
  export default class NotificationService {
    private path = "notifications";

    getNotifications = async (): Promise<NotificationOutput[]> => {
      try {
        const res = await InternseaAPI.get(`${this.path}`, {});
        return res as unknown as NotificationOutput[];
      } catch (error) {
        throw Error(helpers.errorHandler(error));
      }
    };
  
    deleteNotification = async (
        args: DeleteNotificationInput
      ): Promise<boolean> => {
        try {
          const res = await InternseaAPI.delete(`${this.path}/${args.id}`, {});
          return res as unknown as boolean;
        } catch (error) {
          throw Error(helpers.errorHandler(error));
        }
    };

    markAsReadNotification = async (
        args: MarkAsReadNotificationInput
      ): Promise<boolean> => {
        try {
          const res = await InternseaAPI.put(`${this.path}/${args.id}/mark-as-read`, {}, {});
          return res as unknown as boolean;
        } catch (error) {
          throw Error(helpers.errorHandler(error));
        }
      };
  }
  