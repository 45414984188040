import {
  createRef,
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from "react";
import { ApplicationOutput } from "../../../models/company.models";
import type { ColumnsType } from "antd/es/table";
import "./earnings.scss";
import { helpers } from "../../../utils/helpers";
import { Avatar, Table } from "antd";
import { useNavigate } from "react-router-dom";

interface EarningsProps {
  earnings: ApplicationOutput[];
  paidApplicationId: string;
}

interface DataType {
  key: React.Key;
  profilePhoto: ReactNode;
  applicant: string;
  internship: string;
  paymentDate: string;
  paymentId: string;
  payment: number;
}

const Earnings: FC<EarningsProps> = ({ earnings, paidApplicationId }) => {
  const { uniqueObjectArray, getFirstLetterOfDisplayname } = helpers;
  const navigate = useNavigate();

  const refs: RefObject<HTMLDivElement>[] = earnings.map((child) =>
    createRef<HTMLDivElement>()
  );

  const [data, setData] = useState<DataType[]>([]);

  const scrollToEarning = () => {
    let matched = earnings.findIndex((child) => child.id === paidApplicationId);
    const ref = refs[matched];
    if (matched !== -1 && ref.current && paidApplicationId) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
      ref.current.parentElement?.parentElement?.classList.add("matchedEarning");
      setTimeout(() => {
        ref.current?.parentElement?.parentElement?.classList.remove(
          "matchedEarning"
        );
        navigate("/earnings");
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToEarning();
    }, 300);
  }, [earnings, paidApplicationId]);

  const setFullname = (application: ApplicationOutput) =>
    `${application.applicant.firstName} ${application.applicant.lastName}`;

  useEffect(() => {
    setData(
      earnings?.map((child, index) => ({
        key: child.id,
        profilePhoto: (
          <Avatar
            src={child?.applicant?.profilePhotoURL}
            shape="circle"
            size="small"
            ref={refs[index]}
          >
            {getFirstLetterOfDisplayname(child?.applicant?.firstName ?? "C")}
          </Avatar>
        ),
        applicant: setFullname(child),
        internship: child?.internship?.title ?? "",
        paymentDate: new Date(child.paymentDate).toDateString(),
        paymentId: child.transactionResponse ? JSON.parse(child.transactionResponse)?.id : '-',
        payment: child?.appliedPrice ?? 0,
      })) ?? []
    );
  }, [earnings]);

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "profilePhoto",
      width: "100px",
    },
    {
      title: "Applicant",
      dataIndex: "applicant",
      filterMode: "tree",
      filters: uniqueObjectArray(
        earnings.map((child) => ({
          text: setFullname(child),
          value: setFullname(child),
        }))
      ),
      onFilter: (value, record) => record.applicant === value,
      sorter: (a, b) => a.applicant.localeCompare(b.applicant),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Internship",
      dataIndex: "internship",
      filterMode: "tree",
      filters: uniqueObjectArray(
        earnings.map((child) => ({
          text: child.internship.title,
          value: child.internship.title,
        }))
      ),
      onFilter: (value, record) => record.internship === value,
      sorter: (a, b) => a.internship.localeCompare(b.internship),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Payment",
      dataIndex: "payment",
      sorter: (a, b) =>
        a.payment - b.payment,
      sortDirections: ["descend", "ascend"],
      render: (payment) => (
        <span>
          {`$ ${payment?.toFixed(2)}`}
        </span>
      )
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      sorter: (a, b) =>
        new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Payment Id",
      dataIndex: "paymentId",
    },
  ];

  return (
    <div className="earningsPageContainer">
      <div className="earningsContent boxContainer">
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Earnings;
