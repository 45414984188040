import Login from '../../components/login';
import {FC, useMemo} from 'react';
import AccountService from '../../services/account';
import {LoginInput} from '../../models/account.models';
import StorageModel from "../../httpClient/StorageModel";

const LoginPage: FC = () => {
    const AppStorage = useMemo(() => {
        return new StorageModel();
    }, []);
    const authService = useMemo(() => {
        return new AccountService();
    }, []);

    const emailCookieName = 'loginEmail';

    const formValues: LoginInput = {
        email: AppStorage?.getCookie(emailCookieName) ?? '',
        password: ''
    };

    const onLogin = async (
        formValues: LoginInput,
        rememberMe: boolean,
        successCallback: () => void,
        errCallback: (err: Error) => void
    ) => {
        try {
            const {token} = await authService.login(formValues);
            if (process.env.REACT_APP_ACCESS_TOKEN_NAME)
                AppStorage.setCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME, token);
            if (rememberMe) {
                AppStorage.setCookie(emailCookieName, formValues.email);
            }
            successCallback();
        } catch (error) {
            errCallback(error as Error);
        }
    };

    return <Login onLogin={onLogin} formValues={formValues}/>;
};

export default LoginPage;
