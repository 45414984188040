import { Tooltip } from "antd";
import { FC } from "react";
import { helpers } from "../../utils/helpers";

interface LongTextCutterProps {
  text: string;
  cuttingLimit?: number;
}

const LongTextCutter: FC<LongTextCutterProps> = ({
  text,
  cuttingLimit = 30,
}) => {
  const { longtextCutter } = helpers;

  const isNecessaryToCutting = () => text.length > cuttingLimit;

  return (
    <Tooltip title={isNecessaryToCutting() ? text : ""}>
      {longtextCutter(text, cuttingLimit)}
    </Tooltip>
  );
};

export default LongTextCutter;
