import { FC, useContext, useState } from 'react';
import './resetForgottenPassword.scss';
import * as yup from 'yup';
import { Form, Button, Input } from 'antd';
import { Formik } from 'formik';
import { Context } from '../../context/provider';
import { AlertType } from '../../context/models';

interface ResetPasswordProps {
  onSubmit: (
    password: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

interface FormValuesModel {
  password: string;
  confirmPassword: string;
}

const ResetForgottenPassword: FC<ResetPasswordProps> = ({ onSubmit }) => {
  const { Alert } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const formValues: FormValuesModel = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = () =>
    yup.object().shape({
      password: yup
        .string()
        .min(8, "Minimum 8 characters!")
        .max(32, "Maximum 32 characters!")
        .matches(
          /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must include uppercase and lowercase letters and at least one number or punctuation mark."
        )
        .required("Required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Those passwords didn’t match. Try again.")
        .required("Required"),
    });

  const onClickSubmit = (values: FormValuesModel) => {
    setLoading(true);
    onSubmit(
      values.password,
      () => {
        setLoading(false);
        Alert('Password updated successfully.', AlertType.Success);
      },
      (err) => {
        setLoading(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  return (
    <div className="resetPasswordPageContainer">
      <Formik
        initialValues={formValues}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema()}
        onSubmit={onClickSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => {
          return (
            <div className="resetForm">
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Form.Item
                  validateStatus={errors.password && touched.password ? 'error' : 'success'}>
                  <Input.Password
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}></Input.Password>
                    {touched.password && errors?.password && (
                    <div className="errorMessage">{errors?.password}</div>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={
                    errors.confirmPassword && touched.confirmPassword ? 'error' : 'success'
                  }>
                  <Input.Password
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}></Input.Password>
                    {touched.confirmPassword && errors?.confirmPassword && (
                    <div className="errorMessage">{errors?.confirmPassword}</div>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetForgottenPassword;
