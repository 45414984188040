import { BaseDto } from "./common.models";

export interface DeleteNotificationInput {
    id: string;
}

export interface MarkAsReadNotificationInput {
    id: string;
}

export interface NotificationOutput extends BaseDto {
    body: string;
    isRead: boolean;
    path: string;
}

export enum NotificationType {
    Message = 'message',
    Application = 'application',
    SeatCount = 'seat_count',
    Rating = 'rating',
    Earning = 'earning',
  }
  

export interface ReceivedSocketNotificationModel extends NotificationOutput {
    notificationType: NotificationType
}