import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Divider, Tooltip, Typography } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import InfoModal from "../../../modals/infoModal";
import {
  ApplicationOutput,
  ApplicationStatus,
  UpdateApplicationInput,
} from "../../../models/company.models";
import ApplicantProfile from "../../applicantComponents/profile";
import {
  faQuestion,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../context/provider";
import { AlertType } from "../../../context/models";
import { onUpdateButtonsProps } from "../applications";

interface ApplicantProfileViewProps {
  applications: ApplicationOutput;
  modalVisible: boolean;
  onClickCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onUpdateButtons: onUpdateButtonsProps[] | undefined;
  onUpdateApplication: (
    args: UpdateApplicationInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onReject: (
    id: string,
    rejectReason: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

enum EvaluateType {
  Like = "like",
  Dislike = "dislike",
  Maybe = "maybe",
}

const { Title } = Typography;

const ApplicantProfileView: FC<ApplicantProfileViewProps> = ({
  applications,
  modalVisible,
  onClickCancel,
  onUpdateApplication,
  onUpdateButtons,
  onReject,
}) => {
  const { Alert } = useContext(Context);
  const [gradeType, setGradeType] = useState<string>();
  const [notes, setNotes] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");

  const actionsButtonss = [
    {
      icon: faThumbsUp,
      onclick: (type: EvaluateType) =>
        handleClickGradeUpdate(applications.id, type),
      activeColor: "blue",
      tooltip: "Like",
      key: EvaluateType.Like,
    },
    {
      icon: faQuestion,
      onclick: (type: EvaluateType) =>
        handleClickGradeUpdate(applications.id, type),
      activeColor: "green",
      tooltip: "Maybe",
      key: EvaluateType.Maybe,
    },
    {
      icon: faThumbsDown,
      onclick: (type: EvaluateType) =>
        handleClickGradeUpdate(applications.id, type),
      activeColor: "red",
      tooltip: "Dislike",
      key: EvaluateType.Dislike,
    },
  ];

  const actionRender = () => {
    return (
      <div className="actionContainer">
        {applications.status === ApplicationStatus.Pending && (
          <>
            <Divider />
            <div className="actionSideContainer">
              <div className="actionSides">
                <div className="gradeContainer">
                  <Tooltip title="Applicant doesnt see this evaluation.">
                    <Title level={4}>Evaluate</Title>
                  </Tooltip>
                  <div className="actionButtons">
                    {actionsButtonss.map((button) => (
                      <Tooltip title={button.tooltip}>
                        <Button
                          icon={
                            <FontAwesomeIcon
                              icon={button.icon}
                              color={
                                gradeType === button.key
                                  ? button.activeColor
                                  : "gray"
                              }
                            />
                          }
                          onClick={() => button.onclick(button.key)}
                        ></Button>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                <div className="notesLine">
                  <textarea
                    placeholder="Evaluation is for internal use only. The applicant will not see the evaluation notes or the grades."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="richTextEditor"
                  />
                  <Button
                    disabled={!notes}
                    onClick={() =>
                      handleClickGraderNotesUpdate(applications.id, notes)
                    }
                    type="ghost"
                    className="saveReject"
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div className="actionSides">
                <Tooltip title="If you want You can add reason.">
                  <Title level={4}>Decline / Approve Notes</Title>
                </Tooltip>
                <textarea
                  placeholder="Decline / Approve Notes"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  className="richTextEditorRejectReason"
                />
                <div className="approveRejectButtonsContainer">
                  <Button
                    onClick={() => handleClickRejectReason(applications.id)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                    }}
                    className="saveReject"
                  >
                    Decline
                  </Button>
                  {onUpdateButtons?.map((button) => (
                    <Button
                      onClick={() => button.onclick(applications.id)}
                      type={button.type}
                    >
                      {button.buttonText}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleClickRejectReason = (id: string) => {
    onReject(
      id,
      rejectReason,
      () => {
        Alert("Application declined successfully.", AlertType.Success);
      },
      (err) => {
        Alert(err.message, AlertType.Error);
      }
    );
  };

  const handleClickGradeUpdate = (id: string, grade: string) => {
    setGradeType(grade);
    onUpdateApplication(
      { id: id, grade: grade },
      () => {
        Alert("Success", AlertType.Success);
      },
      (err) => {
        Alert(err.message, AlertType.Error);
      }
    );
  };
  const handleClickGraderNotesUpdate = (id: string, graderNotes: string) => {
    onUpdateApplication(
      { id: id, graderNotes: graderNotes },
      () => {
        Alert("Notes saved successfully.", AlertType.Success);
      },
      (err) => {
        Alert(err.message, AlertType.Error);
      }
    );
  };

  useEffect(() => {
    setGradeType(applications.grade);
    setNotes(applications.graderNotes);
  }, []);

  return (
    <div className="profileContainer">
      <InfoModal
        children={
          <ApplicantProfile
            children={actionRender()}
            applicantProfileInfo={applications.applicant}
          />
        }
        title={`${applications.applicant.firstName} ${applications.applicant.lastName}'s profile`}
        modalVisible={modalVisible}
        onClickCancel={onClickCancel}
      />
    </div>
  );
};

export default ApplicantProfileView;
