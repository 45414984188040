import { FC, Fragment, ReactNode, useContext } from "react";
import { Helmet } from "react-helmet";
import { Context } from "../../context/provider";

interface MetaProps {
  children: ReactNode;
  title?: string;
  description?: string;
  keywords?: string;
  activateGTag?: boolean;
}

const Meta: FC<MetaProps> = ({ children, title, description, keywords, activateGTag }) => {
  const { notificationReceivedTitleText } = useContext(Context);

  return (
    <Fragment>
      <Helmet>
        {title && <title>{notificationReceivedTitleText || title}</title>}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {/* {activateGTag && <script async src="https://www.googletagmanager.com/gtag/js?id=G-NYP208N5B3%22%3E"></script>}
        {activateGTag && <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', ${new Date()});
            gtag('config', 'G-NYP208N5B3');
          `
          }
        </script>} */}
      </Helmet>
      {children}
    </Fragment>
  );
};

export default Meta;
