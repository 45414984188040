import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "antd";
import { FC, useState } from "react";

interface ActionButtonProps {
  icon: IconProp;
  defaultColor?: string;
  onClick: () => void;
  hoverColor?: string;
  className?: string;
  title?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  icon,
  defaultColor = "var(--primary-color)",
  hoverColor = "var(--primary-color)",
  onClick,
  className = '',
  title = '',
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <Tooltip title={title}>
      <Button
        className={className}
        size="small"
        style={{ border: 'none' }}
        onClick={onClick}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        icon={
          mouseOver ? (
            <FontAwesomeIcon icon={icon} color={hoverColor} />
          ) : (
            <FontAwesomeIcon icon={icon} color={defaultColor} />
          )
        }
      />
    </Tooltip>
  );
};

export default ActionButton;
