import { FC, ReactNode, RefObject, useEffect, useRef, useState, useContext } from 'react';
import { Layout } from 'antd';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './layout.scss';
import { useLocation } from 'react-router-dom';
import { Context } from '../../context/provider';
import AppFooter from './footer';
import AppHeader from './header';
import MenuContent from './menuContent';

interface IAppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<IAppLayoutProps> = ({ children }) => {
  const { currentUser } = useContext(Context);

  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const [isPositionTopOfPage, setIsPositionTopOfPage] = useState<boolean>(true);
  const [isDeviceDesktop, setIsDeviceDesktop] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  const onScroll = () => {
    if (containerRef.current && containerRef.current.scrollTop > 150) {
      setIsPositionTopOfPage(false);
    } else {
      setIsPositionTopOfPage(true);
    }
  };

  const onClickBacktop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  const setDeviceWidth = () => {
    setIsDeviceDesktop(window.innerWidth > 1200);
    closeSidebar();
  };

  useEffect(() => {
    onClickBacktop();
  }, [location.pathname]);

  useEffect(() => {
    setDeviceWidth();
    window.onresize = setDeviceWidth;
  }, []);

  const isHeaderVisible = (): boolean => !isPositionTopOfPage || location.pathname !== '/';

  const showLightLogo = () => isPositionTopOfPage && location.pathname === '/' && isDeviceDesktop;

  const showContentUnderHeader = () => location.pathname === '/';

  const onClickBurgerMenu = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className="container" onScrollCapture={onScroll} ref={containerRef}>
      <Layout className="layout">
        <AppHeader
          isHeaderVisible={isHeaderVisible}
          showSidebar={showSidebar}
          onClickBurgerMenu={onClickBurgerMenu}
          showLightLogo={showLightLogo}
          currentUser={currentUser}
          isDeviceDesktop={isDeviceDesktop}
          closeSidebar={closeSidebar}
        />
        {showSidebar && (
          <div className="sidebar">
            <MenuContent
              showLightLogo={showLightLogo}
              currentUser={currentUser}
              isHeaderVisible={isHeaderVisible}
              isDeviceDesktop={isDeviceDesktop}
              closeSidebar={closeSidebar}
            />
          </div>
        )}
        <div className={`content ${showContentUnderHeader() && 'contentUnderHeader'}`}>
          {children}
        </div>
        <AppFooter closeSidebar={closeSidebar} />
      </Layout>
      {!isPositionTopOfPage && (
        <div className="backTop" onClick={onClickBacktop}>
          <FontAwesomeIcon icon={faCaretUp} color="var(--white)" />
        </div>
      )}
    </div>
  );
};

export default AppLayout;
