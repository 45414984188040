import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Tooltip } from "antd";
import { FC, Fragment } from "react";
import { InternshipOutput } from "../../models/company.models";
import { helpers } from "../../utils/helpers";
import LongTextCutter from "../longTextCutter";
import "./internshipCard.scss";

interface InternshipCardProps {
  body: InternshipOutput;
  onClickApply: () => void;
  marked: boolean;
  onMark: () => void;
  showActionButtons?: boolean;
}

const InternshipCard: FC<InternshipCardProps> = ({
  body,
  onClickApply,
  marked,
  onMark,
  showActionButtons = false,
}) => {
  const { getFirstLetterOfDisplayname, setTypeStyle, priceFormatter, pluralText } = helpers;

  const setHidden = (label: string) => !label && "hidden";

    return (
    <div className="internshipCardContainer" onClick={onClickApply}>
      <div className="topSection">
        <div
          className={`label ${setHidden(body?.partTimeFullTime)}`}
          style={setTypeStyle(body?.partTimeFullTime ?? "")}
        >
          {body?.partTimeFullTime || "."}
        </div>
        <div className={`label ${setHidden(body?.partTimeFullTime)}`}>
          {body?.whoCanApply || "."}
        </div>
        {showActionButtons && (
          <Fragment>
            {marked ? (
              <FontAwesomeIcon
                className="heart"
                icon={faHeart}
                color="red"
                size="2x"
                onClick={(e) => {
                  e.stopPropagation();
                  onMark();
                }}
              />
            ) : (
              <Tooltip title="Mark as Favorite">
                <img
                  src="/heart-regular.svg"
                  className="heart"
                  onClick={(e) => {
                    e.stopPropagation();
                    onMark();
                  }}
                 alt='Mark as favorite'/>
              </Tooltip>
            )}
          </Fragment>
        )}
      </div>
      <div
        className="avatarContainer"
      >
        <Avatar size="large" src={body?.company?.logoURL}>
          {getFirstLetterOfDisplayname(body?.company?.name ?? "C")}
        </Avatar>
      </div>
      <div className="internshipCardContent">
        <Tooltip title={body?.title ?? ""} placement='leftTop'>
          <div className="internshipTitle">
            {body?.title ?? ""}
          </div>
        </Tooltip>
        <div className="locationSection">
          <LongTextCutter text={body?.location ?? ""} />
        </div>
        <div className="durationSection">
          <LongTextCutter text={body?.physicalRemote ??  ""} />
          <LongTextCutter
            text={
              body.duration && body.durationUnit
                ? pluralText(body.duration, body.durationUnit, true)
                : ""
            }
          />
          <span>{body.price ? priceFormatter(body?.price) : 'Free'}</span>
        </div>
      </div>
      <div className="cardBottom">
        {showActionButtons && (
          <Fragment>
            <Button type="primary">Apply</Button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default InternshipCard;
