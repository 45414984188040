import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Tooltip } from "antd";
import { FC, Fragment } from "react";
import { EducationParsedModel } from "../../../../models/applicant.models";

interface EducationSectionProps {
  educations: EducationParsedModel[];
  onChangeEducations: (args: EducationParsedModel[]) => void;
  className?: string;
}

const EducationSection: FC<EducationSectionProps> = ({
  educations,
  onChangeEducations,
  className = "",
}) => {
  const onChange = (index: number, name: string, value: string) => {
    const existEducations: EducationParsedModel[] = educations;
    existEducations[index][name as keyof EducationParsedModel] = value;
    onChangeEducations([...existEducations]);
  };

  const onClickAddNew = () => {
    let existEducations: EducationParsedModel[] = educations;
    existEducations = [
      ...existEducations,
      { school: "", degree: "", fieldOfStudy: "" },
    ];
    onChangeEducations([...existEducations]);
  };

  const onClickDelete = (index: number) => {
    let existEducations: EducationParsedModel[] = educations;
    existEducations = existEducations.filter((_child, i) => i !== index);
    onChangeEducations([...existEducations]);
  };

  const disabledAddNew = () =>
    educations.length > 0 &&
    (educations[educations.length - 1].degree === "" ||
      educations[educations.length - 1].school === "" ||
      educations[educations.length - 1].fieldOfStudy === "");

  return (
    <Fragment>
      {educations?.map((child, index) => (
        <Form.Item className={className}>
          <Input.Group compact>
            <Form.Item className="education">
              <div>School</div>
              <Input
                placeholder="School"
                onChange={(e) => onChange(index, "school", e.target.value)}
                value={child?.school ?? ""}
              ></Input>
            </Form.Item>
            <Form.Item className="education">
              <div>Degree</div>
              <Input
                placeholder="Degree"
                onChange={(e) => onChange(index, "degree", e.target.value)}
                value={child?.degree ?? ""}
              ></Input>
            </Form.Item>
            <Form.Item className="education">
              <div>Field Of Study</div>
              <Input
                placeholder="Field Of Study"
                onChange={(e) =>
                  onChange(index, "fieldOfStudy", e.target.value)
                }
                value={child?.fieldOfStudy ?? ""}
              ></Input>
            </Form.Item>
            <Form.Item className="education">
              <div style={{ visibility: "hidden" }}>.</div>
              <Button
                onClick={() => onClickDelete(index)}
                icon={<FontAwesomeIcon icon={faTrash} color="var(--red-5)" />}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      ))}
      <Form.Item className={className}>
        <Tooltip title="Add New">
          <Button
            type="primary"
            onClick={onClickAddNew}
            className="addNew"
            disabled={disabledAddNew()}
            icon={<FontAwesomeIcon icon={faPlus} />}
          />
        </Tooltip>
      </Form.Item>
    </Fragment>
  );
};

export default EducationSection;
