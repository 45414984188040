import { ResetExistingPasswordInput } from "../../models/account.models";
import { FC, useContext, useState } from "react";
import "./resetExistPassword.scss";
import * as yup from 'yup';
import { Form, Button, Input } from 'antd';
import { Formik } from 'formik';
import { Context } from '../../context/provider';
import { AlertType } from '../../context/models';

interface ResetExistPasswordProps {
  onSubmit: (
    args: ResetExistingPasswordInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

interface FormValuesModel {
  existPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ResetExistPassword: FC<ResetExistPasswordProps> = ({ onSubmit }) => {
  const { Alert } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const formValues: FormValuesModel = {
    existPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = () =>
    yup.object().shape({
      existPassword: yup
        .string()
        .min(8, "Minimum 8 characters!")
        .max(32, "Maximum 32 characters!")
        .matches(
          /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must include uppercase and lowercase letters and at least one number or punctuation mark."
        )
        .required("Required"),
      newPassword: yup
        .string()
        .min(8, "Minimum 8 characters!")
        .max(32, "Maximum 32 characters!")
        .matches(
          /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must include uppercase and lowercase letters and at least one number or punctuation mark."
        )
        .required("Required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Those passwords didn’t match. Try again.")
        .required("Required"),
    });

  const onClickSubmit = (values: FormValuesModel) => {
    setLoading(true);
    onSubmit(
      {
        password: values.existPassword,
        newPassword: values.newPassword,
      },
      () => {
        setLoading(false);
        Alert('Password updated successfully.', AlertType.Success);
      },
      (err) => {
        setLoading(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  return (
    <div className="resetExistPasswordPageContainer">
      <Formik
        initialValues={formValues}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema()}
        onSubmit={onClickSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => {
          return (
            <div className="resetExistForm boxContainer">
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Form.Item
                  validateStatus={
                    errors.existPassword && touched.existPassword ? 'error' : 'success'
                  }>
                  <Input.Password
                    name="existPassword"
                    placeholder="Current Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.existPassword}
                  ></Input.Password>
                  {touched.existPassword && errors?.existPassword && (
                    <div className="errorMessage">{errors?.existPassword}</div>
                  )}
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={errors.newPassword && touched.newPassword ? 'error' : 'success'}>
                      <Input.Password
                        name="newPassword"
                        placeholder="New Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}></Input.Password>
                      {touched.newPassword && errors?.newPassword && (
                    <div className="errorMessage">{errors?.newPassword}</div>
                  )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.confirmPassword && touched.confirmPassword ? 'error' : 'success'
                      }>
                      <Input.Password
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}></Input.Password>
                        {touched.confirmPassword && errors?.confirmPassword && (
                    <div className="errorMessage">{errors?.confirmPassword}</div>
                  )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetExistPassword;
