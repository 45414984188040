import { FC, useContext } from "react";
import "./payment.scss";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { ApplicationOutput } from "../../../models/company.models";
import { Context } from "../../../context/provider";
import { AlertType } from "../../../context/models";
import { printError } from "../../../utils/logger";
import { useNavigate } from "react-router-dom";
import { Avatar, Typography } from "antd";
import { helpers } from "../../../utils/helpers";

interface PaymentProps {
  application: ApplicationOutput;
  afterPayment: (
    order: unknown,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const { Title } = Typography;

const Payment: FC<PaymentProps> = ({ application, afterPayment }) => {
  const { Alert } = useContext(Context);
  const navigate = useNavigate();

  const { getFirstLetterOfDisplayname } = helpers;

  const orderInfo = {
    description: `Payment for ${
      application?.internship?.title ?? ""
    } internship(${application?.internship?.company?.name ?? ""}) by ${
      application?.applicant?.firstName ?? ""
    } ${application?.applicant?.lastName ?? ""} ${application?.isFreeOfCharge ? '(Free Internship)' : ''}`,
    price: `${(application?.appliedPrice ?? 0).toFixed(2)}`,
  };

  const onApproved = (order: unknown) => {
    afterPayment(
      order,
      () => {
        Alert(
          "Your payment was successful. Your application is activated.",
          AlertType.Success
        );
        navigate(`/bookings?applicationId=${application.id}`);
      },
      (err) => {
        Alert((err as Error).message, AlertType.Warning);
      }
    );
  };

  const onError = (err: unknown) => {
    printError([err]);
    Alert("Somethings went wrong. Please try again.", AlertType.Error);
  };

  return (
    <div className="paymentPageContainer">
      <div className="paymentContent boxContainer">
        <div className="leftSide">
          <Avatar src={application?.internship?.company?.logoURL}>
            {getFirstLetterOfDisplayname(
              application?.internship?.company?.name ?? "C"
            )}
          </Avatar>
          <div className="internshipInfo">
            <Title level={2} className='internshipTitle'>
              {application?.internship?.title}
            </Title>
            <Title level={3}>
              {application?.internship?.location}
            </Title>
            <Title level={4}>
              {`$ ${orderInfo.price}`}
            </Title>
            <Title level={4}>
              {`${application?.internship?.duration} ${application?.internship?.durationUnit}`}
            </Title>
          </div>
        </div>
        <div className="rightSide">
          <PayPalButtons
            onClick={(_data, actions) => {
              if (application.isPaymentComplete) {
                Alert("You have already made payment.", AlertType.Warning);
                return actions.reject();
              } else if (new Date(application.approvalDate).getTime() <= new Date().getTime()) {
                Alert("Payment due is over(72 hours)", AlertType.Warning);
                return actions.reject();
              } else {
                return actions.resolve();
              }
            }}
            createOrder={(_data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: orderInfo.description,
                    amount: {
                      value: orderInfo.price,
                    },
                  },
                ],
                application_context: {
                  shipping_preference: "NO_SHIPPING"
                }
              });
            }}
            onApprove={async (_data, actions) => {
              const order = await actions.order?.capture();
              onApproved(order);
            }}
            onError={(err) => {
              onError(err);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
