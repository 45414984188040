import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Breadcrumb, Typography } from 'antd';
import './breadCrumb.scss';
import { Context } from '../../context/provider';
import { BreadCrumbPageModel } from '../../context/models';
import { useLocation, useNavigate } from 'react-router-dom';

interface BreadCrumbProps {
    children: ReactNode;
    name: string;
    show?: boolean;
}

const { Title } = Typography;

const PageBreadCrumb: FC<BreadCrumbProps> = ({ children, name, show = false }) => {
    const { breadCrumbPageList, setBreadCrumbPageList } = useContext(Context);
    const navigate = useNavigate();
    const [prevPage, setPrevPage] = useState<BreadCrumbPageModel>({} as BreadCrumbPageModel);
    const location = useLocation();

    useEffect(() => {
        if (breadCrumbPageList.length > 1) {
            setPrevPage(breadCrumbPageList[breadCrumbPageList.length -2])
        }
        if (location.pathname !== breadCrumbPageList[breadCrumbPageList.length - 1]?.path) {
            setBreadCrumbPageList((prevState) => ([...prevState, { name, path: location.pathname }]))
        }
    }, [location, breadCrumbPageList]);

    const showBreadCrumb = () => breadCrumbPageList.length > 1 && prevPage?.name !== name;

    const onClickPrevPage = () => {
        navigate(prevPage.path);
    };

  return (
    <div className='breadCrumbContainer'>
        {show && (
            <div className="breadCrumb">
                <Title>{name}</Title>
                {/* {showBreadCrumb() && (
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={onClickPrevPage}>
                            {prevPage.name}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                )} */}
            </div>
        )}
        <div className="content">
            {children}
        </div>
    </div>
  )
}

export default PageBreadCrumb;