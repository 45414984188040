import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Menu } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";

interface NotificationItemProps {
  onRead?: () => void;
  onDelete: () => void;
  showMarkAsRead?: boolean;
  body: string;
  createdAt: Date;
  onClick: () => void;
  path: string;
}

const NotificationItem: FC<NotificationItemProps> = ({
  onRead = () => null,
  onDelete,
  showMarkAsRead = false,
  body,
  createdAt,
  onClick,
  path,
}) => {
  let notificationButtons = [
    {
      label: "Delete",
      key: "1",
      onClick: onDelete,
    },
  ];

  if (showMarkAsRead) {
    notificationButtons.push({
      label: "Mark as Read",
      key: "0",
      onClick: onRead,
    });
  }

  const dropdownContent = <Menu items={notificationButtons} />;

  const clickHandler = () => {
    onClick();
    if (path) {
      window.location.href = path; // For mozilla browser
    }
  }

  return (
    <div className="notificationItem">
      <div onClick={clickHandler} className="notificationItemBody">
        {body}
      </div>
      <div className="notificationDate">{new Date(createdAt).toDateString()}</div>
      <div className="notificationItemAction">
        <Dropdown overlay={dropdownContent} trigger={["click"]}>
            <Button icon={<FontAwesomeIcon icon={faEllipsisVertical} size="1x" color="var(--primary-color)" />} size="small" />
        </Dropdown>
      </div>
    </div>
  );
};

export default NotificationItem;
