import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CreateUpdateBillingAccount from "../../components/companyComponents/createUpdateBillingAccount";
import PageLoader from "../../components/pageLoader";
import { Context } from "../../context/provider";
import {
  CreateCompanyAccountInput,
  UpdateCompanyAccountInput,
} from "../../models/company.models";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";
import NotFoundPage from "../notFoundPage";

const UpdateBillingAccountPage: FC = () => {
  const { currentUser } = useContext(Context);
  const { id } = useParams();

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const [inputs, setInputs] = useState({} as CreateCompanyAccountInput);
  const [loading, setLoading] = useState(true);

  const getAccount = async () => {
    try {
      const res = await companyService.getAccount({ id: id! });
      setInputs(res as CreateCompanyAccountInput);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  useEffect(() => {
    getAccount();
  }, [id]);

  const onUpdate = async (
    values: CreateCompanyAccountInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      const args: UpdateCompanyAccountInput = { ...values, id: id! };
      await companyService.updateAccount(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const onDelete = async (
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await companyService.deleteAccount({ id: id! });
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  if (loading) {
    return <PageLoader avatar active />;
  }

  if (!currentUser.isCompany) {
    return <NotFoundPage />;
  }

  return (
    <CreateUpdateBillingAccount
      inputs={inputs}
      isEditMode
      onSubmit={onUpdate}
      onDelete={onDelete}
    />
  );
};

export default UpdateBillingAccountPage;
