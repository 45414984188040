import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";
import { ApplicantInput, ApplicantOutput, CreateApplicationInput } from "../models/applicant.models";
import {
  AfterPaymentInput,
  ApplicationOutput,
  DeleteApplicationInput,
  GetApplicationInput,
  InternshipOutput,
  UpdateApplicationInput,
} from "../models/company.models";
import { RateInput, RateOutput } from "../models/rating.models";

export default class ApplicantService {
  private path = "applicants";

  getProfile = async (applicantId: string): Promise<ApplicantOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/profile/${applicantId}`, {});
      return res as unknown as ApplicantOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  isProfileCompleted = async (): Promise<boolean> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/is_profile_completed`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateProfile = async (args: ApplicantInput): Promise<ApplicantOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/profile`, args, {});
      return res as unknown as ApplicantOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getFavoriteInternshipIDs = async (): Promise<string[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/internships/favorites/ids`, {});
      return res as unknown as string[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getFavoriteInternships = async (): Promise<InternshipOutput[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/internships/favorites`, {});
      return res as unknown as InternshipOutput[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  markFavoriteInternship = async (id: string): Promise<InternshipOutput> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/internships/${id}/favorites`, {}, {});
      return res as unknown as InternshipOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  unmarkFavoriteInternship = async (id: string) => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/internships/${id}/favorites`, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getAllApplications = async (): Promise<ApplicationOutput[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/applications`, {});
      return res as unknown as ApplicationOutput[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getApplication = async (
    args: GetApplicationInput
  ): Promise<ApplicationOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/applications/${args.id}`, {});
      return res as unknown as ApplicationOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  createApplication = async (
    args: CreateApplicationInput
  ): Promise<ApplicationOutput> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/applications`, args, {});
      return res as unknown as ApplicationOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateApplication = async (
    args: UpdateApplicationInput
  ): Promise<ApplicationOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/applications/${args.id}`, args, {});
      return res as unknown as ApplicationOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  afterPayment = async (applicationId: string, args: AfterPaymentInput): Promise<boolean> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/applications/${applicationId}/after_payment`, args, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteApplication = async (
    args: DeleteApplicationInput
  ): Promise<boolean> => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/applications/${args.id}`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };
}
