import { GetInternshipsInput, InternshipOutput } from '../../models/company.models';
import Main from "../../components/main";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";
import ApplicantService from "../../services/applicant";
import { Context } from "../../context/provider";
import { AlertType } from "../../context/models";

const MainPage: FC = () => {
  const { currentUser, Alert, Confirm } = useContext(Context);

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  const navigate = useNavigate();
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [internships, setInternships] = useState<InternshipOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [favoriteInternshipIDs, setFavoriteInternshipIDs] = useState<string[]>(
    []
  );
  const internshipLength = 12;

  const getFavoriteInternshipIDs = async () => {
    try {
      const res = await applicantService.getFavoriteInternshipIDs();
      setFavoriteInternshipIDs(res);
    } catch (error) {
      printError([error]);
    }
  };

  const markUnmarkInternship = async (id: string) => {
    if (currentUser.isLoggedIn) {
      if (!currentUser.isCompany) {
        try {
          let existIds = favoriteInternshipIDs;
          if (favoriteInternshipIDs.some((child) => child === id)) {
            applicantService.unmarkFavoriteInternship(id);
            existIds = existIds.filter((x) => x !== id);
          } else {
            applicantService.markFavoriteInternship(id);
            existIds = [ ...existIds, id ];
          }
          setFavoriteInternshipIDs([ ...existIds ]);
        } catch (error) {
          Alert((error as Error).message, AlertType.Error);
        }
      } else {
        Alert(
          "The company account can't mark internship as favorite!",
          AlertType.Warning
        );
      }
    } else {
      Confirm("You must log in! Do you want to go to login page?", () => {
        navigate("/login");
      });
    }
  };

  useEffect(() => {
    if (currentUser.isLoggedIn && !currentUser.isCompany) {
      getFavoriteInternshipIDs();
    } else {
      setFavoriteInternshipIDs([]);
    }
  }, [currentUser.isLoggedIn, currentUser.isCompany]);

  const getLocationOptions = async () => {
    try {
      const res = await companyService.getLocations();
      setLocationOptions(res);
    } catch (error) {
      printError([error]);
    }
  };

  const getInternships = async () => {
    setLoading(true);
    const input: GetInternshipsInput = {
      limit: internshipLength,
      page: 1,
      isPublished: true,
      hideSeatCountFilled: true,
      showApplicationPrice: true,
    };
    try {
      const res = await companyService.getAllInternships(input);
      setLoading(false);
      setInternships(res.items);
    } catch (error) {
      printError([error]);
    }
  };

  useEffect(() => {
    getLocationOptions();
    getInternships();
  }, []);

  const onSearch = (title: string, location: string) => {
    navigate(
      `/searchInternship?title=${title}&location=${location}&duration=&durationUnit=&physicalRemote=&partTimeFullTime=&whoCanApply=&category=`
    );
  };

  return (
    <Main
      favoriteInternshipIDs={favoriteInternshipIDs}
      markUnmarkInternship={markUnmarkInternship}
      locationOptions={locationOptions}
      onSearch={onSearch}
      internships={internships}
      loading={loading}
    />
  );
};

export default MainPage;
