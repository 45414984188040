import VerifyEmail from '../../components/verifyEmail';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AccountService from '../../services/account';

const VerifyEmailPage: FC = () => {
  const { code } = useParams();
  const authService = useMemo(() => {
    return new AccountService();
  }, []);

  const verify = async (successCallback: () => void, errCallback: (err: Error) => void) => {
    try {
      await authService.verifyEmail({ verification_code: code! });
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const onSendNewVerifyEmail = async (
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await authService.sendEmailVerificationLink({ verification_code: code! });
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  return <VerifyEmail onSendNewVerifyEmail={onSendNewVerifyEmail} verify={verify} />;
};

export default VerifyEmailPage;
