import { Modal } from "antd";
import { FC, ReactNode, useState } from "react";
import "./infoModal.scss"

interface InfoModalProps {
  children: ReactNode;
  title: ReactNode;
  modalVisible: boolean;
  onClickCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

const InfoModal: FC<InfoModalProps> = ({ children, modalVisible, onClickCancel, title }) => {

  return (
    <Modal
      className="info-modal"
      title={title}
      open={modalVisible}
      onCancel={onClickCancel}
      okButtonProps={{ style: { display: 'none' } }}
    >
      {children}
    </Modal>
  );
};

export default InfoModal;
