import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FC, ReactNode } from "react";
import "./actionBox.scss";

interface ActionBoxProps {
  children: ReactNode;
  items: ItemType[];
}

const ActionBox: FC<ActionBoxProps> = ({ children, items }) => {
  return (
    <div className="actionBox">
      <Dropdown overlay={<Menu items={items} />} trigger={['click']} placement="bottomRight" >
        <Button icon={<FontAwesomeIcon icon={faEllipsisVertical} />} size="small" />
      </Dropdown>
      {children}
    </div>
  );
};

export default ActionBox;

