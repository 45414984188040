import { FC, Fragment, useState, useEffect } from 'react';
import './verifyEmail.scss';
import { Typography, Spin, Button } from 'antd';

interface VerifyEmailProps {
  onSendNewVerifyEmail: (successCallback: () => void, errCallback: (err: Error) => void) => void;
  verify: (successCallback: () => void, errCallback: (err: Error) => void) => void;
}

const { Title } = Typography;

const VerifyEmail: FC<VerifyEmailProps> = ({ onSendNewVerifyEmail, verify }) => {
  const [sending, setSending] = useState(false);
  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailVerifed, setEmailVerifed] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    setLoading(true);
    verify(
      () => {
        setLoading(false);
        setEmailVerifed(true);
        setNotification('Your email has been successfully verified.');
      },
      (err) => {
        setError(err.message);
        setLoading(false);
      }
    );
  }, []);

  const onSubmit = async () => {
    setSending(true);
    onSendNewVerifyEmail(
      () => {
        setSending(false);
        setNotification('New verification email sent again. Please check your email inbox.');
      },
      (err) => {
        setSending(false);
        setError(err.message);
      }
    );
  };

  return (
    <div className="verifyEmailPageContainer">
      {loading ? (
        <Spin size="large" />
      ) : (
        <Fragment>
          {!emailVerifed && (
            <Button
              type="primary"
              loading={sending}
              disabled={sending}
              onClick={onSubmit}
              size="large">
              Send new email verification link
            </Button>
          )}
          {notification && <Title level={3}>{notification}</Title>}
          {error && <Title level={3}>{error}</Title>}
        </Fragment>
      )}
    </div>
  );
};

export default VerifyEmail;
