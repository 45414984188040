import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";
import { CreateMessageInput, MessageDto } from "../models/message.models";

export default class MessageService {
  private path = "messages";

  createMessage = async (args: CreateMessageInput): Promise<MessageDto> => {
    try {
      const res = await InternseaAPI.post(`${this.path}`, args, {});
      return res as unknown as MessageDto;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getMessages = async (): Promise<MessageDto[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}`, {});
      return res as unknown as MessageDto[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getMessage = async (id: string): Promise<MessageDto> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/${id}`, {});
      return res as unknown as MessageDto;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteMessage = async (id: string) => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/${id}`, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  markAsReadMessage = async (id: string): Promise<MessageDto> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/${id}/mark-as-read`, {}, {});
      return res as unknown as MessageDto;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };
}
