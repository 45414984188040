import { FC, ReactNode, useContext } from "react";
import { Image, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../context/provider";
import "./profileSidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey, faUser } from "@fortawesome/free-solid-svg-icons";

interface ProfileSidebarProps {
  children: ReactNode;
}

const ProfileSidebar: FC<ProfileSidebarProps> = ({ children }) => {
  const { currentUser } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const subMenuList = [
    {
      name: "Profile",
      path: "/profile",
      icon: faUser,
    },
    {
      name: "Change Password",
      path: "/change-password",
      icon: faKey,
    },
  ];

  if (currentUser.isCompany) {
    subMenuList.push({
      name: "Billing Settings",
      path: "/change-billings-settings",
      icon: faUser,
    });
  }

  const onClickSubMenu = (path: string) => {
    navigate(path);
  };

  const isPageActive = (path: string) => location.pathname === path;

  return (
    <div className="profileSidebarContainer">
      <div className="profileSidebar">
        <div className="imageContainer">
          <Image width={250} height={250} src={currentUser.photoUrl || "/avatar.webp"} />
          <div className="displayName">
            {currentUser.displayName || "Unknow User"}
          </div>
        </div>
        <div className="buttons">
          {subMenuList?.map((child, index) => (
            <Button
              key={index}
              type={isPageActive(child.path) ? "primary" : "default"}
              onClick={() => onClickSubMenu(child.path)}
              block
              icon={<FontAwesomeIcon icon={child.icon} />}
            >
              {child.name}
            </Button>
          ))}
        </div>
      </div>
      <div className="profileSidebarContent">{children}</div>
    </div>
  );
};

export default ProfileSidebar;
