import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";
import {
  ApplicationOutput,
  CompanyAccountOutput,
  CompanyOutput,
  CreateCompanyAccountInput,
  CreateInternshipInput,
  DeleteApplicationInput,
  DeleteCompanyAccountInput,
  DeleteInternshipInput,
  GetApplicationInput,
  GetCompanyAccountInput,
  GetInternshipInput,
  InternshipOutput,
  UpdateApplicationInput,
  UpdateCompanyAccountInput,
  CompanyInput,
  UpdateInternshipInput,
  GetInternshipsInput,
  PublishInternshipInput,
  GetInternshipQuery,
} from "../models/company.models";
import { PaginationOutput } from "../models/common.models";
import { RateInput } from "../models/rating.models";

export default class CompanyService {
  private path = "company";

  getProfile = async (): Promise<CompanyOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/profile`, {});
      return res as unknown as CompanyOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  isProfileCompleted = async (): Promise<boolean> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/is_profile_completed`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateProfile = async (
    args: CompanyInput
  ): Promise<CompanyOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/profile`, args, {});
      return res as unknown as CompanyOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getAllInternships = async (args: GetInternshipsInput): Promise<PaginationOutput<InternshipOutput[]>> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/internships`, {
        params: args
      });
      return res as unknown as PaginationOutput<InternshipOutput[]>;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getInternship = async (
    args: GetInternshipInput, query?: GetInternshipQuery
  ): Promise<InternshipOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/internships/${args.id}`, { params: query });
      return res as unknown as InternshipOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getLocations = async (): Promise<string[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/internships/locations`, {});
      return res as unknown as string[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  createInternship = async (
    args: CreateInternshipInput
  ): Promise<InternshipOutput> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/internships`, args, {});
      return res as unknown as InternshipOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateInternship = async (
    args: UpdateInternshipInput
  ): Promise<InternshipOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/internships/${args.id}`, args, {});
      return res as unknown as InternshipOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  publishInternship = async (
    args: PublishInternshipInput
  ): Promise<boolean> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/internships/${args.id}/publish`, args, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteInternship = async (
    args: DeleteInternshipInput
  ): Promise<boolean> => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/internships/${args.id}`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getAllAccounts = async (): Promise<CompanyAccountOutput[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/accounts`, {});
      return res as unknown as CompanyAccountOutput[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getAccount = async (
    args: GetCompanyAccountInput
  ): Promise<CompanyAccountOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/accounts/${args.id}`, {});
      return res as unknown as CompanyAccountOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  createAccount = async (
    args: CreateCompanyAccountInput
  ): Promise<CompanyAccountOutput> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/accounts`, args, {});
      return res as unknown as CompanyAccountOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateAccount = async (
    args: UpdateCompanyAccountInput
  ): Promise<CompanyAccountOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/accounts/${args.id}`, args, {});
      return res as unknown as CompanyAccountOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  makeDefaultAccount = async (id: string): Promise<boolean> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/accounts/${id}/make-default`, {}, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteAccount = async (
    args: DeleteCompanyAccountInput
  ): Promise<boolean> => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/accounts/${args.id}`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getAllApplications = async (): Promise<ApplicationOutput[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/applications`, {});
      return res as unknown as ApplicationOutput[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getEarnings = async (): Promise<ApplicationOutput[]> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/earnings`, {});
      return res as unknown as ApplicationOutput[];
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getApplication = async (
    args: GetApplicationInput
  ): Promise<ApplicationOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}/applications/${args.id}`, {});
      return res as unknown as ApplicationOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateApplication = async (
    args: UpdateApplicationInput
  ): Promise<ApplicationOutput> => {
    try {
      const res = await InternseaAPI.put(`${this.path}/applications/${args.id}`, args, {});
      return res as unknown as ApplicationOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  sendReminderEmailForApplication = async (applicationId: string): Promise<boolean> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/applications/${applicationId}/reminder`, {}, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteApplication = async (
    args: DeleteApplicationInput
  ): Promise<boolean> => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/applications/${args.id}`, {});
      return res as unknown as boolean;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };
}
