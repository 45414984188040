import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import {
  DatePicker,
  DatePickerProps,
  Divider,
  Steps,
  Tooltip,
  UploadFile,
  UploadProps,
} from "antd";
import { Form, Button, Input, Select, Upload, Typography } from "antd";
import * as yup from "yup";
import { Context } from "../../../context/provider";
import { CompanyOutput, CompanyInput } from "../../../models/company.models";
import "./editCompanyProfile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faInfo,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AlertType } from "../../../context/models";
import { helpers } from "../../../utils/helpers";
import moment from "moment";
import { companySizes, organizationTypes } from "../../../utils/constants";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/lib/upload";
import LocationInput from "../../locationInput";
import { useNavigate, useSearchParams } from "react-router-dom";

interface EditCompanyProfileProps {
  companyProfileInfo: CompanyOutput;
  onUpdateCompanyProfile: (
    args: CompanyInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const { Option } = Select;

const { Title } = Typography;

const { Step } = Steps;

const EditCompanyProfile: FC<EditCompanyProfileProps> = ({
  companyProfileInfo,
  onUpdateCompanyProfile,
}) => {
  const [searchParams] = useSearchParams();

  const { convertFiletoBase64 } = helpers;
  const { selectOptionsWithPlaceholderValue } = helpers;
  const { Alert, setCurrentUserInfoUpdated, Confirm } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [organizationType, setOrganizationType] = useState(
    companyProfileInfo?.type ?? ""
  );
  const [size, setSize] = useState(companyProfileInfo?.size ?? "");
  const [logo, setLogo] = useState<null | UploadFile>(null);
  const [establishmentYear, setEstablishmentYear] = useState(
    companyProfileInfo?.establishmentYear
  );
  const [city, setCity] = useState(companyProfileInfo?.city);
  const navigate = useNavigate();

  useEffect(() => {
    setCity(companyProfileInfo?.city);
  }, [companyProfileInfo?.city]);

  useEffect(() => {
    setEstablishmentYear(companyProfileInfo?.establishmentYear);
  }, [companyProfileInfo?.establishmentYear]);

  useEffect(() => {
    setSize(companyProfileInfo?.size ?? "");
  }, [companyProfileInfo?.size]);

  useEffect(() => {
    setOrganizationType(companyProfileInfo?.type ?? "");
  }, [companyProfileInfo?.type]);

  const validationSchema = () =>
    yup.object().shape({
      facebook: yup.string().nullable().max(250, "Maximum 250 characters!"),
      instagram: yup.string().nullable().max(250, "Maximum 250 characters!"),
      linkedIn: yup.string().nullable().max(250, "Maximum 250 characters!"),
      name: yup
        .string()
        .required("Required")
        .min(3, "Minimum 3 characters!")
        .max(250, "Maximum 250 characters!"),
      phone: yup.string().nullable().max(25, "Maximum 25 characters!"),
      streetAddress1: yup
        .string()
        .nullable()
        .max(250, "Maximum 100 characters!"),
      streetAddress2: yup
        .string()
        .nullable()
        .max(250, "Maximum 100 characters!"),
      twitter: yup.string().nullable().max(250, "Maximum 250 characters!"),
      website: yup.string().nullable().max(250, "Maximum 250 characters!"),
      zipCode: yup.string().nullable().max(10, "Maximum 10 characters!"),
      description: yup.string().nullable(),
    });

  const onClickSubmit = async (values: CompanyOutput) => {
    setLoading(true);
    values = {
      ...values,
      type: organizationType,
      size,
      city,
      establishmentYear,
    };
    if (logo) {
      const base64 = await convertFiletoBase64(logo.originFileObj);
      values = { ...values, logo: base64 as string };
    }
    onUpdateCompanyProfile(
      values as unknown as CompanyInput,
      () => {
        setLoading(false);
        setCurrentUserInfoUpdated((prevState) => !prevState);
        Alert("Your profile was updated successfully.", AlertType.Success);
        const redirection_path = searchParams?.get("redirection_path");
        if (redirection_path) {
          navigate(redirection_path);
        }
      },
      (err) => {
        setLoading(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  const onClickDeleteLogo = async () => {
    Confirm("Are you sure?", () => {
      const values = { ...companyProfileInfo, logo: "delete" };
      onUpdateCompanyProfile(
        values as unknown as CompanyInput,
        () => {
          setCurrentUserInfoUpdated((prevState) => !prevState);
          Alert("Logo deleted successfully.", AlertType.Success);
        },
        (err) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isValid =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isValid) {
        Alert(`${file.name} is not a valid file`, AlertType.Warning);
      }
      return isValid || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.fileList.length > 0) {
        const file = info.fileList[0];
        setLogo(file);
      }
    },
    onPreview: async (file) => {
      let src = file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    onRemove: () => {
      setLogo(null);
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        (onSuccess as Function)("ok");
      }, 0);
    },
    accept: "image/png, image/jpeg, image/jpg",
    maxCount: 1,
    listType: "picture-card",
  };

  const onChangeEstablishmentYear: DatePickerProps["onChange"] = (
    _date,
    dateString
  ) => {
    setEstablishmentYear(Number(dateString));
  };

  const setSteps = (values: CompanyOutput) => {
    const companyInfo =
      values.name &&
      values.phone &&
      organizationType &&
      values.establishmentYear &&
      size &&
      values.website &&
      values.description;
    const companyAddress =
      values.city && values.zipCode;
    const setStatus = (arg: any): "finish" | "wait" =>
      arg ? "finish" : "wait";
    return [setStatus(companyInfo), setStatus(companyAddress)];
  };

  return (
    <div className="editCompanyProfileContainer">
      <div className="editProfileContent boxContainer">
        <Formik
          initialValues={companyProfileInfo}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={validationSchema()}
          onSubmit={onClickSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => {
            return (
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Steps>
                  <Step
                    title="Company Info"
                    icon={<FontAwesomeIcon icon={faInfo} />}
                    status={setSteps(values)[0]}
                  />
                  <Step
                    title="Company Address"
                    icon={<FontAwesomeIcon icon={faAddressCard} />}
                    status={setSteps(values)[1]}
                  />
                </Steps>
                <Divider />
                <Form.Item>
                  <div className="formHeader">
                    <Title>Company Info</Title>
                  </div>
                </Form.Item>
                <Form.Item
                  validateStatus={
                    errors.name && touched.name ? "error" : "success"
                  }
                >
                  <div>
                    Company Name{" "}
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                        className="formRequiredStar"
                        icon={faStar}
                        color="var(--red-5)"
                      />
                    </Tooltip>
                  </div>
                  <Input
                    name="name"
                    placeholder="Company Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  ></Input>
                  {touched.name && errors?.name && (
                    <div className="errorMessage">{errors?.name}</div>
                  )}
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.phone && touched.phone ? "error" : "success"
                      }
                    >
                      <div>
                        Phone{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Input
                        name="phone"
                        placeholder="Contact No"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      ></Input>
                      {touched.phone && errors?.phone && (
                        <div className="errorMessage">{errors?.phone}</div>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <div>
                        Organization Type{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Select
                        showSearch
                        placeholder="Organization Type"
                        onChange={(value) => setOrganizationType(value)}
                        value={organizationType}
                      >
                        {selectOptionsWithPlaceholderValue(
                          organizationTypes,
                          "Select Organization Type"
                        )?.map((child) => (
                          <Option value={child.value}>{child.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.establishmentYear && touched.establishmentYear
                          ? "error"
                          : "success"
                      }
                    >
                      <div>
                        Establishment Year{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <DatePicker
                        value={
                          establishmentYear
                            ? moment(establishmentYear, "YYYY")
                            : undefined
                        }
                        format="YYYY"
                        picker="year"
                        onChange={onChangeEstablishmentYear}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div>
                        Company Size{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Select
                        placeholder="Company Size"
                        onChange={(value) => setSize(value)}
                        value={size}
                      >
                        {selectOptionsWithPlaceholderValue(
                          companySizes,
                          "Select Company Size"
                        )?.map((child) => (
                          <Option value={child.value}>{child.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item>
                      <div>
                        Company Logo{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <div className="logoUploadSection">
                        <ImgCrop rotate>
                          <Upload {...uploadProps}>
                            {!logo && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                        {companyProfileInfo?.logoURL && (
                          <Button
                            type="primary"
                            onClick={onClickDeleteLogo}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.facebook && touched.facebook
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Facebook Account</div>
                      <Input
                        name="facebook"
                        placeholder="Facebook"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebook}
                      ></Input>
                      {touched.facebook && errors?.facebook && (
                        <div className="errorMessage">{errors?.facebook}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.twitter && touched.twitter ? "error" : "success"
                      }
                    >
                      <div>Twitter Account</div>
                      <Input
                        name="twitter"
                        placeholder="Twitter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.twitter}
                      ></Input>
                      {touched.twitter && errors?.twitter && (
                        <div className="errorMessage">{errors?.twitter}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.linkedIn && touched.linkedIn
                          ? "error"
                          : "success"
                      }
                    >
                      <div>LinkedIn Account</div>
                      <Input
                        name="linkedIn"
                        placeholder="LinkedIn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.linkedIn}
                      ></Input>
                      {touched.linkedIn && errors?.linkedIn && (
                        <div className="errorMessage">{errors?.linkedIn}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.instagram && touched.instagram
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Instagram Account</div>
                      <Input
                        name="instagram"
                        placeholder="Instagram"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instagram}
                      ></Input>
                      {touched.instagram && errors?.instagram && (
                        <div className="errorMessage">{errors?.instagram}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.website && touched.website ? "error" : "success"
                      }
                    >
                      <div>
                        Website{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Input
                        name="website"
                        placeholder="Website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                      ></Input>
                      {touched.website && errors?.website && (
                        <div className="errorMessage">{errors?.website}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  validateStatus={
                    errors.description && touched.description
                      ? "error"
                      : "success"
                  }
                >
                  <div>
                    Description{" "}
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                        className="formRequiredStar"
                        icon={faStar}
                        color="var(--red-5)"
                      />
                    </Tooltip>
                  </div>
                  <textarea
                    placeholder="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="richTextEditor"
                  />
                  {touched.description && errors?.description && (
                    <div className="errorMessage">{errors?.description}</div>
                  )}
                </Form.Item>
                <Divider />
                <Form.Item>
                  <Title>Company Address</Title>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item>
                      <div>
                        Location{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <LocationInput
                        value={city}
                        onChange={(val) => setCity(val)}
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.zipCode && touched.zipCode ? "error" : "success"
                      }
                    >
                      <div>
                        Zip Code{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Input
                        name="zipCode"
                        placeholder="Zip Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode}
                        className='zipCode'
                      ></Input>
                      {touched.zipCode && errors?.zipCode && (
                        <div className="errorMessage">{errors?.zipCode}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditCompanyProfile;
