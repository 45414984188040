import { Button, Empty, Pagination, Tabs, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { InternshipOutput } from "../../../models/company.models";
import ActionBox from "../../actionBox";
import InternshipListItem from "../../internshipListItem";
import "./internships.scss";

interface InternshipsProps {
  internships: InternshipOutput[];
  totalCount: number;
  showPublishedOnes: (published: boolean) => void;
  onSkip: (pageIndex: number) => void;
  pageLimit: number;
  onPublish: (internshipId: string, publish: boolean) => void;
  onDelete: (id: string) => void;
}

const { Title } = Typography;

const Internships: FC<InternshipsProps> = ({
  internships,
  totalCount,
  showPublishedOnes,
  onSkip,
  pageLimit,
  onPublish,
  onDelete,
}) => {
  const navigate = useNavigate();

  const onClickListItem = (id: string) => {
    navigate(`/internships/${id}`);
  };

  const onClickCreateInternship = () => {
    navigate("/create-internship");
  };

  const onClickUpdateInternship = (id: string) => {
    navigate(`/update-internship/${id}`);
  };

  const onClickDelete = (id: string) => {
    onDelete(id);
  };

  return (
    <div className="internshipsContainer">
      <div className="listContainer boxContainer">
        <div className="listHeader">
          <Title level={2}>Internship Listings</Title>
          <Button
            onClick={onClickCreateInternship}
            className="addNewInternship"
          >
            + Add New Internship
          </Button>
        </div>
        <div className="paginationContainer">
          <Pagination
            total={totalCount}
            defaultPageSize={pageLimit}
            showSizeChanger={false}
            defaultCurrent={1}
            onChange={(page) => onSkip(page - 1)}
          />
        </div>
        <Tabs
          defaultActiveKey="1"
          onChange={(activeKey) => showPublishedOnes(activeKey === "1")}
        >
          <Tabs.TabPane tab="Published" key="1">
            <div className="tabContainer">
              {internships?.length === 0 && <Empty description='Not found any published internship.' />}
              {internships.map((child) => (
                <ActionBox
                  items={[
                    {
                      key: "1",
                      label: <span>Preview</span>,
                      onClick: () => onClickListItem(child.id),
                    },
                    {
                      key: "2",
                      label: <span>Edit</span>,
                      onClick: () => onClickUpdateInternship(child.id),
                    },
                    {
                      key: "3",
                      label: <span>Un-publish</span>,
                      onClick: () => onPublish(child.id, false),
                    },
                    {
                      key: "4",
                      label: <span>Delete</span>,
                      onClick: () => onClickDelete(child.id),
                    },
                  ]}
                >
                  <InternshipListItem
                    body={child}
                    onClick={() => onClickListItem(child.id)}
                    showLogo={false}
                    cutLongTexts
                  />
                </ActionBox>
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Unpublished" key="2">
            <div className="tabContainer">
              {internships?.length === 0 && <Empty description='Not found any unpublished internship.' />}
              {internships.map((child) => (
                <ActionBox
                  items={[
                    {
                      key: "1",
                      label: <span>Preview</span>,
                      onClick: () => onClickListItem(child.id),
                    },
                    {
                      key: "2",
                      label: <span>Edit</span>,
                      onClick: () => onClickUpdateInternship(child.id),
                    },
                    {
                      key: "3",
                      label: <span>Publish</span>,
                      onClick: () => onPublish(child.id, true),
                    },
                  ]}
                >
                  <InternshipListItem
                    body={child}
                    onClick={() => onClickListItem(child.id)}
                    showLogo={false}
                  />
                </ActionBox>
              ))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Internships;
