import {
  faCalendar,
  faCircle,
  faClock,
  faInfinity,
  faMapMarked,
  faMoneyBill,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { FC, Fragment } from "react";
import { InternshipOutput } from "../../models/company.models";
import { RateOutput, RateUpdate } from "../../models/rating.models";
import { helpers } from "../../utils/helpers";
import PageLoader from "../pageLoader";
import RatingReviews from "../ratingReviews";
import "./internship.scss";

interface InternshipProps {
  internship: InternshipOutput;
  onApply: () => void;
  onSendMessage?: () => void;
  showSendMessage?: boolean;
  ratings: RateOutput;
  loadingReviews: boolean;
  onUpdateRate: (
    args: RateUpdate,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onDeleteRate: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const { Title, Text } = Typography;

const Internship: FC<InternshipProps> = ({
  loadingReviews,
  ratings,
  internship,
  onApply,
  onSendMessage = () => null,
  showSendMessage = false,
  onUpdateRate,
  onDeleteRate,
}) => {
  const { priceFormatter } = helpers;

  const responsibilities: string[] = internship?.responsibilities
    ? JSON.parse(internship?.responsibilities)
    : [];
  const keyBenefits: string[] = internship?.whatYouWillLearn
    ? JSON.parse(internship?.whatYouWillLearn)
    : [];

  return (
    <Fragment>
      <div className="internshipContainer">
        <div className="summary">
          <Card title="Internship Summary">
            <div className="summaryGroups">
              <div>
                <Title level={5}>
                  <FontAwesomeIcon icon={faClock} />
                  Type
                </Title>
                <Text>
                  <b>{internship?.partTimeFullTime ?? ""}</b>
                </Text>
                <Title level={5}>
                  <FontAwesomeIcon icon={faInfinity} />
                  Category
                </Title>
                <Text>
                  <b>{internship?.category ?? ""}</b>
                </Text>
              </div>
              <div>
                <Title level={5}>
                  <FontAwesomeIcon icon={faCalendar} />
                  Internship Type
                </Title>
                <Text>
                  <b>{internship?.physicalRemote ?? ""}</b>
                </Text>
                <Title level={5}>
                  <FontAwesomeIcon icon={faUser} />
                  Applicant Type
                </Title>
                <Text>
                  <b>{internship?.whoCanApply ?? ""}</b>
                </Text>
              </div>
              <div>
                <Title level={5}>
                  <FontAwesomeIcon icon={faClock} />
                  Working Hours/Week
                </Title>
                <Text>
                  <b>{`${internship?.weeklyWorkHours} Hour(s)`}</b>
                </Text>
                <Title level={5}>
                  <FontAwesomeIcon icon={faCalendar} />
                  Duration
                </Title>
                <Text>
                  <b>
                    {`${internship?.duration ?? 0} ${
                      internship?.durationUnit ?? ""
                    }`}
                  </b>
                </Text>
              </div>
              <div>
                <Title level={5}>
                  <FontAwesomeIcon icon={faMapMarked} />
                  Location
                </Title>
                <Text>
                  <b>{internship?.location ?? ""}</b>
                </Text>
                <Title level={5}>
                  <FontAwesomeIcon icon={faMoneyBill} />
                  Fee
                </Title>
                <Text>
                  <b>{priceFormatter(internship?.price)}</b>
                </Text>
              </div>
            </div>
            <Divider />
            <div className="summaryButtonGroups">
              <Button type="primary" onClick={onApply}>
                Apply Now
              </Button>
              {showSendMessage && (
                <Button
                  type="default"
                  onClick={onSendMessage}
                  className="sendMessageButton"
                >
                  Send Message To Company
                </Button>
              )}
            </div>
          </Card>
        </div>
        <div className="internship">
          <div className="internshipContent boxContainer">
            <Row>
              <Col span={24}>
                <Title level={2}>{internship?.title ?? ""}</Title>
                <Title level={4} className="companyName">
                  <span className="by">{"by "}</span>{" "}
                  {internship?.company?.name ?? ""}
                </Title>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Title level={2}>Position Summary</Title>
                <div className="summaryContent">
                  <Title level={5}>{internship?.summary ?? ""}</Title>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Title level={2}>Responsibilities/Tasks</Title>
                {responsibilities?.map((child, index) => (
                  <Title level={5} className="li">
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="sm"
                      className="bulletPoint"
                      color="var(--primary-color)"
                    />
                    {child}
                  </Title>
                ))}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Title level={2}>Key Benefits</Title>
                {keyBenefits?.map((child, index) => (
                  <Title level={5} className="li">
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="sm"
                      className="bulletPoint"
                      color="var(--primary-color)"
                    />
                    {child}
                  </Title>
                ))}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Title level={2}>About Company</Title>
                <div className="summaryContent">
                  <Title level={5}>
                    {internship?.company?.description ?? ""}
                  </Title>
                </div>
              </Col>
            </Row>
            <Divider />
            <div className="summaryButtonGroups">
              <Button type="primary" onClick={onApply}>
                Apply Now
              </Button>
              {showSendMessage && (
                <Button
                  type="default"
                  onClick={onSendMessage}
                  className="sendMessageButton"
                >
                  Send Message To Company
                </Button>
              )}
            </div>
          </div>
          {loadingReviews ? (
            <PageLoader active avatar numOfSkeleton={3} />
          ) : (
            <RatingReviews
              showApplicantProfile
              ratings={ratings}
              onUpdate={onUpdateRate}
              onDelete={onDeleteRate}
            />
          )}
        </div>
      </div>
      
    </Fragment>
  );
};

export default Internship;
