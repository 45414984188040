import { Typography, Form, Input, Button, Select, Tooltip, Checkbox } from "antd";
import {
  createRef,
  FC,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { CreateInternshipInput } from "../../../models/company.models";
import "./createUpdateInternship.scss";
import { Context } from "../../../context/provider";
import { useNavigate } from "react-router-dom";
import { AlertType } from "../../../context/models";
import ListCreator from "../../listCreator";
import { helpers } from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  freeOfCharge,
  internshipCategoryOptions,
  internshipDurationUnitOptions,
  internshipPartTimeFullTimeOptions,
  internshipPhysicalRemoteOptions,
  internshipWhoCanApplyOptions,
} from "../../../utils/constants";
import LocationInput from "../../locationInput";

interface CreateUpdateInternshipProps {
  onSubmit: (
    args: CreateInternshipInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  inputs: CreateInternshipInput;
  isEditMode?: boolean;
}

const { Title } = Typography;

const { Option } = Select;

interface FormInputModel {
  physicalRemote: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  weeklyWorkHours: {
    value: number;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  duration: {
    value: number;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  price: {
    value: number;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  seatCount: {
    value: number;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  durationUnit: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  category: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  whoCanApply: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  partTimeFullTime: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  title: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  location: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
  summary: {
    value: string;
    ref: RefObject<HTMLDivElement>;
    isEmpty: boolean;
    errorOrder: number;
  };
}

const CreateUpdateInternship: FC<CreateUpdateInternshipProps> = ({
  onSubmit,
  inputs,
  isEditMode = false,
}) => {
  const { Alert, currentUser } = useContext(Context);
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({} as FormInputModel);

  useEffect(() => {
    setFormInputs({
      title: {
        value: inputs.title,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 1,
      },
      location: {
        value: inputs.location,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 2,
      },
      physicalRemote: {
        value: inputs.physicalRemote,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 3,
      },
      duration: {
        value: inputs.duration || 1,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 4,
      },
      durationUnit: {
        value: inputs.durationUnit,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 5,
      },
      whoCanApply: {
        value: inputs.whoCanApply,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 6,
      },
      partTimeFullTime: {
        value: inputs.partTimeFullTime,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 7,
      },
      price: {
        value: inputs.price || 100,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 8,
      },
      weeklyWorkHours: {
        value: inputs.weeklyWorkHours,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 9,
      },
      seatCount: {
        value: inputs.seatCount || 1,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 10,
      },
      category: {
        value: inputs.category,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 11,
      },
      summary: {
        value: inputs.summary,
        ref: createRef<HTMLDivElement>(),
        isEmpty: false,
        errorOrder: 12,
      },
    });
  }, [inputs]);

  const [submitting, setSubmitting] = useState(false);

  const [parsedResponsibilities, setParsedResponsibilities] = useState<
    string[]
  >([""]);
  const [parsedWhatYouWillLearn, setParsedWhatYouWillLearn] = useState<
    string[]
  >([""]);

  const [isFreeOfCharge, setIsFreeOfCharge] = useState(false);

  const [responsibilitiesRef, setResponsibilitiesRef] = useState({
    isEmpty: false,
    ref: createRef<HTMLDivElement>(),
  });

  const [whatYouWillLearnRef, setWhatYouWillLearnRef] = useState({
    isEmpty: false,
    ref: createRef<HTMLDivElement>(),
  });

  const [areResponsibilitiesFilled, setAreResponsibilitiesFilled] =
    useState(false);
  const [isWhatYouWillLearnFilled, setIsWhatYouWillLearnFilled] =
    useState(false);

  const { selectOptionsWithPlaceholderValue } = helpers;

  useEffect(() => {
    if (inputs.isFreeOfCharge != null) {
      setIsFreeOfCharge(inputs.isFreeOfCharge)
    }
  }, [inputs.isFreeOfCharge])

  useEffect(() => {
    setIsWhatYouWillLearnFilled(
      parsedWhatYouWillLearn.length > 0 &&
        parsedWhatYouWillLearn.every((child) => child !== "")
    );
  }, [parsedWhatYouWillLearn]);

  useEffect(() => {
    setAreResponsibilitiesFilled(
      parsedResponsibilities.length > 0 &&
        parsedResponsibilities.every((child) => child !== "")
    );
  }, [parsedResponsibilities]);

  useEffect(() => {
    if (inputs?.responsibilities) {
      const parsed = JSON.parse(inputs.responsibilities) as string[];
      setParsedResponsibilities([...parsed]);
    }
  }, [inputs?.responsibilities]);

  useEffect(() => {
    if (inputs?.whatYouWillLearn) {
      const parsed = JSON.parse(inputs.whatYouWillLearn) as string[];
      setParsedWhatYouWillLearn([...parsed]);
    }
  }, [inputs?.whatYouWillLearn]);

  const isFormValid = () => {
    let isValid = true;
    const formKeys = Object.keys(formInputs);
    let invalidFields = formKeys.filter(
      (key) => !formInputs[key as keyof typeof formInputs].value
    );

    if (isFreeOfCharge) {
      invalidFields = invalidFields.filter((child) => child !== 'price')
    }

    if (invalidFields.length > 0) {
      isValid = false;

      const minErrorOrder = Math.min(
        ...invalidFields.map(
          (child) => formInputs[child as keyof typeof formInputs].errorOrder
        )
      );
      const minInvalidField = formKeys.find(
        (key) =>
          formInputs[key as keyof typeof formInputs].errorOrder ===
          minErrorOrder
      );

      if (minInvalidField) {
        setFormInputs((prevState) => ({
          ...prevState,
          [minInvalidField]: {
            ...prevState[minInvalidField as keyof typeof formInputs],
            isEmpty: true,
          },
        }));

        setTimeout(() => {
          setFormInputs((prevState) => ({
            ...prevState,
            [minInvalidField]: {
              ...prevState[minInvalidField as keyof typeof formInputs],
              isEmpty: false,
            },
          }));
        }, 2000);

        const fieldRef =
          formInputs[minInvalidField as keyof typeof formInputs].ref;
        fieldRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else if (!areResponsibilitiesFilled) {
      isValid = false;

      responsibilitiesRef?.ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setResponsibilitiesRef((prevState) => ({
        ...prevState,
        isEmpty: true,
      }));

      setTimeout(() => {
        setResponsibilitiesRef((prevState) => ({
          ...prevState,
          isEmpty: false,
        }));
      }, 2000);
    } else if (!isWhatYouWillLearnFilled) {
      isValid = false;

      whatYouWillLearnRef?.ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setWhatYouWillLearnRef((prevState) => ({
        ...prevState,
        isEmpty: true,
      }));

      setTimeout(() => {
        setWhatYouWillLearnRef((prevState) => ({
          ...prevState,
          isEmpty: false,
        }));
      }, 2000);
    }

    return isValid;
  };

  const onClickSubmit = () => {
    if (!isFormValid()) return;

    setSubmitting(true);
    inputs = {
      ...inputs,
      physicalRemote: formInputs.physicalRemote.value,
      weeklyWorkHours: formInputs.weeklyWorkHours.value,
      duration: formInputs.duration.value,
      price: isFreeOfCharge ? 0 : formInputs.price.value,
      seatCount: formInputs.seatCount.value,
      durationUnit: formInputs.durationUnit.value,
      category: formInputs.category.value,
      whoCanApply: formInputs.whoCanApply.value,
      partTimeFullTime: formInputs.partTimeFullTime.value,
      title: formInputs.title.value,
      location: formInputs.location.value,
      summary: formInputs.summary.value,
      responsibilities: JSON.stringify(parsedResponsibilities),
      whatYouWillLearn: JSON.stringify(parsedWhatYouWillLearn),
      isPublished: currentUser.isProfileCompleted,
      isFreeOfCharge,
    };
    onSubmit(
      inputs,
      () => {
        let alertMessage: string;
        let alertType: AlertType = AlertType.Success;
        let navigationPath = "/internships";
        if (isEditMode) {
          if (currentUser.isProfileCompleted) {
            alertMessage = "Your internship was updated successfully."
          } else {
            alertMessage = "Your internship was updated successfully. It is un-published. You must complete the company profile to publish your internship."
            alertType = AlertType.Warning;
          }
        } else {
          if (currentUser.isProfileCompleted) {
            alertMessage = "Congratulations! Your internship is published successfully.";
          } else {
            alertMessage = "Congratulations! Your internship is created successfully. It is un-published. You must complete the company profile to publish your internship.";
            alertType = AlertType.Warning;
            navigationPath = `/profile?redirection_path=/internships`;
          }
        }
        Alert(alertMessage, alertType);
        navigate(navigationPath);
      },
      (error) => {
        Alert(error.message, AlertType.Error);
        setSubmitting(false);
      }
    );
  };

  const onChange = (key: keyof typeof formInputs, value: string | number) => {
    setFormInputs((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };

  const setMinValueForInputNumber = (
    key: keyof typeof formInputs,
    minValue = 1
  ) => {
    setFormInputs((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value:
          Number(prevState[key].value) < Number(minValue) || !prevState[key].value
            ? minValue
            : prevState[key].value,
      },
    }));
  };

  return (
    <div className="createUpdateInternshipContainer">
      <div className="formContainer boxContainer">
        <Form
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 20, offset: 2 }}
          initialValues={{ remember: true }}
        >
          <Form.Item
            validateStatus={formInputs?.title?.isEmpty ? "error" : "success"}
          >
            <div ref={formInputs?.title?.ref}>
              Internship Title{" "}
              <Tooltip title="Required">
                <FontAwesomeIcon
                  className="formRequiredStar"
                  icon={faStar}
                  color="var(--red-5)"
                />
              </Tooltip>
            </div>
            <Input
              name="title"
              placeholder="Internship Title"
              onChange={(e) => onChange("title", e.target.value)}
              value={formInputs?.title?.value}
            ></Input>
            {formInputs?.title?.isEmpty && (
              <div className="errorMessage">Required</div>
            )}
          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item
                validateStatus={
                  formInputs?.location?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.location?.ref}>
                  Location{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <LocationInput
                  value={inputs.location}
                  onChange={(val) => onChange("location", val)}
                  placeholder="Location"
                />
                {formInputs?.location?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
              <Form.Item
                validateStatus={
                  formInputs?.physicalRemote?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.physicalRemote?.ref}>
                  Physical/Remote{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Physical/Remote"
                  onChange={(value) => onChange("physicalRemote", value)}
                  value={formInputs?.physicalRemote?.value}
                >
                  {selectOptionsWithPlaceholderValue(
                    internshipPhysicalRemoteOptions,
                    "Select One"
                  )?.map((child) => (
                    <Option value={child.value}>{child.label}</Option>
                  ))}
                </Select>
                {formInputs?.physicalRemote?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item
                validateStatus={
                  formInputs?.duration?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.duration?.ref}>
                  Duration{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Input
                  onChange={(e) => onChange("duration", e.target.valueAsNumber)}
                  onBlur={() => {
                    setMinValueForInputNumber("duration");
                  }}
                  name="duration"
                  placeholder="Duration"
                  type="number"
                  value={formInputs?.duration?.value}
                  min={1}
                ></Input>
                {formInputs?.duration?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
              <Form.Item
                validateStatus={
                  formInputs?.durationUnit?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.durationUnit?.ref}>
                  Duration Unit{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Duration Unit"
                  onChange={(value) => onChange("durationUnit", value)}
                  value={formInputs?.durationUnit?.value}
                >
                  {selectOptionsWithPlaceholderValue(
                    internshipDurationUnitOptions,
                    "Select One"
                  )?.map((child) => (
                    <Option value={child.value}>{child.label}</Option>
                  ))}
                </Select>
                {formInputs?.durationUnit?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item
                validateStatus={
                  formInputs?.whoCanApply?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.whoCanApply?.ref}>
                  Who Can Apply?{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Who Can Apply"
                  onChange={(value) => onChange("whoCanApply", value)}
                  value={formInputs?.whoCanApply?.value}
                >
                  {selectOptionsWithPlaceholderValue(
                    internshipWhoCanApplyOptions,
                    "Select One"
                  )?.map((child) => (
                    <Option value={child.value}>{child.label}</Option>
                  ))}
                </Select>
                {formInputs?.whoCanApply?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
              <Form.Item
                validateStatus={
                  formInputs?.partTimeFullTime?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.partTimeFullTime?.ref}>
                  Part-Time/Full-Time{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Part-Time/Full-Time"
                  onChange={(value) => onChange("partTimeFullTime", value)}
                  value={formInputs?.partTimeFullTime?.value}
                >
                  {selectOptionsWithPlaceholderValue(
                    internshipPartTimeFullTimeOptions,
                    "Select One"
                  )?.map((child) => (
                    <Option value={child.value}>{child.label}</Option>
                  ))}
                </Select>
                {formInputs?.partTimeFullTime?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <div style={{border: "blue dashed", padding: "5px"}}>
            <Checkbox checked={isFreeOfCharge} onChange={() => setIsFreeOfCharge((prevState) => !prevState)}>
              Free Of Charge?
            </Checkbox>

              <div className="freeOfChargeText">
                {`If the company doesn't charge applicants for the professional experience, Internsea charges approved applicants $${freeOfCharge} as a service fee.`}
              </div>
              </div>

          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item
                validateStatus={
                  formInputs?.price?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.price?.ref}>
                  Price($){" "}
                  {!isFreeOfCharge && (
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                        className="formRequiredStar"
                        icon={faStar}
                        color="var(--red-5)"
                      />
                    </Tooltip>
                  )}
                </div>
                <Input
                  onChange={(e) => onChange("price", e.target.valueAsNumber)}
                  onBlur={() => {
                    setMinValueForInputNumber("price", isFreeOfCharge ? 0 : 100);
                  }}
                  name="price"
                  placeholder="Price"
                  type="number"
                  value={isFreeOfCharge ? 0 : formInputs?.price?.value}
                  min={isFreeOfCharge ? 0 : 100}
                  disabled={isFreeOfCharge}
                ></Input>
                {formInputs?.price?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
              <Form.Item
                validateStatus={
                  formInputs?.weeklyWorkHours?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.weeklyWorkHours?.ref}>
                  Weekly Work Hours{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Input
                  onChange={(e) =>
                    onChange("weeklyWorkHours", e.target.valueAsNumber)
                  }
                  onBlur={() => {
                    setMinValueForInputNumber("weeklyWorkHours");
                  }}
                  name="weeklyWorkHours"
                  placeholder="Weekly Work Hours"
                  type="number"
                  value={formInputs?.weeklyWorkHours?.value}
                  min={1}
                ></Input>
                {formInputs?.weeklyWorkHours?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Input.Group compact>
              <Form.Item
                validateStatus={
                  formInputs?.seatCount?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.seatCount?.ref}>
                  Seat Count{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Input
                  onChange={(e) =>
                    onChange("seatCount", e.target.valueAsNumber)
                  }
                  onBlur={() => {
                    setMinValueForInputNumber("seatCount");
                  }}
                  name="seatCount"
                  placeholder="Seat Count"
                  type="number"
                  value={formInputs?.seatCount?.value}
                  min={1}
                ></Input>
                {formInputs?.seatCount?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
              <Form.Item
                validateStatus={
                  formInputs?.category?.isEmpty ? "error" : "success"
                }
              >
                <div ref={formInputs?.category?.ref}>
                  Category{" "}
                  <Tooltip title="Required">
                    <FontAwesomeIcon
                      className="formRequiredStar"
                      icon={faStar}
                      color="var(--red-5)"
                    />
                  </Tooltip>
                </div>
                <Select
                  placeholder="Category"
                  onChange={(value) => onChange("category", value)}
                  value={formInputs?.category?.value}
                >
                  {selectOptionsWithPlaceholderValue(
                    internshipCategoryOptions,
                    "Select One"
                  )?.map((child) => (
                    <Option value={child.value}>{child.label}</Option>
                  ))}
                </Select>
                {formInputs?.category?.isEmpty && (
                  <div className="errorMessage">Required</div>
                )}
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Title level={3} ref={responsibilitiesRef?.ref}>
              Responsibilities{" "}
              <Tooltip title="Required">
                <FontAwesomeIcon
                  className="formRequiredStar"
                  icon={faStar}
                  color="var(--red-5)"
                />
              </Tooltip>
            </Title>
            {responsibilitiesRef?.isEmpty && (
              <div className="errorMessage">Required</div>
            )}
          </Form.Item>
          <ListCreator
            args={parsedResponsibilities}
            onChangeList={(args) => setParsedResponsibilities(args)}
            placeholder="List the main tasks the intern will do."
          />
          <Form.Item>
            <Title level={3} ref={whatYouWillLearnRef?.ref}>
              What You Will Learn{" "}
              <Tooltip title="Required">
                <FontAwesomeIcon
                  className="formRequiredStar"
                  icon={faStar}
                  color="var(--red-5)"
                />
              </Tooltip>
            </Title>
            {whatYouWillLearnRef?.isEmpty && (
              <div className="errorMessage">Required</div>
            )}
          </Form.Item>
          <ListCreator
            args={parsedWhatYouWillLearn}
            onChangeList={(args) => setParsedWhatYouWillLearn(args)}
            placeholder="List what the intern will mainly learn from this internship."
          />
          <Form.Item
            validateStatus={formInputs?.summary?.isEmpty ? "error" : "success"}
          >
            <div ref={formInputs?.summary?.ref}>
              Description{" "}
              <Tooltip title="Required">
                <FontAwesomeIcon
                  className="formRequiredStar"
                  icon={faStar}
                  color="var(--red-5)"
                />
              </Tooltip>
            </div>
            <textarea
              placeholder="Description"
              value={formInputs?.summary?.value}
              name="summary"
              onChange={(e) => onChange("summary", e.target.value)}
              className="richTextEditor"
            />
            {formInputs?.summary?.isEmpty && (
              <div className="errorMessage">Required</div>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              loading={submitting}
              disabled={submitting}
              onClick={() => {
                onClickSubmit();
              }}
            >
              {!isEditMode ? "Submit" : "Save Changes"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateUpdateInternship;
