import { FC, useContext, useMemo, useState } from "react";
import { Modal, Rate } from "antd";
import "./ratingBox.scss";
import { Context } from "../../context/provider";
import RatingService from "../../services/rating";
import { AlertType } from "../../context/models";

const RatingBox: FC = () => {
  const { rating, currentUser, Alert } = useContext(Context);
  const ratingService = useMemo(() => {
    return new RatingService();
  }, []);

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [stars, setStars] = useState(0);

  const onClickOk = async () => {
    if (!notes || !stars) {
      Alert("Please fill all fields!", AlertType.Warning);
      return;
    }
    try {
      if (rating.internshipId && rating.applicantOrCompanyId) {
        setLoading(true);
        if (currentUser.isCompany) {
          await ratingService.rateApplicant({
            internshipId: rating.internshipId!,
            companyId: currentUser.companyId,
            applicantId: rating.applicantOrCompanyId!,
            notes,
            stars,
          });
        } else {
          await ratingService.rateCompany({
            applicantId: currentUser.applicantId,
            companyId: rating.applicantOrCompanyId!,
            internshipId: rating.internshipId!,
            notes,
            stars,
          });
        }
        Alert("Thank you! Your review is now public.", AlertType.Success);
        setNotes("");
        setStars(0);
        rating.onClose();
        setLoading(false);
      }
    } catch (error) {
      Alert((error as Error).message, AlertType.Error);
      setLoading(false);
    }
  };

  return (
    <Modal
      className="ratingBox"
      title={currentUser.isCompany ? "How was your intern?" : "How was your internship?"}
      open={rating.show}
      onOk={onClickOk}
      onCancel={rating.onClose}
      okButtonProps={{
        loading,
        disabled: loading,
        className: 'okButton',
      }}
      cancelButtonProps={{
        style: { display: 'none' }
      }}
      okText='Submit review'
    >
      <Rate value={stars} onChange={(value) => setStars(value)} />
      <textarea
        placeholder="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        className="richTextEditor"
      />
    </Modal>
  );
};

export default RatingBox;
