import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchInternship from "../../components/searchInternship";
import { AlertType } from "../../context/models";
import { Context } from "../../context/provider";
import {
  GetInternshipsInput,
  InternshipOutput,
  SearchInternshipParamsModel,
} from "../../models/company.models";
import ApplicantService from "../../services/applicant";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";

const SearchInternshipPage: FC = () => {
  const { currentUser, Alert, Confirm } = useContext(Context);

  const navigate = useNavigate();

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  const defaultParams: SearchInternshipParamsModel = {
    title: "",
    location: "",
    duration: 0,
    durationUnit: "",
    physicalRemote: "",
    partTimeFullTime: "",
    whoCanApply: "",
    category: ""
  }

  const [params, setParams] = useState<SearchInternshipParamsModel>({
    title: searchParams?.get("title") ?? "",
    location: searchParams?.get("location") ?? "",
    duration: Number(searchParams?.get("duration") ?? 0),
    durationUnit: searchParams?.get("durationUnit") ?? "",
    physicalRemote: searchParams?.get("physicalRemote") ?? "",
    partTimeFullTime: searchParams?.get("partTimeFullTime") ?? "",
    whoCanApply: searchParams?.get("whoCanApply") ?? "",
    category: searchParams?.get("category") ?? "",
  });

  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [internships, setInternships] = useState<InternshipOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [favoriteInternshipIDs, setFavoriteInternshipIDs] = useState<string[]>(
    []
  );
  const pageSize = 10;

  const getLocationOptions = async () => {
    try {
      const res = await companyService.getLocations();
      setLocationOptions(res);
    } catch (error) {
      printError([error]);
    }
  };

  const getFavoriteInternshipIDs = async () => {
    try {
      const res = await applicantService.getFavoriteInternshipIDs();
      setFavoriteInternshipIDs(res);
    } catch (error) {
      printError([error]);
    }
  };

  const markUnmarkInternship = async (id: string) => {
    if (currentUser.isLoggedIn) {
      if (!currentUser.isCompany) {
        try {
          let existIds = favoriteInternshipIDs;
          if (favoriteInternshipIDs.some((child) => child === id)) {
            applicantService.unmarkFavoriteInternship(id);
            existIds = existIds.filter((x) => x !== id);
          } else {
            applicantService.markFavoriteInternship(id);
            existIds = [ ...existIds, id ];
          }
          setFavoriteInternshipIDs([ ...existIds ]);
        } catch (error) {
          Alert((error as Error).message, AlertType.Error);
        }
      } else {
        Alert(
          "The company account can't mark internship as favorite!",
          AlertType.Warning
        );
      }
    } else {
      Confirm("You must log in! Do you want to go to login page?", () => {
        navigate("/login");
      });
    }
  };

  useEffect(() => {
    if (currentUser.isLoggedIn && !currentUser.isCompany) {
      getFavoriteInternshipIDs();
    } else {
      setFavoriteInternshipIDs([]);
    }
  }, [currentUser.isLoggedIn, currentUser.isCompany]);

  const onSkip = (pageIndex: number) => {
    setSkip(pageIndex * pageSize);
  };

  const onApply = (args: SearchInternshipParamsModel) => {
    setParams(args);
    setSearchParams({ ...(args as any) });
  };

  const getSearchedInternships = async () => {
    setLoading(true);
    const input: GetInternshipsInput = {
      limit: pageSize,
      page: (skip / pageSize) + 1,
      category: params.category,
      duration: !isNaN(params.duration) && params.duration ? params.duration : undefined,
      durationUnit: params.durationUnit,
      location: params.location,
      partTimeFullTime: params.partTimeFullTime,
      physicalRemote: params.physicalRemote,
      title: params.title,
      whoCanApply: params.whoCanApply,
      isPublished: true,
      hideSeatCountFilled: true,
      showApplicationPrice: true,
    };
    try {
      const res = await companyService.getAllInternships(input);
      setTotal(res.totalItems!);
      setLoading(false);
      setInternships(res.items);
    } catch (error) {
      printError([error]);
    }
  };

  const onReset = () => {
    setParams(defaultParams);
    setSearchParams({ ...(defaultParams as any) });
  };

  useEffect(() => {
    getSearchedInternships();
  }, [skip, location, params]);

  useEffect(() => {
    getLocationOptions();
  }, []);

  return (
    <SearchInternship
      params={params}
      onApply={onApply}
      onSkip={onSkip}
      internships={internships}
      locationOptions={locationOptions}
      total={total}
      loading={loading}
      pageSize={pageSize}
      favoriteInternshipIDs={favoriteInternshipIDs}
      markUnmarkInternship={markUnmarkInternship}
      onReset={onReset}
    />
  );
};

export default SearchInternshipPage;