import { AppMode, appMode } from './common';

export function printError(...logs: any[]) {
  print('error', logs);
}

export function printLog(...logs: any[]) {
  print('log', logs);
}

function print(type: string, ...logs: any[]) {
  if (appMode === AppMode.development) {
    switch (type) {
      case 'log':
        console.log(...logs);
        break;
      case 'error':
        console.error(...logs);
        break;
      default:
        break;
    }
  } /* else not needed */
}
