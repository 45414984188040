import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import "./forgotPassword.scss";
import * as yup from "yup";
import { Form, Button, Input } from 'antd';
import { ForgotPasswordInput } from '../../models/account.models';
import { Context } from '../../context/provider';
import { AlertType } from '../../context/models';

interface ForgotPasswordProps {
  onSubmit: (
    args: ForgotPasswordInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ onSubmit }) => {
  const { Alert } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState("");

  const formValues: ForgotPasswordInput = {
    email: "",
  };

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification("");
      }, 3000);
    }
  }, [notification]);

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().email("Invalid email format.").required("Required"),
    });

  const onClickSubmit = (values: ForgotPasswordInput) => {
    setLoading(true);
    onSubmit(
      values,
      () => {
        setLoading(false);
        Alert(`A link to reset your password has been sent to ${values.email}`, AlertType.Success);
      },
      (err) => {
        setLoading(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  return (
    <div className="forgotPasswordPageContainer">
      <Formik
        initialValues={formValues}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema()}
        onSubmit={onClickSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => {
          return (
            <div className="forgotPasswordForm">
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Form.Item
                  validateStatus={
                    errors.email && touched.email ? "error" : "success"
                  }
                >
                  <Input
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  ></Input>
                  {touched.email && errors?.email && (
                    <div className="errorMessage">{errors?.email}</div>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Send Password Reset Link
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
