import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import EditApplicantProfile from "../../components/applicantComponents/editProfile";
import EditCompanyProfile from "../../components/companyComponents/editProfile";
import PageLoader from "../../components/pageLoader";
import { Context } from "../../context/provider";
import { ApplicantInput, ApplicantOutput } from "../../models/applicant.models";
import {
  CompanyOutput,
  CompanyInput,
} from "../../models/company.models";
import ApplicantService from "../../services/applicant";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";

const EditProfilePage: FC = () => {
  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);
  const { currentUser, setCurrentUser } = useContext(Context);
  const [companyProfileInfo, setCompanyProfileInfo] =
    useState<CompanyOutput>({} as CompanyOutput);
  const [applicantProfileInfo, setApplicantProfileInfo] = useState<ApplicantOutput>({} as ApplicantOutput);
  const [loading, setLoading] = useState(true);

  const getCompanyProfileInfo = async () => {
    try {
      const res = await companyService.getProfile();
      setCompanyProfileInfo(res);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const getApplicantProfileInfo = async () => {
    try {
      const res = await applicantService.getProfile(currentUser.applicantId);
      setApplicantProfileInfo(res);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const isProfileCompleted = async (isCompany: boolean) => {
    try {
        let isCompleted = false;
        if (isCompany) {
            isCompleted = await companyService.isProfileCompleted();
        } else {
            isCompleted = await applicantService.isProfileCompleted();
        }
        setCurrentUser((prevState) => ({
          ...prevState,
          isProfileCompleted: isCompleted,
        }))
    } catch (error) {
        setCurrentUser((prevState) => ({
          ...prevState,
          isProfileCompleted: false,
        }))
        printError([error])
    }
};

  const onUpdateCompanyProfile = async (
    args: CompanyInput,
    successCallback: () => void,
    errCallback: (err: Error) => void,
  ) => {
    try {
      await companyService.updateProfile(args);
      await isProfileCompleted(true);
      successCallback();
    } catch (error) {
        errCallback(error as Error);
    }
  };

  const onUpdateApplicantProfile = async (
    args: ApplicantInput,
    successCallback: () => void,
    errCallback: (err: Error) => void,
  ) => {
    try {
      await applicantService.updateProfile(args);
      await isProfileCompleted(false);
      successCallback();
    } catch (error) {
        errCallback(error as Error);
    }
  };

  useEffect(() => {
    if (currentUser.isCompany) {
      getCompanyProfileInfo();
    } else {
      getApplicantProfileInfo();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <PageLoader avatar active />
    )
  }

  return (
    <Fragment>
      {currentUser.isCompany ? (
        <EditCompanyProfile companyProfileInfo={companyProfileInfo} onUpdateCompanyProfile={onUpdateCompanyProfile} />
      ) : (
        <EditApplicantProfile applicantProfileInfo={applicantProfileInfo} onUpdateApplicantProfile={onUpdateApplicantProfile} />
      )}
    </Fragment>
  );
};

export default EditProfilePage;
