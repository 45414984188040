import { FC, CSSProperties, ReactNode } from "react";
import { RateOutput, RateUpdate } from "../../models/rating.models";
import { Col, Divider, Rate, Row, Typography } from "antd";
import ReviewStarCountRow from "../reviewStarCountRow";
import "./ratingReviews.scss";
import Review from "./review";

interface RatingReviewsProps {
  ratings: RateOutput;
  isCompanyReview?: boolean;
  style?: CSSProperties;
  toogle?: ReactNode;
  onUpdate: (
    args: RateUpdate,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onDelete: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  showApplicantProfile: boolean;
}

const { Title } = Typography;

const RatingReviews: FC<RatingReviewsProps> = ({
  ratings,
  isCompanyReview = true,
  style = {},
  toogle,
  onUpdate,
  onDelete,
  showApplicantProfile,
}) => {
  const totalStarCount =
    (ratings?.fiveStarCount ?? 0) +
    (ratings?.fourStarCount ?? 0) +
    (ratings?.threeStarCount ?? 0) +
    (ratings?.twoStarCount ?? 0) +
    (ratings?.oneStarCount ?? 0);

  const setPercentage = (
    countName:
      | "fiveStarCount"
      | "fourStarCount"
      | "threeStarCount"
      | "twoStarCount"
      | "oneStarCount"
  ) =>
    totalStarCount ? ((ratings[countName] ?? 0) / totalStarCount) * 100 : 0;

  return (
    <div className="review boxContainer" style={style}>
      <Row>
        <Col span={24}>
          <div className="reviewTopSection">
            <Title level={2}>Rating/Reviews</Title>
            {toogle}
          </div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Title level={2}>{`${ratings?.reviews?.length ?? 0} Review(s)`}</Title>
          <Rate disabled value={ratings?.starAvg ?? 0} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ratings">
            <div className="startCounts">
              <ReviewStarCountRow
                star={5}
                starCount={ratings?.fiveStarCount ?? 0}
                percentage={setPercentage("fiveStarCount")}
              />
              <ReviewStarCountRow
                star={4}
                starCount={ratings?.fourStarCount ?? 0}
                percentage={setPercentage("fourStarCount")}
              />
              <ReviewStarCountRow
                star={3}
                starCount={ratings?.threeStarCount ?? 0}
                percentage={setPercentage("threeStarCount")}
              />
              <ReviewStarCountRow
                star={2}
                starCount={ratings?.twoStarCount ?? 0}
                percentage={setPercentage("twoStarCount")}
              />
              <ReviewStarCountRow
                star={1}
                starCount={ratings?.oneStarCount ?? 0}
                percentage={setPercentage("oneStarCount")}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="comments">
        <Col span={24}>
          <Divider />
          {ratings?.reviews?.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())?.map((child, index) => (
            <Review
              showApplicantProfile={showApplicantProfile}
              key={index}
              isCompanyReview={isCompanyReview}
              review={child}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default RatingReviews;
