export const dateFormat = "YYYY/MM/DD";

export const genders = [
    'Male',
    'Female',
    'Other'
];

export const internshipCategoryOptions = [
    'Finance, Management & Ops',
    'Sales & Marketing',
    'Product & Engineering',
    'Services',
    'Art & Fashion',
    'Health & Science',
    'Education',
    'Law & Journalism',
    'Networking Opportunities',
    'Maintenance & Technicians',
  ];
  
  export const internshipWhoCanApplyOptions = [
    'Graduates',
    'Students',
    'Grads & Students'
  ];
  
  export const internshipPhysicalRemoteOptions = [
    'Physical',
    'Remote',
  ];
  
  export const internshipPartTimeFullTimeOptions = [
    'Part-Time',
    'Full-Time',
  ];
  
  export const internshipDurationUnitOptions = [
    'Day(s)',
    'Week(s)',
    'Month(s)',
  ];
  
  export const organizationTypes = [
    "Aerospace & Defense",
    "Agriculture",
    "Arts, Entertainment & Recreation",
    "Construction, Repair & Maintenance Services",
    "Education",
    "Energy, Mining & Utilities",
    "Financial Services",
    "Government & Public Administration",
    "Healthcare",
    "Hotels & Travel Accommodation",
    "Human Resources & Staffing",
    "Information Technology",
    "Insurance",
    "Legal",
    "Management & Consulting",
    "Manufacturing",
    "Media & Communication",
    "Nonprofit & NGO",
    "Personal Consumer Services",
    "Pharmaceutical & Biotechnology",
    "Real Estate",
    "Restaurants & Food Service",
    "Retail & Wholesale",
    "Telecommunications",
    "Transportation & Logistics",
  ];
  
  export const companySizes = [
    "1 to 49",
    "50 to 149",
    "150 to 249",
    "250 to 499",
    "500 to 749",
    "750 to 999",
    "1000+",
  ];

  export const freeOfCharge = 500;
