import { Empty, Tabs } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import { AlertType } from "../../../context/models";
import { Context } from "../../../context/provider";
import { NotificationOutput } from "../../../models/notification.models";
import NotificationItem from "../notificationItem";

interface NotificationContentProps {
  notifications: NotificationOutput[];
  markAsRead: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  deleteNotification: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onClick: (id: string) => void;
}

const NotificationContent: FC<NotificationContentProps> = ({
  notifications,
  markAsRead,
  deleteNotification,
  onClick,
}) => {
  const { Alert } = useContext(Context);
  const [readOnes, setReadOnes] = useState<NotificationOutput[]>([]);
  const [unreadOnes, setUnreadOnes] = useState<NotificationOutput[]>([]);

  useEffect(() => {
    setReadOnes([]);
    setUnreadOnes([]);
    notifications
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .forEach((child) => {
        if (child.isRead) {
          setReadOnes((prevState) => [...prevState, child]);
        } else {
          setUnreadOnes((prevState) => [...prevState, child]);
        }
      });
  }, [notifications]);

  const onRead = (id: string) => {
    markAsRead(
      id,
      () => {
        Alert("The notification marked as read", AlertType.Success);
      },
      (err) => {
        Alert(err.message, AlertType.Error);
      }
    );
  };

  const onDelete = (id: string) => {
    deleteNotification(
      id,
      () => {
        Alert("The notification deleted.", AlertType.Success);
      },
      (err) => {
        Alert(err.message, AlertType.Error);
      }
    );
  };

  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Unread" key="1">
        <div className="tabContent">
          {unreadOnes?.length === 0 && <Empty description='Not found any unread notification.' />}
          {unreadOnes?.map((child) => (
            <NotificationItem
              key={child.id}
              onDelete={() => onDelete(child.id)}
              onRead={() => onRead(child.id)}
              showMarkAsRead
              body={child.body}
              createdAt={child.createdAt}
              onClick={() => onClick(child.id)}
              path={child.path}
            />
          ))}
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Read" key="2">
        <div className="tabContent">
          {readOnes?.length === 0 && <Empty description='Not found any read notification.' />}
          {readOnes?.map((child) => (
            <NotificationItem
              key={child.id}
              onDelete={() => onDelete(child.id)}
              body={child.body}
              createdAt={child.createdAt}
              path={child.path}
              onClick={() => onClick(child.id)}
            />
          ))}
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default NotificationContent;
