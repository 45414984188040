import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Payment from "../../components/applicantComponents/payment";
import Earnings from "../../components/companyComponents/earnings";
import { Context } from "../../context/provider";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ApplicationOutput } from "../../models/company.models";
import PageLoader from "../../components/pageLoader";
import ApplicantService from "../../services/applicant";
import { printError, printLog } from "../../utils/logger";
import CompanyService from "../../services/company";

const EarningsPage: FC = () => {
  const { currentUser, updatedEarnings } = useContext(Context);
  const [searchParams] = useSearchParams();

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);

  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID!;

  const applicationId = searchParams?.get("application_id") ?? "";
  const paidApplicationId = searchParams?.get("paidApplicationId") ?? "";

  const [application, setApplication] = useState({} as ApplicationOutput);
  const [earnings, setEarnings] = useState<ApplicationOutput[]>([]);
  const [loading, setLoading] = useState(false);

  const getEarnings = async () => {
    try {
      setLoading(true);
      const res = await companyService.getEarnings();
      setEarnings([...res]);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const getApplication = async () => {
    try {
      setLoading(true);
      const res = await applicantService.getApplication({ id: applicationId });
      setApplication((prevState) => ({
        ...prevState,
        ...res,
      }));
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const afterPayment = async (
    order: unknown,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      const stringifiedOrder = JSON.stringify(order);
      await applicantService.afterPayment(applicationId, { transactionResponse: stringifiedOrder });
      printLog([order]);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  useEffect(() => {
    if (currentUser.isCompany) {
      getEarnings();
    }
  }, [updatedEarnings, currentUser.isCompany]);

  useEffect(() => {
    if (applicationId && !currentUser.isCompany) {
      getApplication();
    }
  }, [applicationId, currentUser.isCompany]);

  if (loading) {
    return <PageLoader avatar active />;
  }

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId,
      }}
    >
      {currentUser.isCompany ? (
        <Earnings earnings={earnings} paidApplicationId={paidApplicationId} />
      ) : (
        <Payment application={application} afterPayment={afterPayment} />
      )}
    </PayPalScriptProvider>
  );
};

export default EarningsPage;
