import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Internship from "../../components/internship";
import PageLoader from "../../components/pageLoader";
import { AlertType, MessageType } from "../../context/models";
import { Context } from "../../context/provider";
import { InternshipOutput } from "../../models/company.models";
import { RateOutput, RateUpdate } from "../../models/rating.models";
import CompanyService from "../../services/company";
import RatingService from "../../services/rating";
import { printError } from "../../utils/logger";

const InternshipPage: FC = () => {
  const { currentUser, Alert, sendMessage } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const ratingService = useMemo(() => {
    return new RatingService();
  }, []);
  const [internship, setInternship] = useState<InternshipOutput>(
    {} as InternshipOutput
  );
  const [loadingInternship, setLoadingInternship] = useState(true);
  const [loadingReview, setLoadingReview] = useState(true);
  const [review, setReview] = useState<RateOutput>(
    {} as RateOutput
  );
  const [updatedReviews, setUpdatedReviews] = useState(false);

  const sendMessageToCompany = () => {
    sendMessage(internship.company.id, MessageType.Create);
  };

  const getInternship = async () => {
    try {
      const res = await companyService.getInternship({ id: id! }, { showApplicationPrice: true });
      setInternship(res);
      setLoadingInternship(false);
    } catch (error) {
      printError([error]);
    }
  };

  const getReview = async (companyId: string) => {
    try {
      const res = await ratingService.getCompanyReviews(companyId, false);
      setReview(res);
      setLoadingReview(false);
    } catch (error) {
      printError([error]);
    }
  };

  useEffect(() => {
    getInternship();
  }, [id]);

  useEffect(() => {
    if (internship?.company?.id) {
      getReview(internship?.company?.id);
    }
  }, [internship?.company?.id, updatedReviews]);

  const onApply = () => {
    if (currentUser.isLoggedIn) {
      if (!currentUser.isCompany) {
        if (currentUser.isProfileCompleted) {
          navigate(`/internships/apply/${id}`);
        } else {
          Alert(`You must complete your profile! You will be redirected to ${internship.title} internship page after you complete your profile.`, AlertType.Warning);
          navigate(`/profile?redirection_path=/internships/${id}`)
        }
      } else {
        Alert("Only applicant accounts can apply for internships!", AlertType.Warning);
      }
    } else {
      Alert(`You must be logged in before applying. You will be redirected to ${internship.title} internship page after logging in.`, AlertType.Warning);
      navigate(`/login?redirection_path=/internships/${id}`);
    }
  };

  const onUpdateRate = async (args: RateUpdate, successCallback: () => void, errCallback: (err: Error) => void) => {
    try {
      await ratingService.updateRate(args);
      successCallback();
      setUpdatedReviews((prevState) => !prevState);
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const onDeleteRate = async (id: string, successCallback: () => void, errCallback: (err: Error) => void) => {
    try {
      await ratingService.deleteRate(id);
      successCallback();
      setUpdatedReviews((prevState) => !prevState);
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const setShowSendMessageButtonVisibility = (): boolean =>
    currentUser.isLoggedIn && !currentUser.isCompany;

  if (loadingInternship) {
    return <PageLoader active avatar />;
  }

  return (
    <Internship
      internship={internship}
      onApply={onApply}
      onSendMessage={sendMessageToCompany}
      showSendMessage={setShowSendMessageButtonVisibility()}
      ratings={review}
      loadingReviews={loadingReview}
      onUpdateRate={onUpdateRate}
      onDeleteRate={onDeleteRate}
    />
  );
};

export default InternshipPage;
