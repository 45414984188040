import { ApplicantOutput } from "./applicant.models";
import { BaseDto, PagedResultRequest } from "./common.models";

export interface CompanyOutput extends BaseDto {
  email: string;
  phone: string;
  name: string;
  type: string;
  establishmentYear: number;
  size: string;
  logoURL: string;
  logo: string;
  website: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
  instagram: string;
  description: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface CompanyInput {
  phone: string;
  name: string;
  type: string;
  establishmentYear: number;
  size: string;
  logo: string;
  website: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
  instagram: string;
  description: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface InternshipOutput {
  id: string;
  companyId: string;
  company: CompanyOutput;
  title: string;
  location: string;
  duration: number;
  durationUnit: string;
  physicalRemote: string;
  whoCanApply: string;
  partTimeFullTime: string;
  price: number;
  weeklyWorkHours: number;
  seatCount: number;
  responsibilities: string; // JSON.parse as string[]
  whatYouWillLearn: string; // JSON.parse as string[]
  isPublished: boolean;
  summary: string;
  createdAt: Date;
  category: string;
  isFreeOfCharge: boolean;
}

export interface GetInternshipInput {
  id: string;
}

export interface GetInternshipQuery {
  showApplicationPrice?: boolean;
}

export interface GetInternshipsInput extends PagedResultRequest, GetInternshipQuery {
  title?: string;

  location?: string;

  duration?: number;

  durationUnit?: string;

  physicalRemote?: string;

  partTimeFullTime?: string;

  whoCanApply?: string;

  isPublished?: boolean;

  companyId?: string;

  category?: string;

  hideSeatCountFilled?: boolean;
}

export interface CreateInternshipInput {
  title: string;
  location: string;
  duration: number;
  durationUnit: string;
  physicalRemote: string;
  whoCanApply: string;
  partTimeFullTime: string;
  price: number;
  category: string;
  weeklyWorkHours: number;
  seatCount: number;
  responsibilities: string; // JSON.stringfy as string[]
  whatYouWillLearn: string; // JSON.stringfy as string[]
  isPublished: boolean;
  summary: string;
  isFreeOfCharge: boolean;
}

export interface UpdateInternshipInput extends CreateInternshipInput {
  id: string;
}

export interface PublishInternshipInput {
  id: string;
  isPublished: boolean;
}

export interface DeleteInternshipInput {
  id: string;
}

export interface CompanyAccountOutput {
  id: string;
  title: string;
  nameOnAccount: string;
  paypalId: string;
  phone: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  accountNumber: string;
  IBAN: string;
  swift: string;
  isDefault: boolean;
}

export interface GetCompanyAccountInput {
  id: string;
}

export interface CreateCompanyAccountInput {
  title: string;
  nameOnAccount: string;
  paypalId: string;
  phone: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  accountNumber: string;
  IBAN: string;
  swift: string;
}

export interface UpdateCompanyAccountInput
  extends CreateCompanyAccountInput {
  id: string;
}

export interface DeleteCompanyAccountInput {
  id: string;
}

export enum ApplicationStatus {
  Pending = 'pending',
  Completed = 'completed',
  Approved = 'approved',
  Active = 'active',
  Rejected = 'rejected',
}

export interface ApplicationOutput extends BaseDto {
  grade: string;
  gradeBy: string;
  graderNotes: string;
  applicant: ApplicantOutput;
  internship: InternshipOutput;
  status: ApplicationStatus;
  isPaymentComplete: boolean;
  paymentDate: Date;
  rejectReason: string;
  approvalDate: Date;
  transactionResponse: string;
  isFreeOfCharge: boolean;
  appliedPrice: number;
}

export interface GetApplicationInput {
  id: string;
}

export interface UpdateApplicationInput {
  id: string;
  status?: ApplicationStatus;
  isPaymentComplete?: boolean;
  rejectReason?: string;
  approvalDate?: Date;
  reviewExpiredDate?: Date;
  grade?: string;
  graderNotes?: string;
}

export interface AfterPaymentInput {
  transactionResponse: string;
}

export interface DeleteApplicationInput {
  id: string;
}

export type SearchInternshipParamsModel = {
  title: string;
  location: string;
  duration: number;
  durationUnit: string;
  physicalRemote: string;
  partTimeFullTime: string;
  whoCanApply: string;
  category: string;
};
