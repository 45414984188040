import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenStatic } from 'axios';
import TokenRequestInterceptor, {
    TokenRequestInterceptorOptions
} from './httpClientInterceptors/tokenRequestInterceptor';
import TokenResponseInterceptor, {
    TokenResponseInterceptorOptions
} from './httpClientInterceptors/tokenResponseInterceptor';

export interface HttpClientOptions {
    requestInterceptors: any[];
    responseInterceptors: any[];
    injectTokenHeaderRequestInterceptor?: boolean;
    injectTokenRefreshResponseInterceptor?: boolean;
    document?: any;
    tokenKey?: string;
    [key: string]: any;
}

export default class HttpClient {
    private readonly axios: AxiosInstance;
    CancelToken: CancelTokenStatic;

    constructor(baseURL: string, options: HttpClientOptions) {
        const {
            requestInterceptors = [],
            responseInterceptors = [],
            headers = {},
            injectTokenHeaderRequestInterceptor = true,
            injectTokenRefreshResponseInterceptor = true,
            document = undefined,
            tokenKey = undefined,
            ...rest
        } = options;

        this.CancelToken = axios.CancelToken;

        this.axios = axios.create({
            baseURL,
            headers,
            ...rest
        });

        if (injectTokenHeaderRequestInterceptor) {
            const tokenRequestInterceptor = new TokenRequestInterceptor({
                document: document
            } as TokenRequestInterceptorOptions);
            this.axios.interceptors.request.use(
                (conf: any) => tokenRequestInterceptor.onFulfilled(conf),
                (error: any) => tokenRequestInterceptor.onRejected(error)
            );
        }

        if (injectTokenRefreshResponseInterceptor) {
            const tokenResponseInterceptor = new TokenResponseInterceptor({
                document: document,
                tokenKey: tokenKey
            } as TokenResponseInterceptorOptions);
            this.axios.interceptors.response.use(
                (conf : any) => tokenResponseInterceptor.onFulfilled(conf),
                (error : any) => tokenResponseInterceptor.onRejected(error)
            );
        }

        requestInterceptors.forEach((interceptorConfig) => {
            this.axios.interceptors.request.use(
                (conf: any) => interceptorConfig.onFulfilled(conf, this.axios),
                (error: any) => interceptorConfig.onRejected(error, this.axios)
            );
        });

        responseInterceptors.forEach((interceptorConfig) => {
            this.axios.interceptors.response.use(
                (conf: any) => interceptorConfig.onFulfilled(conf, this.axios),
                (error: any) => {
                    if (error.message === 'Retried request') {
                        return Promise.reject(error);
                    }
                    return interceptorConfig.onRejected(error, this.axios);
                }
            );
        });
    }

    async request(options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.request(options);

        return response.data;
    }

    async get(path: string, options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.get(path, options);

        return response.data;
    }

    async post(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.post(path, data, options);

        return response.data;
    }

    async put(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.put(path, data, options);

        return response.data;
    }

    async patch(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.patch(path, data, options);

        return response.data;
    }

    async delete(path: string, options: AxiosRequestConfig): Promise<AxiosResponse> {
        const response = await this.axios.delete(path, options);

        return response.data;
    }

    getAxiosInstance(): AxiosInstance {
        return this.axios;
    }

    static isCancel(error: any): boolean {
        return axios.isCancel(error);
    }
}