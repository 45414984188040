import { Skeleton } from "antd";
import { FC } from "react";
import "./pageLoader.scss";

interface PageLoaderProps {
  active?: boolean;
  avatar?: boolean;
  numOfSkeleton?: number;
}

const PageLoader: FC<PageLoaderProps> = ({
  active = false,
  avatar = false,
  numOfSkeleton = 1,
}) => (
  <div className="skeletonContainer">
    {Array.from(Array(numOfSkeleton).keys())?.map((_child) => (
      <Skeleton active={active} avatar={avatar} paragraph={{ rows: numOfSkeleton === 1 ? 6 : 4 }} />
    ))}
  </div>
);

export default PageLoader;
