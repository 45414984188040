import { CurrentUserModel } from "../../../context/models";
import { Context } from "../../../context/provider";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu, Space } from "antd";
import { FC, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import StorageModel from "../../../httpClient/StorageModel";
import { helpers } from "../../../utils/helpers";

interface IAvatarProps {
  currentUser: CurrentUserModel;
  showLightLogo: () => boolean;
  closeSidebar: () => void;
}

const AppAvatar: FC<IAvatarProps> = ({
  currentUser,
  showLightLogo,
  closeSidebar,
}) => {
  const AppStorage = useMemo(() => {
    return new StorageModel();
  }, []);

  const { getFirstLetterOfDisplayname } = helpers;

  const { setCurrentUserInfoUpdated } = useContext(Context);

  const navigate = useNavigate();

  const logout = () => {
    if (process.env.REACT_APP_ACCESS_TOKEN_NAME)
      AppStorage.deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
    setCurrentUserInfoUpdated((prevState) => !prevState);
    closeSidebar();
  };

  const setDropdownContent = () => (
    <Menu
      items={[
        {
          key: "1",
          label: <div>Profile</div>,
          onClick: () => [navigate("/profile"), closeSidebar()],
        },
        {
          key: "2",
          label: <div>Log out</div>,
          onClick: logout,
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={setDropdownContent()} trigger={[ "click" ]}>
      <Space>
        <div style={{ cursor: 'pointer' }}>
          {/* <span
            className="displayName"
            style={{
              color: showLightLogo() ? "var(--white)" : "var(--primary-color)",
            }}
          >
            {currentUser?.displayName?.toUpperCase() || "Unknown User"}
          </span> */}
          <Avatar src={currentUser?.photoUrl}>
            {getFirstLetterOfDisplayname(currentUser?.displayName ?? "A")}
          </Avatar>
        </div>
      </Space>
    </Dropdown>
  );
};

export default AppAvatar;
