import ResetForgottenPassword from '../../components/resetForgottenPassword';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AccountService from '../../services/account';
import { ResetForgottenPasswordInput } from '../../models/account.models';

const ResetForgottenPasswordPage: FC = () => {
  const { verificationCode } = useParams();
  const authService = useMemo(() => {
    return new AccountService();
  }, []);

  const onSubmit = async (
    password: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      const args: ResetForgottenPasswordInput = {
        password,
        verificationCode: verificationCode!
      };
      await authService.resetForgottenPassword(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  return <ResetForgottenPassword onSubmit={onSubmit} />;
};

export default ResetForgottenPasswordPage;
