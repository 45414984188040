import { FC } from "react";
import "./helpCompanies.scss";
import { Typography } from "antd";

const { Title, Text } = Typography;

const HelpCompanies: FC = () => {
  return (
    <div className="helpCompaniesContainer">
      <div className="helpCompaniesContent boxContainer">
        <Title>FAQ</Title>
        <Title level={2}>When is the payout?</Title>
        <Text>You can always withdraw your earnings at any time.</Text>
        <Title level={2}>
          When would my earnings be cleared to be withdrawn?
        </Title>
        <Text>
          After the internship duration is done. You'll close the internship and
          mark it as complete, and your intern will accept, then your earnings
          will be unlocked to be withdrawn.
        </Text>
        <Title level={2}>
          Can I create and list more than one internship position?
        </Title>
        <Text>
          Absolutely! You could list as many internship positions as you want.
        </Text>
        <Title level={2}>
          How much should I charge for every internship I offer?
        </Title>
        <Text>You can charge the price you feel is most appropriate.</Text>
        <Title level={2}>
          Can I change my internship listings' prices listed?
        </Title>
        <Text>Yes! You can change your internship price at any time.</Text>
        <Title level={2}>
          If I accept more than one intern for the same position, should I
          create similar listings?
        </Title>
        <Text>
          No. You can just create and list 1 internship listing where many
          people would apply and you only approve the number of interns you can
          let whose profiles are most appealing to you. The moment you approve
          the number of interns you could accept, then you unpublish your
          internship listing so that no one could see it and apply for it.
        </Text>
        <Title level={2}>Can I publish a listing that I unpublished?</Title>
        <Text>
          Yes. Your listing will reappear again and applicants could apply for
          the internship position.
        </Text>
        <Title level={2}>
          Should I charge for an Internsea's unpaid internship?
        </Title>
        <Text>
          Always. The goal of Internsea is to provide high quality unpaid
          internships to students who are willing to spare time searching and
          applying for a variety of internships.
        </Text>
        <Title level={2}>What's the deadline to accept an applicant?</Title>
        <Text>
          You have 3 business days to accept an applicant. If not, the booking
          request sent by the applicant will be automatically closed.
        </Text>
        <Title level={2}>
          Do I have to be a big company to list my internship positions?
        </Title>
        <Text>
          Not at all! Equally, both big companies and startup companies are
          invited to list their unpaid internship positions.
        </Text>
        <Title level={2}>
          Can I hire the intern for a full-time or part-time paid position after
          the internship duration is complete?
        </Title>
        <Text>Absolutely! It is highly encouraged but it's not mandatory.</Text>
        <Title level={2}>What are Internsea service fees?</Title>
        <Text>
          All companies listing an internship on Internsea pay a service fee
          that is 7% of the booking subtotal.
        </Text>
        <Title level={2}>
          When would I be able to connect with applicants to discuss the details
          of the internship?
        </Title>
        <Text>
          Whenever someone applies to get approved for the internship you offer
          (before payment), you can get into contact with them via our chat,
          where you could ask them all sorts of questions, conduct an interview,
          and also discuss details with them before they book the internship.
        </Text>
        <Title level={2}>
          Can I ask the applicant to pay our company outside Internsea?
        </Title>
        <Text>
          It's stricly against Internsea policy. Any attempt to talk outside the
          platform would lead to a definitive ban.
        </Text>
        <Title level={2}>
          When will I be able to leave a review for the intern?
        </Title>
        <Text>
          After the internship duration is finished and after you mark the
          internship as complete.
        </Text>
      </div>
    </div>
  );
};

export default HelpCompanies;
