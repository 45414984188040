import {
  ForgotPasswordInput,
  LoginInput,
  LoginOutput,
  ProfileOutput,
  ResetExistingPasswordInput,
  ResetForgottenPasswordInput,
  SignupInput,
  VerifyEmailInput,
} from "../models/account.models";
import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";

export default class AccountService {
  private path = "account";

  getAccount = async (): Promise<ProfileOutput> => {
    try {
      const res = await InternseaAPI.get(`${this.path}`, {});
      return res as unknown as ProfileOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  signup = async (args: SignupInput): Promise<void> => {
    try {
      await InternseaAPI.post(`${this.path}/signup`, args, {});
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  login = async (args: LoginInput): Promise<LoginOutput> => {
    try {
      const res = await InternseaAPI.post(`${this.path}/login`, args, {});
      return res as unknown as LoginOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  sendEmailVerificationLink = async (args: VerifyEmailInput) => {
    try {
      const res = await InternseaAPI.post(`${this.path}/send_verification_link`, args, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  verifyEmail = async (args: VerifyEmailInput) => {
    try {
      const res = await InternseaAPI.post(`${this.path}/verify_email`, args, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  forgotPassword = async (args: ForgotPasswordInput) => {
    try {
      const res = await InternseaAPI.post(
        `${this.path}/forgot-password`,
        args,
        {}
      );
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  resetForgottenPassword = async (args: ResetForgottenPasswordInput) => {
    try {
      const res = await InternseaAPI.put(`${this.path}/password`, args, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  resetExistingPassword = async (args: ResetExistingPasswordInput) => {
    try {
      const res = await InternseaAPI.post(
        `${this.path}/reset-password`,
        args,
        {}
      );
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };
}
