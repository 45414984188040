import ForgotPassword from '../../components/forgotPassword';
import { FC, useMemo } from 'react';
import { ForgotPasswordInput } from '../../models/account.models';
import AccountService from '../../services/account';

const ForgotPasswordPage: FC = () => {
  const authService = useMemo(() => {
    return new AccountService();
  }, []);

  const onSubmit = async (
    args: ForgotPasswordInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await authService.forgotPassword(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  return <ForgotPassword onSubmit={onSubmit} />;
};

export default ForgotPasswordPage;
