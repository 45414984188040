import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Form, Button, Input, Tooltip } from "antd";
import * as yup from "yup";
import { Context } from "../../../context/provider";
import { CreateCompanyAccountInput } from "../../../models/company.models";
import "./createUpdateBillingAccount.scss";
import { AlertType } from "../../../context/models";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import LocationInput from "../../locationInput";

interface CreateUpdateBillingAccountProps {
  inputs: CreateCompanyAccountInput;
  onSubmit: (
    args: CreateCompanyAccountInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  isEditMode?: boolean;
  onDelete?: (
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const CreateUpdateBillingAccount: FC<CreateUpdateBillingAccountProps> = ({
  inputs,
  onSubmit,
  isEditMode = false,
  onDelete = () => null,
}) => {
  const { Alert, Confirm } = useContext(Context);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [city, setCity] = useState(inputs?.city);

  const navigate = useNavigate();

  useEffect(() => {
    setCity(inputs?.city);
  }, [inputs?.city]);

  const validationSchema = () =>
    yup.object().shape({
      title: yup
        .string()
        .required("Required")
        .max(50, "Maximum 50 characters!"),
      nameOnAccount: yup
        .string()
        .nullable()
        .max(100, "Maximum 100 characters!"),
      paypalId: yup.string().nullable().max(100, "Maximum 100 characters!"),
      phone: yup.string().nullable().max(25, "Maximum 25 characters!"),
      state: yup.string().nullable().max(50, "Maximum 50 characters!"),
      zipCode: yup.string().nullable().max(10, "Maximum 10 characters!"),
      accountNumber: yup.string().nullable().max(50, "Maximum 50 characters!"),
      IBAN: yup.string().nullable().max(50, "Maximum 50 characters!"),
      swift: yup.string().nullable().max(50, "Maximum 50 characters!"),
    });

  const onClickSubmit = (values: CreateCompanyAccountInput) => {
    setSending(true);
    values = {
      ...values,
      city,
    };
    onSubmit(
      values,
      () => {
        setSending(false);
        const message = !isEditMode
          ? "The account created successfully."
          : "The account updated successfully.";
        Alert(message, AlertType.Success);
        navigate("/change-billings-settings");
      },
      (err) => {
        setSending(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  const onClickDelete = () => {
    Confirm("Are you sure?", () => {
      setDeleting(true);
      onDelete(
        () => {
          setDeleting(false);
          Alert("The account deleted successfully.", AlertType.Success);
          navigate("/change-billings-settings");
        },
        (err) => {
          Alert(err.message, AlertType.Error);
          setDeleting(false);
        }
      );
    });
  };

  return (
    <div className="createUpdateBillingAccountContainer">
      <div className="formContent boxContainer">
        <Formik
          initialValues={inputs}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={validationSchema()}
          onSubmit={onClickSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => {
            return (
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.title && touched.title ? "error" : "success"
                      }
                    >
                      <div>
                        Account Title{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Input
                        name="title"
                        placeholder="Account Title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      ></Input>
                      {touched.title && errors?.title && (
                        <div className="errorMessage">{errors?.title}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.accountNumber && touched.accountNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Account Number</div>
                      <Input
                        name="accountNumber"
                        placeholder="Account Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.accountNumber}
                      ></Input>
                      {touched.accountNumber && errors?.accountNumber && (
                        <div className="errorMessage">
                          {errors?.accountNumber}
                        </div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.nameOnAccount && touched.nameOnAccount
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Name On Account</div>
                      <Input
                        name="nameOnAccount"
                        placeholder="Name On Account"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nameOnAccount}
                      ></Input>
                      {touched.nameOnAccount && errors?.nameOnAccount && (
                        <div className="errorMessage">
                          {errors?.nameOnAccount}
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.phone && touched.phone ? "error" : "success"
                      }
                    >
                      <div>Phone</div>
                      <Input
                        name="phone"
                        placeholder="Contact No"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      ></Input>
                      {touched.phone && errors?.phone && (
                        <div className="errorMessage">{errors?.phone}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.IBAN && touched.IBAN ? "error" : "success"
                      }
                    >
                      <div>IBAN</div>
                      <Input
                        name="IBAN"
                        placeholder="IBAN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IBAN}
                      ></Input>
                      {touched.IBAN && errors?.IBAN && (
                        <div className="errorMessage">{errors?.IBAN}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.swift && touched.swift ? "error" : "success"
                      }
                    >
                      <div>Swift</div>
                      <Input
                        name="swift"
                        placeholder="Swift"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.swift}
                      ></Input>
                      {touched.swift && errors?.swift && (
                        <div className="errorMessage">{errors?.swift}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.paypalId && touched.paypalId
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Paypal Id</div>
                      <Input
                        name="paypalId"
                        placeholder="Paypal Id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.paypalId}
                      ></Input>
                      {touched.paypalId && errors?.paypalId && (
                        <div className="errorMessage">{errors?.paypalId}</div>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <div>Location</div>
                      <LocationInput
                        value={city}
                        onChange={(val) => setCity(val)}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.state && touched.state ? "error" : "success"
                      }
                    >
                      <div>State</div>
                      <Input
                        name="state"
                        placeholder="State"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                      ></Input>
                      {touched.state && errors?.state && (
                        <div className="errorMessage">{errors?.state}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.zipCode && touched.zipCode ? "error" : "success"
                      }
                    >
                      <div>Zip Code</div>
                      <Input
                        name="zipCode"
                        placeholder="Zip Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode}
                      ></Input>
                      {touched.zipCode && errors?.zipCode && (
                        <div className="errorMessage">{errors?.zipCode}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={sending}
                    disabled={sending || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {!isEditMode ? "Submit" : "Save Changes"}
                  </Button>
                </Form.Item>
                {isEditMode && (
                  <Form.Item>
                    <Button
                      type="ghost"
                      block
                      loading={deleting}
                      disabled={deleting}
                      onClick={onClickDelete}
                    >
                      Delete
                    </Button>
                  </Form.Item>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUpdateBillingAccount;
