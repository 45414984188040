import { FC, useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Auth from "./components/auth";
import PageBreadCrumb from "./components/breadCrumb";
import Layout from "./components/layout";
import Meta from "./components/meta";
import ProfileSidebar from "./components/profileSidebar";
import SocketReceiver from "./components/socketReceiver";
import { AlertConfirmType } from "./context/models";
import { Context } from "./context/provider";
import ApplicationsPage from "./pages/applicationsPage";
import ApplicationTermsPage from "./pages/applicationTermsPage";
import BillingAccountsPage from "./pages/billingAccountsPage";
import CreateBillingAccountPage from "./pages/createBillingAccountPage";
import CreateInternshipPage from "./pages/createInternshipPage";
import EarningsPage from "./pages/earningsPage";
import EditProfilePage from "./pages/editProfilePage";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import HelpApplicantsPage from "./pages/helpApplicantsPage";
import HelpCompaniesPage from "./pages/helpCompaniesPage";
import HostPage from "./pages/hostPage";
import HowInternseaWorksPage from "./pages/howInternseaWorks";
import InternshipPage from "./pages/internshipPage";
import InternshipsPage from "./pages/internshipsPage";
import LoginPage from "./pages/loginPage";
import MainPage from "./pages/mainPage";
import MessagesPage from "./pages/messagesPage";
import NotFoundPage from "./pages/notFoundPage";
import ResetExistPasswordPage from "./pages/resetExistPasswordPage";
import ResetForgottenPasswordPage from "./pages/resetForgottenPasswordPage";
import ReviewsPage from "./pages/reviewsPage";
import SearchInternshipPage from "./pages/searchInternshipPage";
import SignupPage from "./pages/signupPage";
import UpdateBillingAccountPage from "./pages/updateBillingAccountPage";
import UpdateInternshipPage from "./pages/updateInternshipPage";
import VerifyEmailPage from "./pages/verifyEmailPage";

const App: FC = () => {
  const context = useContext(Context);
  const { alertConfirm, currentUser } = context;
  const mainTitle = "Internsea: Book Internship Experiences";

  const disabledApp = () =>
    alertConfirm.show && alertConfirm.type === AlertConfirmType.Confirm;

  return (
    <div className={`App ${disabledApp() && "disabledApp"}`}>
      <Router>
        <Auth>
          <SocketReceiver>
            <Routes>
              <Route
                path="/"
                element={
                  <Meta activateGTag title={`${mainTitle}-Main Page`}>
                    <Layout>
                      <PageBreadCrumb name="Main Page">
                        <MainPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/login"
                element={
                  <Meta title={`${mainTitle}-Login Page`}>
                    <Layout>
                      <LoginPage />
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/forget_password"
                element={
                  <Meta title={`${mainTitle}-Forgot Password Page`}>
                    <Layout>
                      <ForgotPasswordPage />
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/change-password"
                element={
                  <Meta title={`${mainTitle}-Change Password Page`}>
                    <Layout>
                      <PageBreadCrumb name="Change Password" show>
                        <ProfileSidebar>
                          <ResetExistPasswordPage />
                        </ProfileSidebar>
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/reset_forgotten_password/:verificationCode"
                element={
                  <Meta title={`${mainTitle}-Reset Password Page`}>
                    <Layout>
                      <ResetForgottenPasswordPage />
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/verify_email/:code"
                element={
                  <Meta title={`${mainTitle}-Verify Email Page`}>
                    <Layout>
                      <VerifyEmailPage />
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/signup"
                element={
                  <Meta title={`${mainTitle}-Signup Page`}>
                    <Layout>
                      <SignupPage />
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/bookings"
                element={
                  <Meta title={`${mainTitle}-Applications Page`}>
                    <Layout>
                      <PageBreadCrumb name="Applications" show>
                        <ApplicationsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/earnings"
                element={
                  <Meta title={`${mainTitle}-${currentUser.isCompany ? 'Earnings' : 'Payment'} Page`}>
                    <Layout>
                      <PageBreadCrumb name={currentUser.isCompany ? 'Earnings' : 'Payment'} show>
                        <EarningsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/internships"
                element={
                  <Meta title={`${mainTitle}-${currentUser.isCompany ? 'Internships' : 'Wishlist'} Page`}>
                    <Layout>
                      <PageBreadCrumb name={currentUser.isCompany ? 'Internships' : 'Wishlist'} show>
                        <InternshipsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/reviews"
                element={
                  <Meta title={`${mainTitle}-Reviews Page`}>
                    <Layout>
                      <PageBreadCrumb name="Reviews" show>
                        <ReviewsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/create-internship"
                element={
                  <Meta title={`${mainTitle}-Create Internship Page`}>
                    <Layout>
                      <PageBreadCrumb name="Create Internship" show>
                        <CreateInternshipPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/update-internship/:id"
                element={
                  <Meta title={`${mainTitle}-Update Internship Page`}>
                    <Layout>
                      <PageBreadCrumb name="Update Internship" show>
                        <UpdateInternshipPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/internships/:id"
                element={
                  <Meta title={`${mainTitle}-Internship Page`}>
                    <Layout>
                      <PageBreadCrumb name="Internship" show>
                        <InternshipPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/messages"
                element={
                  <Meta title={`${mainTitle}-Messages Page`}>
                    <Layout>
                      <PageBreadCrumb name="Messages" show>
                        <MessagesPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/searchInternship"
                element={
                  <Meta title={`${mainTitle}-Search Internship Page`}>
                    <Layout>
                      <PageBreadCrumb name="Search Internship" show>
                        <SearchInternshipPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/change-billings-settings"
                element={
                  <Meta title={`${mainTitle}-Billing Settings Page`}>
                    <Layout>
                      <PageBreadCrumb name="Billing Settings" show>
                        <ProfileSidebar>
                          <BillingAccountsPage />
                        </ProfileSidebar>
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/create-billing-account"
                element={
                  <Meta title={`${mainTitle}-Create Billing Account Page`}>
                    <Layout>
                      <PageBreadCrumb name="Create Billing Account" show>
                        <ProfileSidebar>
                          <CreateBillingAccountPage />
                        </ProfileSidebar>
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/update-billing-account/:id"
                element={
                  <Meta title={`${mainTitle}-Update Billing Account Page`}>
                    <Layout>
                      <PageBreadCrumb name="Update Billing Account" show>
                        <ProfileSidebar>
                          <UpdateBillingAccountPage />
                        </ProfileSidebar>
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/profile"
                element={
                  <Meta title={`${mainTitle}-Profile Page`}>
                    <Layout>
                      <PageBreadCrumb name="Profile" show>
                        <ProfileSidebar>
                          <EditProfilePage />
                        </ProfileSidebar>
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/internships/apply/:internship_id"
                element={
                  <Meta title={`${mainTitle}-Application Terms Page`}>
                    <Layout>
                      <PageBreadCrumb name="Application Terms" show>
                        <ApplicationTermsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/help-companies"
                element={
                  <Meta title={`${mainTitle}-Help (Company) Page`}>
                    <Layout>
                      <PageBreadCrumb name="Help (Company)" show>
                        <HelpCompaniesPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/help"
                element={
                  <Meta title={`${mainTitle}-Help Page`}>
                    <Layout>
                      <PageBreadCrumb name="Help" show>
                        <HelpApplicantsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              {/* <Route
                path="/contact-us"
                element={
                  <Meta title={`${mainTitle}-Contact Us Page`}>
                    <Layout>
                      <PageBreadCrumb name="Contact Us" show>
                        <ContactUsPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              /> */}
              <Route
                path="/how-internsea-works"
                element={
                  <Meta title={`${mainTitle}-How Internsea Works Page`}>
                    <Layout>
                      <PageBreadCrumb name="How Internsea Works" show>
                        <HowInternseaWorksPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="/host"
                element={
                  <Meta title={`${mainTitle}-Host Page`}>
                    <Layout>
                      <PageBreadCrumb name="Host" show>
                        <HostPage />
                      </PageBreadCrumb>
                    </Layout>
                  </Meta>
                }
              />
              <Route
                path="*"
                element={
                  <Meta title={`${mainTitle}-Not Found Page`}>
                    <Layout>
                      <NotFoundPage />
                    </Layout>
                  </Meta>
                }
              />
            </Routes>
          </SocketReceiver>
        </Auth>
      </Router>
    </div>
  );
};

export default App;
