import { SignupInput } from "../../models/account.models";
import { FC, Fragment, useEffect, useState } from "react";
import "./signup.scss";
import * as yup from "yup";
import {Form, Button, Input, Checkbox, Result, Radio} from "antd";
import { Formik } from "formik";

interface ISignupProps {
    onSignup: (
        formValues: SignupInput,
        successCallback: () => void,
        errCallback: (err: Error) => void
    ) => void;
}

interface formValuesModel extends Omit<SignupInput, "isCompany"> {
    confirmPassword: string;
}

interface NotificationModel {
    message: string;
    type: 'success' | 'error'
}

const Signup: FC<ISignupProps> = ({ onSignup }) => {
    const [isCompany, setIsCompany] = useState(true);
    const [loading, setLoading] = useState(false);
    const [ successfullySubmitted, setSuccessfullySubmitted ] = useState(false);
    const formValues: formValuesModel = {
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        confirmPassword: "",
    };
    const [notification, setNotification] = useState<NotificationModel | null>(null);

    useEffect(() => {
      if (notification) {
        setTimeout(() => {
            setNotification(null);
        }, 5000);
      }
    }, [notification])

    const validationSchema = () =>
        yup.object().shape({
            email: yup
                .string()
                .max(250, "Maximum 250 characters!")
                .email("Invalid email format.")
                .required("Required"),
            password: yup
                .string()
                .min(8, "Minimum 8 characters!")
                .max(32, "Maximum 32 characters!")
                .matches(
                    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    "Password must include uppercase and lowercase letters and at least one number or punctuation mark."
                )
                .required("Required"),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref("password"), null], "Those passwords didn’t match. Try again.")
                .required("Required"),
            phone: yup.string().max(25, "Maximum 25 characters!"),
            name: isCompany
                ? yup.string().max(250, "Maximum 250 characters!").required("Required")
                : yup.string().max(250, "Maximum 250 characters!"),
            firstName: !isCompany
                ? yup.string().max(50, "Maximum 50 characters!").required("Required")
                : yup.string().max(50, "Maximum 50 characters!"),
            lastName: !isCompany
                ? yup.string().max(50, "Maximum 50 characters!").required("Required")
                : yup.string().max(50, "Maximum 50 characters!"),
        });

    const onSubmit = (values: formValuesModel) => {
        const input: SignupInput = {
            isCompany: isCompany,
            name: isCompany ? values.name : "",
            firstName: !isCompany ? values.firstName : "",
            lastName: !isCompany ? values.lastName : "",
            email: values.email,
            password: values.password,
            phone: values.phone,
        };
        setLoading(true);
        onSignup(
            input,
            () => {
                setLoading(false);
                setSuccessfullySubmitted(true);
            },
            (error) => {
                setNotification({
                    type: 'error',
                    message: error.message,
                })
                setLoading(false);
            }
        );
    };

    return (
        <div className="signupPageContainer">
            <Formik
                initialValues={formValues}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={validationSchema()}
                onSubmit={onSubmit}
            >
                {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                  }) => {
                    return (
                        <div className="signupForm">
                            {successfullySubmitted && <Result
                                status="success"
                                title="Sign up successful!"
                                subTitle="We've sent you a verification email. Please check your email."
                            /> }
                            <Form
                                labelCol={{ span: 0 }}
                                wrapperCol={{ span: 20, offset: 2 }}
                                initialValues={{ remember: true }}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") handleSubmit();
                                }}
                            >
                                <Form.Item style={{ marginBottom: 0 }}>
                                    Sign Up As
                                </Form.Item>
                                <Form.Item>
                                    <Input.Group compact>
                                        <Form.Item>
                                            <Radio
                                                checked={isCompany}
                                                onChange={() => setIsCompany(!isCompany)}
                                            >
                                                Company
                                            </Radio>
                                        </Form.Item>
                                        <Form.Item>
                                            <Radio
                                                checked={!isCompany}
                                                onChange={() => setIsCompany(!isCompany)}
                                            >
                                                Applicant
                                            </Radio>
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                                {isCompany ? (
                                    <Form.Item
                                        validateStatus={
                                            errors.name && touched.name ? "error" : "success"
                                        }
                                    >
                                        <Input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="name"
                                            placeholder="Company Name"
                                        ></Input>
                                        {touched.name && errors?.name && (
                                            <div className="errorMessage">{errors?.name}</div>
                                        )}
                                    </Form.Item>
                                ) : (
                                    <Fragment>
                                        <Form.Item
                                            validateStatus={
                                                errors.firstName && touched.firstName
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Input
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="firstName"
                                                placeholder="First Name"
                                            ></Input>
                                            {touched.firstName && errors?.firstName && (
                                                <div className="errorMessage">{errors?.firstName}</div>
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            validateStatus={
                                                errors.lastName && touched.lastName
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Input
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="lastName"
                                                placeholder="Last Name"
                                            ></Input>
                                            {touched.lastName && errors?.lastName && (
                                                <div className="errorMessage">{errors?.lastName}</div>
                                            )}
                                        </Form.Item>
                                    </Fragment>
                                )}
                                <Form.Item
                                    validateStatus={
                                        errors.email && touched.email ? "error" : "success"
                                    }
                                >
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="email"
                                        placeholder="Email"
                                    ></Input>
                                    {touched.email && errors?.email && (
                                        <div className="errorMessage">{errors?.email}</div>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    validateStatus={
                                        errors.password && touched.password ? "error" : "success"
                                    }
                                >
                                    <Input.Password
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="password"
                                        placeholder="Password"
                                    ></Input.Password>
                                    {touched.password && errors?.password && (
                                        <div className="errorMessage">{errors?.password}</div>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    validateStatus={
                                        errors.confirmPassword && touched.confirmPassword
                                            ? "error"
                                            : "success"
                                    }
                                >
                                    <Input.Password
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                    ></Input.Password>
                                    {touched.confirmPassword && errors?.confirmPassword && (
                                        <div className="errorMessage">
                                            {errors?.confirmPassword}
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    validateStatus={
                                        errors.phone && touched.phone ? "error" : "success"
                                    }
                                >
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="phone"
                                        placeholder="Phone"
                                    ></Input>
                                    {touched.phone && errors?.phone && (
                                        <div className="errorMessage">{errors?.phone}</div>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        block
                                        loading={loading}
                                        disabled={loading || !isValid}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Sign Up
                                    </Button>
                                </Form.Item>
                                {notification && (
                                    <Form.Item>
                                        <span className={`${notification.type}Notification`}>
                                            {notification.message}
                                        </span>
                                    </Form.Item>
                                )}
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Signup;
