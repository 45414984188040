import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Provider from './context/provider';
import 'antd/dist/antd.variable.min.css';
import 'react-quill/dist/quill.snow.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV !== 'development') {
    disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider>
            <App />
        </Provider>
    </React.StrictMode>
);

