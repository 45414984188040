import {
  FC,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Rate, Comment, Avatar, Button } from "antd";
import { RateUpdate, ReviewModel } from "../../../models/rating.models";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "../../../context/provider";
import { AlertType } from "../../../context/models";
import { helpers } from "../../../utils/helpers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

interface ReviewProps {
  isCompanyReview: boolean;
  onUpdate: (
    args: RateUpdate,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onDelete: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  review: ReviewModel;
  showApplicantProfile: boolean;
}

const Review: FC<ReviewProps> = ({
  isCompanyReview,
  review,
  onUpdate,
  onDelete,
  showApplicantProfile,
}) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const { currentUser, Alert, Confirm } = useContext(Context);
  const [onEditMode, setOnEditMode] = useState(false);
  const [inputs, setInputs] = useState<RateUpdate>({
    notes: "",
    stars: 0,
    id: "",
  });
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  dayjs.extend(relativeTime);

  const { getFirstLetterOfDisplayname } = helpers;

  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      notes: review?.notes ?? "",
      stars: review?.stars ?? 0,
      id: review.id,
    }));
  }, [review]);

  const onChangeInputs = (key: string, value: string | number) => {
    setInputs((prevState) => ({ ...prevState, [key]: value }));
  };

  const onClickEdit = () => {
    setOnEditMode(true);
  };

  const onClickCancel = () => {
    setOnEditMode(false);
    setInputs((prevState) => ({
      ...prevState,
      notes: review?.notes ?? "",
      stars: review?.stars ?? 0,
    }));
  };

  const onClickSave = () => {
    if (inputs.notes && inputs.stars) {
      Confirm("Are you sure?", () => {
        setEditing(true);
        onUpdate(
          inputs,
          () => {
            setEditing(false);
            setOnEditMode(false);
            Alert("Review updated successfully.", AlertType.Success);
          },
          (err) => {
            setEditing(false);
            Alert(err.message, AlertType.Error);
          }
        );
      });
    } else {
      Alert("Please fill all fields.", AlertType.Warning);
    }
  };

  const onClickDelete = () => {
    Confirm("Are you sure?", () => {
      setDeleting(true);
      onDelete(
        inputs.id,
        () => {
          setDeleting(false);
          setOnEditMode(false);
          Alert("Review deleted successfully.", AlertType.Success);
        },
        (err) => {
          setDeleting(false);
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const isEditable = () => false;
    // currentUser.isLoggedIn &&
    // ((isCompanyReview && currentUser.applicantId === review.applicant.id) ||
    //   (!isCompanyReview && currentUser.companyId === review.company.id));

  const scrollToMessage = () => {
    const review_id = searchParams?.get("review_id");
    let matched = review_id === review.id;
    if (matched && ref.current && review_id) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
      ref.current.classList.add("matchedMessage");
      setTimeout(() => {
        ref.current?.classList.remove("matchedMessage");
        navigate("/reviews");
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToMessage();
    }, 300);
  }, [review.id]);

  const setProfilePhoto = (review: ReviewModel): string =>
    showApplicantProfile
      ? review.applicant.profilePhotoURL
      : review.company.logoURL;

  const setAuthorName = (review: ReviewModel): string =>
    showApplicantProfile
      ? `${review?.applicant?.firstName} ${review?.applicant?.lastName}`
      : `${review?.company?.name}`;

  const setActionButtons = (): ReactNode[] => {
    let output: ReactNode[] = [];
    if (isEditable()) {
      output = [
        <Button
          loading={editing}
          disabled={editing}
          size="small"
          onClick={onEditMode ? onClickSave : onClickEdit}
        >
          {onEditMode ? "Save" : "Edit"}
        </Button>,
        <Button
          loading={deleting}
          disabled={deleting || editing}
          size="small"
          onClick={onEditMode ? onClickCancel : onClickDelete}
        >
          {onEditMode ? "Cancel" : "Delete"}
        </Button>,
      ];
    }
    return output;
  };

  const setAuthorLocation = (review: ReviewModel): string =>
    showApplicantProfile
      ? `${review?.applicant?.city}`
      : `${review?.company?.city}`;

  const setCreatedAt = (review: ReviewModel): string => {
    return dayjs(new Date(review?.createdAt)?.toISOString()).fromNow()
  }

  return (
    <Comment
      author={
        <div className="author">
          <div><b>{setAuthorName(review)}</b></div>
          <div className="location"><b>{setAuthorLocation(review)}</b></div>
        </div>
      }
      avatar={
        <Avatar src={setProfilePhoto(review)} alt={`avatar`}>
          {getFirstLetterOfDisplayname(setAuthorName(review))}
        </Avatar>
      }
      actions={setActionButtons()}
      content={
        <div className="commentContent" ref={ref}>
          <div className="commentStars">
            <div className="starValues">
              <div>
                <Rate
                  disabled={!onEditMode}
                  value={inputs.stars}
                  onChange={(value) => onChangeInputs("stars", value)}
                />
                <span className="starText">
                  {inputs.stars}
                </span>
                <span className="pipeline">
                  {'|'}
                </span>
                <span className="createdAt">
                  {setCreatedAt(review)}
                </span>
              </div>
            </div>
          </div>
          {!onEditMode ? (
            <div className="notes">{inputs.notes}</div>
          ) : (
            <textarea
              value={inputs.notes}
              onChange={(e) => onChangeInputs("notes", e.target.value)}
            />
          )}
        </div>
      }
    />
  );
};

export default Review;
