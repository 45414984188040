import {
  faCalendar,
  faClock,
  faHeart,
  faInfinity,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Avatar, Typography, Tooltip } from "antd";
import { FC } from "react";
import { InternshipOutput } from "../../models/company.models";
import { helpers } from "../../utils/helpers";
import LongTextCutter from "../longTextCutter";
import "./internshipListItem.scss";

interface InternshipListItemProps {
  body: InternshipOutput;
  onClick: () => void;
  showMark?: boolean;
  marked?: boolean;
  onMark?: () => void;
  showLogo?: boolean;
  cutLongTexts?: boolean;
}

const { Title, Text } = Typography;

const InternshipListItem: FC<InternshipListItemProps> = ({
  body,
  onClick,
  showMark = false,
  marked = false,
  onMark = () => null,
  showLogo = true,
  cutLongTexts = false,
}) => {
  const { getFirstLetterOfDisplayname, setTypeStyle, priceFormatter } = helpers;

  return (
    <div className="internshipListItemContainer">
      <Row>
        <Col span={20} className="listItemLeftSide">
          <Title onClick={onClick} level={3}>
            <span className='titleWithLocation'>
              <LongTextCutter text={body?.title ?? ""} />{' '}
              { body?.location ? <span className="locationText">{`(${body?.location})`}</span> : '' }
            </span>
          </Title>
          <Title level={4} className='companyName'>
            <span className="by">{'by '}</span>
            <LongTextCutter text={body?.company?.name ?? ""} />
          </Title>
          <Row>
            <Col span={6}>
              <Title level={5}>
                <FontAwesomeIcon icon={faClock} />
                <span className="label">Type</span>
              </Title>
              <Text>
                <div className={`partTimeLabel`} style={setTypeStyle(body?.partTimeFullTime ?? '')}>
                    {body?.partTimeFullTime}
                </div>
              </Text>
              {/* <Text>{body?.partTimeFullTime}</Text> */}
            </Col>
            <Col span={6}>
              <Title level={5}>
                <FontAwesomeIcon icon={faCalendar} />
                <span className="label">Duration</span>
              </Title>
              <Text>{`${body?.duration ?? 0} ${body?.durationUnit ?? ''}`}</Text>
            </Col>
            <Col span={6}>
              <Title level={5}>
                <FontAwesomeIcon icon={faMoneyBill} />
                <span className="label">Fee</span>
              </Title>
              <Text>{priceFormatter(body?.price)}</Text>
            </Col>
            <Col span={6}>
              <Title level={5}>
                <FontAwesomeIcon icon={faInfinity} />
                <span className="label">Category</span>
              </Title>
              <Text>{cutLongTexts ? <LongTextCutter text={body?.category ?? ""} cuttingLimit={10} /> : body?.category}</Text>
            </Col>
          </Row>
        </Col>
        {showLogo && (
          <Col span={4}>
            <div className="avatarContainer">
              {marked ? (
                <FontAwesomeIcon
                  icon={faHeart}
                  color="red"
                  size="2x"
                  onClick={onMark}
                  className="heart"
                />
              ) : (
                <Tooltip title="Mark as Favorite" placement="left">
                  <img
                    src="/heart-regular.svg"
                    className="heart"
                    onClick={onMark}
                    alt="mark as favorite"
                  />
                </Tooltip>
              )}
              <div className="blurredContainer" onClick={onClick}>
                <Avatar src={body?.company?.logoURL}>
                  {getFirstLetterOfDisplayname(body?.company?.name ?? "C")}
                </Avatar>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InternshipListItem;
