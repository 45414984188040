import { GetReviewsInput, RateInput, RateOutput, RateUpdate } from "../models/rating.models";
import { InternseaAPI } from ".";
import { helpers } from "../utils/helpers";

export default class RatingService {
  private path = "rate";

  rateCompany = async (args: RateInput) => {
    try {
      const res = await InternseaAPI.post(`${this.path}`, {...args, isCompanyReview: true}, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  rateApplicant = async (args: RateInput) => {
    try {
      const res = await InternseaAPI.post(`${this.path}`, {...args, isCompanyReview: false}, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  updateRate = async (args: RateUpdate) => {
    try {
      const res = await InternseaAPI.put(`${this.path}/${args.id}`, args, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  deleteRate = async (id: string) => {
    try {
      const res = await InternseaAPI.delete(`${this.path}/${id}`, {});
      return res;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getApplicantReviews = async (applicant_id: string, isSentReviews: boolean): Promise<RateOutput> => {
    try {
      const query: GetReviewsInput = {
        applicant_id,
        isSentReviews,
      };
      const res = await InternseaAPI.get(`${this.path}/applicant`, {
        params: query
      });
      return res as unknown as RateOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };

  getCompanyReviews = async (company_id: string, isSentReviews: boolean): Promise<RateOutput> => {
    try {
      const query: GetReviewsInput = {
        company_id,
        isSentReviews,
      };
      const res = await InternseaAPI.get(`${this.path}/company`, {
        params: query
      });
      return res as unknown as RateOutput;
    } catch (error) {
      throw Error(helpers.errorHandler(error));
    }
  };
}
