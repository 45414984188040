import { FC, useContext, useMemo } from "react";
import CreateUpdateBillingAccount from "../../components/companyComponents/createUpdateBillingAccount";
import { Context } from "../../context/provider";
import { CreateCompanyAccountInput } from "../../models/company.models";
import CompanyService from "../../services/company";
import NotFoundPage from "../notFoundPage";

const CreateBillingAccountPage: FC = () => {
  const { currentUser } = useContext(Context);

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const inputs: CreateCompanyAccountInput = {
    title: "",
    nameOnAccount: "",
    paypalId: "",
    phone: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    accountNumber: "",
    IBAN: "",
    swift: ""
  };

  const onCreate = async (args: CreateCompanyAccountInput, successCallback: () => void, errCallback: (err: Error) => void) => {
    try {
      await companyService.createAccount(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  if (!currentUser.isCompany) {
    return <NotFoundPage />;
  }

  return <CreateUpdateBillingAccount inputs={inputs} onSubmit={onCreate} />;
};

export default CreateBillingAccountPage;
