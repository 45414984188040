import { FC } from "react";
import "./helpApplicants.scss";
import { Typography } from "antd";

const { Text, Title } = Typography;

const HelpApplicants: FC = () => {
  return (
    <div className="helpApplicantsContainer">
      <div className="helpApplicantsContent boxContainer">
        <Title>FAQ</Title>
        <Title level={2}>When can I pay to book an internship?</Title>
        <Text>
          If you're considering being part of an internship, first you'll have
          to apply for the position. Within 3 business days, you should receive
          an answer. When the employer approves your booking request, you'll be
          invited to proceed with the payment to book the internship experience.
        </Text>
        <Title level={2}>
          Can I apply for many internships at the same time?
        </Title>
        <Text>
          Absolutely! You could apply for a variety of unpaid internships. When
          you get approved by them (within 3 business days), then you can choose
          to book the internship that interests you most.
        </Text>
        <Title level={2}>Are all internships unpaid?</Title>
        <Text>Yes, all the internships are unpaid.</Text>
        <Title level={2}>
          What's the advantage of going for an Internsea's unpaid internship?
        </Title>
        <Text>
          You don't have to get any professional experience to get approved for
          an internship with our company partners. Instead of wasting months
          applying for highly competitive different internship positions, with
          Internsea, it's almost instant, you can get an internship like an
          Airbnb home and you'll take off your career way faster than your peers
          who go for the traditional way of securing an internship.
        </Text>
        <Title level={2}>
          Do I have to get a degree or be a student to apply for internship
          experiences?
        </Title>
        <Text>
          Not at all! Even if you have an interest in a field and would love to
          get a practical experience before you choose it as a major, you're
          qualified to apply.
        </Text>
        <Title level={2}>
          Can I get hired after the internship is finished?
        </Title>
        <Text>
          It depends on each and every company, many of them might hire you if
          they like your profile and your work ethic during the internship. Make
          the most of it!
        </Text>
        <Title level={2}>
          What's the price of every internship experience?
        </Title>
        <Text>Prices do vary from one company to the other.</Text>
        <Title level={2}>What are Internsea service fees?</Title>
        <Text>
          All interns (applicants) pay a service fee that is 15% of the booking
          subtotal.
        </Text>
        <Title level={2}>Is an unpaid internship worth the price?</Title>
        <Text>
          It costs tens, oftentimes hundreds of thousands of dollars to attend
          college and at the end of a 4-year degree, you practically have no
          advantage over all your peers as everyone applies for the same
          internships. Paying for an Internsea unpaid internship helps you to
          get started almost instantly, gaining experience fast in such a short
          time while paying only a tiny fraction of what an average college
          tuition would cost you.
        </Text>
        <Title level={2}>
          When would I be able to connect with the company to discuss the
          details of the internship?
        </Title>
        <Text>
          Whenever you get approved for the internship (before payment), you can
          get into contact with the company to ask them questions and discuss
          details with them before you book the internship.
        </Text>
        <Title level={2}>Can I ask to pay the company outside Internsea?</Title>
        <Text>
          It's stricly against Internsea policy. Any attempt to talk outside the
          platform would lead to a definitive ban.
        </Text>
      </div>
    </div>
  );
};

export default HelpApplicants;
