import Signup from '../../components/signup';
import {FC, useMemo} from 'react';
import AccountService from '../../services/account';
import {SignupInput} from '../../models/account.models';
import StorageModel from "../../httpClient/StorageModel";

const SignupPage: FC = () => {
    const appStorage = useMemo(() => {
        return new StorageModel();
    }, []);
    const authService = useMemo(() => {
        return new AccountService();
    }, []);

    const onSignup = async (
        formValues: SignupInput,
        successCallback: () => void,
        errCallback: (err: Error) => void
    ) => {
        try {
            await authService.signup(formValues);
            successCallback();
        } catch (error) {
            errCallback(error as Error);
        }
    };

    return <Signup onSignup={onSignup}/>;
};

export default SignupPage;
