import {Context, currentUserDefaultValue} from '../../context/provider';
import {FC, Fragment, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {AccessTokenPayload} from '../../models/account.models';
import StorageModel from "../../httpClient/StorageModel";
import AccountService from '../../services/account';
import { printError } from '../../utils/logger';
import AppLoader from '../appLoader';
import CompanyService from '../../services/company';
import ApplicantService from '../../services/applicant';

interface IAuthProps {
    children: ReactNode;
}

let existLocationPath = '';

const Auth: FC<IAuthProps> = ({children}) => {
    const {setCurrentUser, currentUserInfoUpdated} = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const accountService = useMemo(() => {
        return new AccountService();
    }, []);

    const companyService = useMemo(() => {
        return new CompanyService();
    }, []);

    const applicantService = useMemo(() => {
        return new ApplicantService();
    }, []);

    const AppStorage = useMemo(() => {
        return new StorageModel();
    }, []);

    useEffect(() => {
        existLocationPath = location.pathname;
    }, [location]);

    const isProfileCompleted = useCallback(async (isCompany: boolean) => {
        try {
            let isCompleted = false;
            if (isCompany) {
                isCompleted = await companyService.isProfileCompleted();
            } else {
                isCompleted = await applicantService.isProfileCompleted();
            }
            setCurrentUser((prevState) => ({
                ...prevState,
                isProfileCompleted: isCompleted,
            }))
        } catch (error) {
            printError([error])
        }
    }, [])

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            const token =(process.env.REACT_APP_ACCESS_TOKEN_NAME && AppStorage?.getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME!) )?? '';
            const decodedToken = token ? jwtDecode(token) : '';
            const nowInMilisecond = new Date().getTime();
            if (token && decodedToken && (decodedToken as any).exp * 1000 > nowInMilisecond) {
                try {
                    const res = await accountService.getAccount();
                    await isProfileCompleted((decodedToken as AccessTokenPayload).isCompany)
                    setLoading(false);
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        photoUrl: res.photoUrl,
                        displayName: res.displayName,
                        isLoggedIn: true,
                        ...(decodedToken as AccessTokenPayload),
                    }));
                } catch (error) {
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        ...currentUserDefaultValue
                    }));
                    setLoading(false);
                    printError([error]);
                }
                if (
                    existLocationPath === '/login' ||
                    existLocationPath === '/signup' ||
                    existLocationPath === '/forget_password' ||
                    existLocationPath.includes('reset_forgotten_password')
                ) {
                    navigate('/');
                }
            } else {
                setCurrentUser((prevState) => ({
                    ...prevState,
                    ...currentUserDefaultValue
                }));
                setLoading(false);
                AppStorage.deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
                if (
                    existLocationPath === '/bookings' ||
                    existLocationPath === '/earnings' ||
                    existLocationPath === '/internships' ||
                    existLocationPath === '/create-internship' ||
                    existLocationPath === '/messages' ||
                    existLocationPath === '/profile-edit' ||
                    existLocationPath === '/profile' ||
                    existLocationPath === '/reviews' ||
                    existLocationPath === '/change-password' ||
                    existLocationPath === '/change-billings-settings' ||
                    existLocationPath === '/create-billing-account' ||
                    existLocationPath.includes('internships/apply') ||
                    existLocationPath.includes('update-billing-account') ||
                    existLocationPath.includes('update-internship')
                ) {
                    navigate('/');
                }
            }
        };
        getUser();
    }, [currentUserInfoUpdated, isProfileCompleted]);

    if (loading) {
        return (
            <AppLoader />
        );
    };

    return <Fragment>{children}</Fragment>;
};

export default Auth;
