import { FC } from 'react';
import './host.scss';
import { Typography } from 'antd';

const { Text, Title } = Typography;

const Host: FC = () => {
  return (
    <div className='hostContainer'>
      <div className="hostContent boxContainer">
        <Title>
          Guide to becoming an Internship host
        </Title>
        <Title level={2}>
          1. List your internship experience
        </Title>
        <Text>
          First things first! We’ll ask for details like the location of your internship, full-time/part-time, duration, the key responsibilities of the intern, the benefits gained out of the whole internship experience.
        </Text>
        <Text>
          To help with your listing title and description, consider what makes your internship special. Also, note what might be important for interns to know before booking.
        </Text>
        <Title level={2}>
          2. Setting your controls
        </Title>
        <Text>
          To help interns understand your expectations, add rules to your listing under description, including details like restrictions on smoking inside the company, etc...
        </Text>
        <Text>
          To make sure you only get the number of interns you can host, unpublish your internship experience the moment you can the maximum you can allow. You will always have control over who you choose to be an intern in your company because applicants will always send an approval request to you, then you'll be able to check their profile before you accept them and book the internship.
        </Text>
        <Text>
          What you charge is always up to you. Take into consideration how reputable your company is to set your pricing.
        </Text>
        <Title level={2}>
          3. Prepping for your new intern
        </Title>
        <Text>
          When the intern books your internship experience, be prepared to connect with the intern to set up a time and location to get started.
        </Text>
        <Text>
          To help interns feel extra welcome, consider providing an initiation session where you give a company tour to your intern, making introductions with the team the intern will be working with closely.
        </Text>
      </div>
    </div>
  )
}

export default Host;