import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../../../context/provider";
import {
  ApplicantInput,
  ApplicantOutput,
  EducationParsedModel,
} from "../../../models/applicant.models";
import "./editApplicantProfile.scss";
import * as yup from "yup";
import {
  Form,
  Button,
  Input,
  Upload,
  Typography,
  Radio,
  UploadProps,
  UploadFile,
  DatePicker,
  Tooltip,
  Divider,
  Steps,
} from "antd";
import type { DatePickerProps } from "antd";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faContactCard,
  faEye,
  faFilePdf,
  faSchool,
  faStar,
  faTrash,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import EducationSection from "./educationSection";
import { AlertType } from "../../../context/models";
import { helpers } from "../../../utils/helpers";
import { dateFormat, genders } from "../../../utils/constants";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/lib/upload";
import LocationInput from "../../locationInput";
import { useNavigate, useSearchParams } from "react-router-dom";

interface EditApplicantProfileProps {
  applicantProfileInfo: ApplicantOutput;
  onUpdateApplicantProfile: (
    args: ApplicantInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}

const { Title } = Typography;

const { Step } = Steps;

const EditApplicantProfile: FC<EditApplicantProfileProps> = ({
  applicantProfileInfo,
  onUpdateApplicantProfile,
}) => {
  const [searchParams] = useSearchParams();
  const { Alert, setCurrentUserInfoUpdated, Confirm } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<null | UploadFile>(null);
  const [resume, setResume] = useState<null | UploadFile>(null);
  const [birthDate, setBirthDate] = useState(applicantProfileInfo?.birthDate);
  const [education, setEducation] = useState<EducationParsedModel[]>([]);
  const [isEducationFilled, setIsEducationFilled] = useState(true);
  const [city, setCity] = useState(applicantProfileInfo?.city);
  const navigate = useNavigate();

  const { convertFiletoBase64 } = helpers;

  useEffect(() => {
    setCity(applicantProfileInfo?.city);
  }, [applicantProfileInfo?.city]);

  useEffect(() => {
    setIsEducationFilled(
      education.length > 0 &&
        education.every(
          (child) => child.degree && child.fieldOfStudy && child.school
        )
    );
  }, [education]);

  const genderOptions = genders.map((child) => ({
    label: child,
    value: child,
  }));

  useEffect(() => {
    setBirthDate(applicantProfileInfo?.birthDate);
  }, [applicantProfileInfo?.birthDate]);

  useEffect(() => {
    setEducation(
      applicantProfileInfo?.education
        ? JSON.parse(applicantProfileInfo.education)
        : []
    );
  }, [applicantProfileInfo?.education]);

  const validationSchema = () =>
    yup.object().shape({
      firstName: yup
        .string()
        .typeError("Required")
        .max(50, "Maximum 50 characters")
        .required("Required"),
      lastName: yup
        .string()
        .typeError("Required")
        .max(50, "Maximum 50 characters")
        .required("Required"),
      gender: yup.string().nullable(),
      nationality: yup.string().nullable().max(50, "Maximum 50 characters"),
      phone: yup.string().nullable().max(25, "Maximum 25 characters"),
      linkedIn: yup.string().nullable().max(250, "Maximum 250 characters"),
      zipCode: yup.string().nullable().max(10, "Maximum 10 characters"),
      about: yup.string().nullable(),
    });

  const onClickSubmit = async (values: ApplicantOutput) => {
    setLoading(true);
    values = {
      ...values,
      birthDate,
      city,
      education: JSON.stringify(education),
    };
    if (profilePhoto) {
      const base64Photo = await convertFiletoBase64(profilePhoto.originFileObj);
      values = { ...values, profilePhoto: base64Photo as string };
    } else {
      values = { ...values, profilePhoto: "" };
    }
    if (resume) {
      const base64Resume = await convertFiletoBase64(resume.originFileObj);
      values = { ...values, resume: base64Resume as string };
    } else {
      values = { ...values, resume: "" };
    }
    onUpdateApplicantProfile(
      values as unknown as ApplicantInput,
      () => {
        setLoading(false);
        setCurrentUserInfoUpdated((prevState) => !prevState);
        Alert("Your profile was updated successfully.", AlertType.Success);
        const redirection_path = searchParams?.get("redirection_path");
        if (redirection_path) {
          navigate(redirection_path);
        }
      },
      (err) => {
        setLoading(false);
        Alert(err.message, AlertType.Error);
      }
    );
  };

  const onClickDeleteProfile = async () => {
    Confirm("Are you sure?", () => {
      const values = { ...applicantProfileInfo, profilePhoto: "delete" };
      onUpdateApplicantProfile(
        values as unknown as ApplicantInput,
        () => {
          setCurrentUserInfoUpdated((prevState) => !prevState);
          Alert("Profile photo deleted successfully.", AlertType.Success);
        },
        (err) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const onClickDeleteResume = async () => {
    Confirm("Are you sure?", () => {
      const values = { ...applicantProfileInfo, resume: "delete" };
      onUpdateApplicantProfile(
        values as unknown as ApplicantInput,
        () => {
          setCurrentUserInfoUpdated((prevState) => !prevState);
          Alert("Resume deleted successfully.", AlertType.Success);
        },
        (err) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const uploadProfilePhotoProps: UploadProps = {
    beforeUpload: (file) => {
      const isValid =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isValid) {
        Alert(`${file.name} is not a valid file`, AlertType.Warning);
      }
      return isValid || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.fileList.length > 0) {
        const file = info.fileList[0];
        setProfilePhoto(file);
      }
    },
    onPreview: async (file) => {
      let src = file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
    onRemove: () => {
      setProfilePhoto(null);
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        (onSuccess as Function)("ok");
      }, 0);
    },
    accept: "image/png, image/jpeg, image/jpg",
    maxCount: 1,
    listType: "picture-card",
  };

  const uploadResumeProps: UploadProps = {
    beforeUpload: (file) => {
      const isValid = file.type === "application/pdf";
      if (!isValid) {
        Alert(`${file.name} is not a valid file`, AlertType.Warning);
      }
      return isValid || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.fileList.length > 0) {
        const file = info.fileList[0];
        setResume(file);
      }
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        (onSuccess as Function)("ok");
      }, 0);
    },
    onRemove: () => {
      setResume(null);
    },
    accept: "application/pdf",
  };

  const onChangeBirthDate: DatePickerProps["onChange"] = (
    _date,
    dateString
  ) => {
    setBirthDate(new Date(dateString));
  };

  const setSteps = (values: ApplicantOutput) => {
    let personal = false;
    if ((values.firstName) &&
        (values.lastName) &&
        (values.gender) &&
        (birthDate) &&
        (values.about))
      personal = true;
    
    const education = isEducationFilled;
    let contactInfo =false;
    if (values.phone && values.city && values.zipCode)
      contactInfo = true;
    const uploadedResume = resume || values?.resumeURL;
    const setStatus = (arg: any): "finish" | "wait" =>
      arg ? "finish" : "wait";
    return [
      setStatus(personal),
      setStatus(education),
      setStatus(contactInfo),
      setStatus(uploadedResume),
    ];
  };

  return (
    <div className="editApplicantProfileContainer">
      <div className="editProfileContent boxContainer">
        <Formik
          initialValues={applicantProfileInfo}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={validationSchema()}
          onSubmit={onClickSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => {
            return (
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
              >
                <Steps>
                  <Step
                    title="Personal Info"
                    icon={<FontAwesomeIcon icon={faUser} />}
                    status={setSteps(values)[0]}
                  />
                  <Step
                    title="Education"
                    icon={<FontAwesomeIcon icon={faSchool} />}
                    status={setSteps(values)[1]}
                  />
                  <Step
                    title="Contact Info"
                    icon={<FontAwesomeIcon icon={faContactCard} />}
                    status={setSteps(values)[2]}
                  />
                  {/* <Step
                    title="Resume"
                    icon={<FontAwesomeIcon icon={faFilePdf} />}
                    status={setSteps(values)[3]}
                  /> */}
                </Steps>
                <Divider />
                <Form.Item>
                  <div className="formHeader">
                    <Title>Personal Info</Title>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.firstName && touched.firstName
                          ? "error"
                          : "success"
                      }
                    >
                      <div>
                        First Name{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>{" "}
                      </div>
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      ></Input>
                      {touched.firstName && errors?.firstName && (
                        <div className="errorMessage">{errors?.firstName}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.lastName && touched.lastName
                          ? "error"
                          : "success"
                      }
                    >
                      <div>
                        Last Name{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                            className="formRequiredStar"
                            icon={faStar}
                            color="var(--red-5)"
                          />
                        </Tooltip>
                      </div>
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      ></Input>
                      {touched.lastName && errors?.lastName && (
                        <div className="errorMessage">{errors?.lastName}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.gender && touched.gender ? "error" : "success"
                      }
                    >
                      <div>Gender{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                              className="formRequiredStar"
                              icon={faStar}
                              color="var(--red-5)"
                          />
                        </Tooltip></div>
                      <Radio.Group
                        name="gender"
                        onBlur={handleBlur}
                        options={genderOptions}
                        onChange={handleChange}
                        value={values?.gender }
                        optionType="button"
                      />
                      {touched.gender && errors?.gender && (
                        <div className="errorMessage">{errors?.gender}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.nationality && touched.nationality
                          ? "error"
                          : "success"
                      }
                    >
                      <div>Nationality</div>
                      <Input
                        name="nationality"
                        placeholder="Nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationality}
                      ></Input>
                      {touched.nationality && errors?.nationality && (
                        <div className="errorMessage">
                          {errors?.nationality}
                        </div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item>
                      <div>Birth Date{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                              className="formRequiredStar"
                              icon={faStar}
                              color="var(--red-5)"
                          />
                        </Tooltip></div>
                      <DatePicker
                        value={
                          birthDate ? moment(birthDate, dateFormat) : undefined
                        }
                        format={dateFormat}
                        onChange={onChangeBirthDate}
                        allowClear={false}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div>Profile Photo{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                              className="formRequiredStar"
                              icon={faStar}
                              color="var(--red-5)"
                          />
                        </Tooltip></div>
                      <div className="uploadSection">
                        <ImgCrop rotate>
                          <Upload {...uploadProfilePhotoProps}>
                            {!profilePhoto && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                        {applicantProfileInfo?.profilePhotoURL && (
                          <Button
                            type="primary"
                            onClick={onClickDeleteProfile}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  validateStatus={
                    errors.about && touched.about ? "error" : "success"
                  }
                >
                  <div>About Me{" "}
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                          className="formRequiredStar"
                          icon={faStar}
                          color="var(--red-5)"
                      />
                    </Tooltip></div>
                  <textarea
                    placeholder="About Me"
                    value={values.about}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="about"
                    className={`richTextEditor`}
                  />
                  {touched.about && errors?.about && (
                    <div className="errorMessage">{errors?.about}</div>
                  )}
                </Form.Item>
                <Divider />
                <Form.Item>
                  <Title>Education{" "}
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                          className="formRequiredStar"
                          icon={faStar}
                          color="var(--red-5)"
                      />
                    </Tooltip></Title>
                </Form.Item>
                <EducationSection
                  educations={education}
                  onChangeEducations={(args) => setEducation(args)}
                />
                <Divider />
                <Form.Item>
                  <Title>Contact Info</Title>
                </Form.Item>
                <Form.Item>
                  <div>Location{" "}
                    <Tooltip title="Required">
                      <FontAwesomeIcon
                          className="formRequiredStar"
                          icon={faStar}
                          color="var(--red-5)"
                      />
                    </Tooltip></div>
                  <LocationInput
                    value={city}
                    onChange={(val) => setCity(val)}
                  />
                </Form.Item>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.phone && touched.phone ? "error" : "success"
                      }
                    >
                      <div>Phone{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                              className="formRequiredStar"
                              icon={faStar}
                              color="var(--red-5)"
                          />
                        </Tooltip></div>
                      <Input
                        name="phone"
                        placeholder="Phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      ></Input>
                      {touched.phone && errors?.phone && (
                        <div className="errorMessage">{errors?.phone}</div>
                      )}
                    </Form.Item>
                    <Form.Item
                      validateStatus={
                        errors.zipCode && touched.zipCode ? "error" : "success"
                      }
                    >
                      <div>Zip Code{" "}
                        <Tooltip title="Required">
                          <FontAwesomeIcon
                              className="formRequiredStar"
                              icon={faStar}
                              color="var(--red-5)"
                          />
                        </Tooltip></div>
                      <Input
                        name="zipCode"
                        placeholder="Zip Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode}
                      ></Input>
                      {touched.zipCode && errors?.zipCode && (
                        <div className="errorMessage">{errors?.zipCode}</div>
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Divider />
                <Form.Item>
                  <Title>Resume</Title>
                </Form.Item>
                <Form.Item>
                  <div className="uploadSection">
                    <Upload {...uploadResumeProps} maxCount={1}>
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faUpload} />}
                      >
                        Upload
                      </Button>
                    </Upload>
                    {applicantProfileInfo?.resumeURL && (
                      <Fragment>
                        <Button
                          type="primary"
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          onClick={onClickDeleteResume}
                        >
                          Delete
                        </Button>
                        <a
                          href={applicantProfileInfo?.resumeURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="primary"
                            icon={<FontAwesomeIcon icon={faEye} />}
                          >
                            Preview
                          </Button>
                        </a>
                      </Fragment>
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditApplicantProfile;
