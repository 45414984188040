import { AccessTokenPayload } from "../../models/account.models";

export interface CurrentUserModel extends AccessTokenPayload {
  isLoggedIn: boolean;
  displayName: string;
  photoUrl: string;
  isProfileCompleted: boolean;
}

export enum AlertConfirmType {
  Alert,
  Confirm,
}

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  SuccessForMessageSending = 'succes_for_message_sending'
}

export interface AlertConfirmModel {
  type: null | AlertConfirmType;
  alertType: null | AlertType;
  show: boolean;
  text: string;
  okClickFunc: Function;
  timeout: number;
}

export interface BreadCrumbPageModel {
  name: string;
  path: string;
}

export enum MessageType {
  Create,
  Reply,
}

export interface MessageModel {
  type: MessageType;
  show: boolean;
  toId: string | null;
  subject: string;
  onClose: () => void;
}

export interface RatingModel {
  show: boolean;
  internshipId: null | string;
  applicantOrCompanyId: null | string;
  onClose: () => void;
}