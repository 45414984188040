import { FC, useContext, useMemo } from "react";
import CreateUpdateInternship from "../../components/companyComponents/createUpdateInternship";
import { Context } from "../../context/provider";
import { CreateInternshipInput } from "../../models/company.models";
import CompanyService from "../../services/company";
import NotFoundPage from "../notFoundPage";

const CreateInternshipPage: FC = () => {
  const { currentUser } = useContext(Context);
  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const inputs: CreateInternshipInput = {
    title: "",
    location: "",
    duration: 0,
    durationUnit: "",
    physicalRemote: "",
    whoCanApply: "",
    partTimeFullTime: "",
    price: 0,
    weeklyWorkHours: 0,
    seatCount: 0,
    responsibilities: "",
    whatYouWillLearn: "",
    isPublished: true,
    summary: "",
    category: "",
    isFreeOfCharge: false
  };

  const onCreateInternship = async (
    args: CreateInternshipInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await companyService.createInternship(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  if (!currentUser.isCompany) {
    return <NotFoundPage />;
  }

  return <CreateUpdateInternship onSubmit={onCreateInternship} inputs={inputs} />;
};

export default CreateInternshipPage;
