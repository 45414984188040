import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Form, Button, Tooltip } from "antd";
import { FC, Fragment } from "react";
import "./listCreator.scss";

interface ListCreatorProps {
  args: string[];
  onChangeList: (args: string[]) => void;
  placeholder?: string;
}

const ListCreator: FC<ListCreatorProps> = ({ args, onChangeList, placeholder = '' }) => {
  const onChange = (index: number, value: string) => {
    const existList: string[] = args;
    existList[index] = value;
    onChangeList([...existList]);
  };

  const onClickAddNew = () => {
    let existList: string[] = args;
    existList = [...existList, ""];
    onChangeList([...existList]);
  };

  const onDelete = (index: number) => {
    let existList: string[] = args;
    existList = existList.filter((child, i) => i !== index);
    onChangeList([...existList]);
  };

  const disabledAddNew = () => args.length > 0 && args[args.length - 1] === "";

  return (
    <Fragment>
      {args?.map((child, index) => (
        <Form.Item>
          <div className="listCreatorInput">
            <Input
              onChange={(e) => onChange(index, e.target.value)}
              value={child || ""}
              placeholder={placeholder}
            ></Input>
            <Button
              icon={<FontAwesomeIcon icon={faTrash} color="var(--red-7)" />}
              onClick={() => onDelete(index)}
            />
          </div>
        </Form.Item>
      ))}
      <Form.Item>
        <Tooltip title='Add New'>
          <Button
            type="primary"
            onClick={onClickAddNew}
            disabled={disabledAddNew()}
            className="addNew"
            icon={<FontAwesomeIcon icon={faPlus} />}
          />
        </Tooltip>
      </Form.Item>
    </Fragment>
  );
};

export default ListCreator;
