import { FC, useEffect, CSSProperties } from 'react';
import { useLocation, Location, useNavigate, NavigateFunction } from 'react-router-dom';

const NotFoundPage: FC = () => {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  }, [navigate]);

  const style: CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return <div style={style}>{`"${location.pathname}" path doesnt exist.`}</div>;
};

export default NotFoundPage;
