import { AxiosRequestConfig } from 'axios';
import StorageModel from "../StorageModel";

export interface TokenRequestInterceptorOptions {
    document: any;
    tokenKey?: string;
}

export default class TokenRequestInterceptor {
    private storage: StorageModel;

    constructor(options: TokenRequestInterceptorOptions) {
        this.storage = new StorageModel(options.document);
    }
    async onFulfilled(conf: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        // @ts-ignore
        const token = this.storage.getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);

        if (token) {
            // @ts-ignore
            conf.headers['authorization'] = 'Bearer ' + token;
        }

        return conf;
    }

    onRejected(error: any): any {
        return Promise.reject(error);
    }
}