import { FC, useContext, useEffect, useMemo, useState } from "react";
import Messages, { MessageUserModel } from "../../components/messages";
import PageLoader from "../../components/pageLoader";
import { AlertType, MessageType } from "../../context/models";
import { Context } from "../../context/provider";
import { MessageDto } from "../../models/message.models";
import MessageService from "../../services/message";
import { printError } from "../../utils/logger";

const MessagesPage: FC = () => {
  const { currentUser, Alert, sendMessage, Confirm, updatedMessages } = useContext(Context);
  const messageService = useMemo(() => {
    return new MessageService();
  }, []);

  const [inboxMessages, setInboxMessages] = useState<MessageDto[]>([]);
  const [outboxMessages, setOutboxMessages] = useState<MessageDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<MessageUserModel[]>([]);

  const getMessages = async () => {
    try {
      const res = await messageService.getMessages();
      setInboxMessages(
        res.filter((child) => child.toAccount.id === currentUser.accountId)
      );
      setOutboxMessages(
        res.filter((child) => child.fromAccount.id === currentUser.accountId)
      );
      const outboxUsers: MessageUserModel[] = res.map((child) => ({ name: child.toAccount.name, id: child.toAccount.id }));
      const inboxUsers: MessageUserModel[] = res.map((child) => ({ name: child.fromAccount.name, id: child.fromAccount.id }));
      let allUsers = [ ...inboxUsers, ...outboxUsers ];
      allUsers = allUsers.filter((child) => child.id !== currentUser.accountId);
      const stringifiedUsers = allUsers.map((child) => JSON.stringify(child));
      const notDuplicatedUsers = [...(new Set(stringifiedUsers) as unknown as any[])];
      let parsedUsers = notDuplicatedUsers.map((child) => JSON.parse(child));
      parsedUsers = [ { name: 'Show All Users', id: '' }, ...parsedUsers ];
      setUsers(parsedUsers);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const markAsRead = async (id: string, showAlert = true) => {
    try {
      await messageService.markAsReadMessage(id);
      let existInboxMessages = inboxMessages;
      const messageIndex = existInboxMessages.findIndex(
        (child) => child.id === id
      );
      existInboxMessages[messageIndex].isRead = true;
      setInboxMessages([...existInboxMessages]);
      if (showAlert) {
        Alert("The message marked as read successfully.", AlertType.Success);
      }
    } catch (error) {
      if (showAlert) {
        Alert((error as Error).message, AlertType.Error);
      }
      printError([error]);
    }
  };

  const deleteMessage = (id: string) => {
    Confirm('Are you sure?', async () => {
      try {
        await messageService.deleteMessage(id);
        let existInboxMessages = inboxMessages;
        existInboxMessages = existInboxMessages.filter(
          (child) => child.id !== id
        );
        setInboxMessages([...existInboxMessages]);
        let existOutboxMessages = outboxMessages;
        existOutboxMessages = existOutboxMessages.filter(
          (child) => child.id !== id
        );
        setOutboxMessages([...existOutboxMessages]);
        Alert("The message deleted successfully.", AlertType.Success);
      } catch (error) {
        Alert((error as Error).message, AlertType.Error);
      }
    });
  };

  const replyMessage = (messageId: string) => {
    const accountId = inboxMessages?.find((child) => child.id === messageId)
      ?.fromAccount?.id;
    if (accountId) {
      markAsRead(messageId, false);
      sendMessage(accountId, MessageType.Reply);
    } else {
      Alert("Somethings went wrong. Please try again.", AlertType.Error);
    }
  };

  useEffect(() => {
    getMessages();
  }, [currentUser.accountId, updatedMessages]);

  if (loading) {
    return <PageLoader active avatar numOfSkeleton={3} />;
  }

  return (
    <Messages
      inboxMessages={inboxMessages}
      outboxMessages={outboxMessages}
      markAsRead={markAsRead}
      deleteMessage={deleteMessage}
      replyMessage={replyMessage}
      users={users}
    />
  );
};

export default MessagesPage;
