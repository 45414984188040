import { FC, useContext, useEffect, useMemo, useState, Fragment } from "react";
import ApplicantApplications from "../../components/applicantComponents/applications";
import CompanyApplications from "../../components/companyComponents/applications";
import PageLoader from "../../components/pageLoader";
import { AlertType, MessageType } from "../../context/models";
import { Context } from "../../context/provider";
import {
  ApplicationOutput,
  ApplicationStatus,
  UpdateApplicationInput,
} from "../../models/company.models";
import ApplicantService from "../../services/applicant";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";

const ApplicationsPage: FC = () => {
  const { currentUser, sendMessage, Alert, updatedApplications } =
    useContext(Context);

  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  const [companyApplications, setCompanyApplications] = useState<
    ApplicationOutput[]
  >([]);
  const [applicantApplications, setApplicantApplications] = useState<
    ApplicationOutput[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [isPublished, setIsPublished] = useState(true);

  const sendMessageToApplicant = (id: string) => {
    sendMessage(id, MessageType.Create);
  };

  const showPublishedOnes = (published: boolean) => {
    setIsPublished(published);
  };

  const getCompanyApplications = async () => {
    try {
      const res = await companyService.getAllApplications();
      setCompanyApplications(res);
      setLoading(false);
    } catch (error) {
      printError([error]);
      setLoading(false);
    }
  };

  const getApplicantApplications = async () => {
    try {
      const res = await applicantService.getAllApplications();
      setApplicantApplications(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      printError([error]);
    }
  };

  const deleteApplicantApplication = async (id: string) => {
    try {
      if (
        applicantApplications?.find((child) => child.id === id)?.status ===
        ApplicationStatus.Pending
      ) {
        await applicantService.deleteApplication({ id });
        let existApplications = applicantApplications;
        existApplications = existApplications.filter(
          (child) => child.id !== id
        );
        setApplicantApplications([...existApplications]);
        Alert("The application deleted successfully.", AlertType.Success);
      } else {
        Alert("You can delete only pending applications", AlertType.Warning);
      }
    } catch (error) {
      Alert((error as Error).message, AlertType.Error);
    }
  };

  const onUpdateCompanyApplication = async (
    args: UpdateApplicationInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await companyService.updateApplication(args);
      successCallback();
      getCompanyApplications();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const sendReminderEmail = async (
    applicationId: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await companyService.sendReminderEmailForApplication(applicationId);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const onDeleteCompanyApplication = async (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await companyService.deleteApplication({ id: id! });
      successCallback();
      getCompanyApplications();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  useEffect(() => {
    if (currentUser.isCompany) {
      getCompanyApplications();
    } else {
      getApplicantApplications();
    }
  }, [currentUser.isCompany, isPublished, updatedApplications]);

  if (loading) {
    return <PageLoader active avatar />;
  }

  return (
    <Fragment>
      {currentUser.isCompany ? (
        <CompanyApplications
          applications={companyApplications}
          showPublishedOnes={showPublishedOnes}
          onUpdateApplication={onUpdateCompanyApplication}
          onSendMessage={sendMessageToApplicant}
          onDelete={onDeleteCompanyApplication}
          sendReminderEmail={sendReminderEmail}
        />
      ) : (
        <ApplicantApplications
          applications={applicantApplications}
          deleteApplication={deleteApplicantApplication}
        />
      )}
    </Fragment>
  );
};

export default ApplicationsPage;
