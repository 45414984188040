import { Switch } from "antd";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import PageLoader from "../../components/pageLoader";
import RatingReviews from "../../components/ratingReviews";
import { Context } from "../../context/provider";
import { RateOutput, RateUpdate } from "../../models/rating.models";
import RatingService from "../../services/rating";
import { printError } from "../../utils/logger";

const ReviewsPage: FC = () => {
  const { currentUser } = useContext(Context);

  const ratingService = useMemo(() => {
    return new RatingService();
  }, []);

  const [showSentReviews, setShowSentReviews] = useState(false);
  const [reviews, setReviews] = useState<RateOutput>({} as RateOutput);
  const [loading, setLoading] = useState(true);
  const [updatedReviews, setUpdatedReviews] = useState(false);

  const onChangeSwitch = () => {
    setShowSentReviews((prevState) => !prevState);
  };

  const getCompanyReviews = async () => {
    try {
      const res = await ratingService.getCompanyReviews(
        currentUser.companyId,
        showSentReviews
      );
      setLoading(false);
      setReviews(res);
    } catch (error) {
      printError([error]);
    }
  };

  const getApplicantReviews = async () => {
    try {
      const res = await ratingService.getApplicantReviews(
        currentUser.applicantId,
        showSentReviews
      );
      setLoading(false);
      setReviews(res);
    } catch (error) {
      printError([error]);
    }
  };

  const onUpdateReview = async (
    args: RateUpdate,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await ratingService.updateRate(args);
      successCallback();
      setUpdatedReviews((prevState) => !prevState);
    } catch (error) {
      errCallback(error as Error);
    }
  };

  const onDeleteReview = async (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await ratingService.deleteRate(id);
      successCallback();
      setUpdatedReviews((prevState) => !prevState);
    } catch (error) {
      errCallback(error as Error);
    }
  };

  useEffect(() => {
    if (currentUser.isCompany) {
      getCompanyReviews();
    } else {
      getApplicantReviews();
    }
  }, [currentUser, showSentReviews, updatedReviews]);

  if (loading) {
    return <PageLoader active avatar />;
  }

  return (
    <RatingReviews
      showApplicantProfile = {currentUser.isCompany}
      ratings={reviews}
      onDelete={onDeleteReview}
      onUpdate={onUpdateReview}
      isCompanyReview={
        (currentUser.isCompany && !showSentReviews) ||
        (!currentUser.isCompany && showSentReviews)
      }
      toogle={
        <span>
            {showSentReviews ? 'Reviews by me' : 'Reviews on me'}
            <Switch checked={showSentReviews} onChange={onChangeSwitch} />
        </span>
      }
    />
  );
};

export default ReviewsPage;
