import { Button, Card, Divider, Input, Radio, Select } from "antd";
import { FC } from "react";
import { SearchInternshipParamsModel } from "../../../models/company.models";
import {
  internshipCategoryOptions,
  internshipDurationUnitOptions,
  internshipPartTimeFullTimeOptions,
  internshipPhysicalRemoteOptions,
  internshipWhoCanApplyOptions,
} from "../../../utils/constants";
import { helpers } from "../../../utils/helpers";

interface Props {
  onChangeParams: (key: string, value: string | number) => void;
  onApply: (params: SearchInternshipParamsModel) => void;
  onReset: () => void;
  searchParams: SearchInternshipParamsModel;
  locationOptions: string[];
}

const { Option } = Select;

const FilterSection: FC<Props> = ({
  onChangeParams,
  onApply,
  onReset,
  searchParams,
  locationOptions,
}) => {
  const { selectOptionsWithPlaceholderValue } = helpers;

  return (
    <Card title="Filter">
      <div>Keywords</div>
      <Input
        name="title"
        placeholder="Search Job Titles, Company Names..."
        onChange={(e) => onChangeParams(e.target.name, e.target.value)}
        value={searchParams.title}
        onKeyDown={(e) => {
          if (e.key === "Enter") onApply(searchParams);
        }}
      ></Input>
      <div>Location</div>
      <Select
        showSearch
        value={searchParams.location}
        onChange={(value) => onChangeParams("location", value)}
      >
        {selectOptionsWithPlaceholderValue(locationOptions, "Select One")?.map(
          (child, index) => (
            <Option key={index} value={child.value}>
              {child.label}
            </Option>
          )
        )}
      </Select>
      <div>Category</div>
      <Select
        value={searchParams.category}
        onChange={(value) => onChangeParams("category", value)}
      >
        {selectOptionsWithPlaceholderValue(
          internshipCategoryOptions,
          "Select One"
        )?.map((child, index) => (
          <Option key={index} value={child.value}>
            {child.label}
          </Option>
        ))}
      </Select>
      <div className="durationFilter">
        <div>
          <div>Duration</div>
          <Input
            type="number"
            name="duration"
            placeholder="Duration"
            onChange={(e) =>
              onChangeParams(e.target.name, e.target.valueAsNumber)
            }
            value={searchParams.duration}
          ></Input>
        </div>
        <div>
          <div>Duration Unit</div>
          <Select
            value={searchParams.durationUnit}
            onChange={(value) => onChangeParams("durationUnit", value)}
          >
            {selectOptionsWithPlaceholderValue(
              internshipDurationUnitOptions,
              "Select One"
            )?.map((child, index) => (
              <Option key={index} value={child.value}>
                {child.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div>Who Can Apply?</div>
      <div>
        <Radio
          checked={searchParams.whoCanApply === internshipWhoCanApplyOptions[0]}
          onChange={() =>
            onChangeParams("whoCanApply", internshipWhoCanApplyOptions[0])
          }
        >
          Graduates
        </Radio>
      </div>
      <div>
        <Radio
          checked={searchParams.whoCanApply === internshipWhoCanApplyOptions[1]}
          onChange={() =>
            onChangeParams("whoCanApply", internshipWhoCanApplyOptions[1])
          }
        >
          Students
        </Radio>
      </div>
      <div>
        <Radio
          checked={searchParams.whoCanApply === internshipWhoCanApplyOptions[2]}
          onChange={() =>
            onChangeParams("whoCanApply", internshipWhoCanApplyOptions[2])
          }
        >
          Grads & Students
        </Radio>
      </div>
      <div style={{ marginTop: "15px" }}>Type</div>
      <div>
        <Radio
          checked={
            searchParams.partTimeFullTime ===
            internshipPartTimeFullTimeOptions[1]
          }
          onChange={() => {
            onChangeParams(
              "partTimeFullTime",
              internshipPartTimeFullTimeOptions[1]
            );
          }}
        >
          Full-Time
        </Radio>
      </div>
      <div>
        <Radio
          checked={
            searchParams.partTimeFullTime ===
            internshipPartTimeFullTimeOptions[0]
          }
          onChange={() => {
            onChangeParams(
              "partTimeFullTime",
              internshipPartTimeFullTimeOptions[0]
            );
          }}
        >
          Part-Time
        </Radio>
      </div>
      <div style={{ marginTop: "15px" }}>Physical/Remote</div>
      <div>
        <Radio
          checked={
            searchParams.physicalRemote === internshipPhysicalRemoteOptions[0]
          }
          onChange={() => {
            onChangeParams(
              "physicalRemote",
              internshipPhysicalRemoteOptions[0]
            );
          }}
        >
          Physical
        </Radio>
      </div>
      <div>
        <Radio
          checked={
            searchParams.physicalRemote === internshipPhysicalRemoteOptions[1]
          }
          onChange={() => {
            onChangeParams(
              "physicalRemote",
              internshipPhysicalRemoteOptions[1]
            );
          }}
        >
          Remote
        </Radio>
      </div>
      <Divider />
      <Button type="primary" onClick={() => onApply(searchParams)}>
        Search
      </Button>
      <Button type="ghost" onClick={onReset} className="resetButton">
        Reset
      </Button>
    </Card>
  );
};

export default FilterSection;
