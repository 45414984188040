import { FC } from 'react';
import { Layout, Button, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const { Footer } = Layout;
const { Title } = Typography;

interface AppFooterProps {
  closeSidebar: () => void;
}

const AppFooter: FC<AppFooterProps> = ({ closeSidebar }) => {
  const navigate = useNavigate();

  return (
    <Footer className="footer">
      <div className="footerTop">
        <div className="leftSide column">
          <img src="/logo.png" alt="logo" className="logo" onClick={() => [navigate("/"), closeSidebar()]} />
          <div className="description">
            Internsea is an online marketplace that allows people to book an unpaid internship
            as easy as booking a home on Airbnb.
          </div>
          <div className="social">
            <Button shape="circle" icon={<img src="/facebook.svg" />} />
            <Button shape="circle" icon={<img src="/twitter.svg" />} />
            <Button shape="circle" icon={<img src="/instagram.svg" />} />
          </div>
        </div>
        <div className="column">
          <Title level={3}>About</Title>
          <div className="li" onClick={() => navigate('/how-internsea-works')}>
            <FontAwesomeIcon icon={faChevronRight} size="1x" /> How Internsea Works
          </div>
          <div className="li" onClick={() => navigate('/help')}>
            <FontAwesomeIcon icon={faChevronRight} size="1x" /> Help
          </div>
        </div>
        <div className="column">
          <Title level={3}>Companies</Title>
          <div className="li" onClick={() => navigate('/host')}>
            <FontAwesomeIcon icon={faChevronRight} size="1x" /> Host An Unpaid Internship
          </div>
          <div className="li" onClick={() => navigate('/help-companies')}>
            <FontAwesomeIcon icon={faChevronRight} size="1x" /> Help
          </div>
          <div className="emailSection">
            <FontAwesomeIcon icon={faEnvelope} />
            support@internsea.com
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="copyright">© 2022 Internsea, Inc.</div>
        <div className="privacyAndTerms">
          <a
            href="https://www.privacypolicyonline.com/live.php?token=xR0zfFqVRVo4uKelIPL8eaT2OcqZLlvB"
            target="_blank"
            rel="noreferrer">
            <Button type="text">Privacy</Button>
          </a>
          <a
            href="https://www.privacypolicyonline.com/live.php?token=jAmNG1Bm9PRnc4RwToLOGCno8IX90Ico"
            target="_blank"
            rel="noreferrer">
            <Button type="text">Terms</Button>
          </a>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
