import { FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

interface LocationInputProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
}

const LocationInput: FC<LocationInputProps> = ({
  onChange,
  value,
  placeholder = "City, Country",
}) => (
  <GooglePlacesAutocomplete
    autocompletionRequest={{
      types: ["administrative_area_level_1", "political"],
    }}
    selectProps={{
      placeholder,
      defaultInputValue: value,
      onChange: (val: any) => onChange(val.label),
    }}
  />
);

export default LocationInput;
