import { FC, RefObject, useEffect, useRef } from "react";
import { Comment } from "antd";
import { MessageDataModel } from "..";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface MessageProps {
  item: MessageDataModel;
  id: string;
  markAsRead: (id: string, showAlert?: boolean) => void;
  replyMessage: (messageId: string) => void;
}

const Message: FC<MessageProps> = ({ item, id, markAsRead, replyMessage }) => {
  const ref: RefObject<HTMLLIElement> = useRef<HTMLLIElement>(null);
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToMessage = () => {
    const replyMessageId = searchParams?.get("replyMessageId");

    if (replyMessageId === id) {
      replyMessage(replyMessageId)
    }

    let hashMatched = location.hash.replace('#', '') === id || replyMessageId === id;
    if (hashMatched && ref.current && id) {
      markAsRead(id, false);
      ref.current.scrollIntoView({ behavior: "smooth", block: 'center' });
      ref.current.classList.add('matchedMessage');
      setTimeout(() => {
        ref.current?.classList.remove('matchedMessage');
        navigate('/messages');
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToMessage();
    }, 300);
  }, [location.hash, id]);

  return (
    <li ref={ref}>
      <Comment
        actions={item.actions}
        author={item.author}
        avatar={item.avatar}
        content={item.content}
        datetime={item.datetime}
      />
    </li>
  );
};

export default Message;
