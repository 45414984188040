import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Menu, Tabs } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import {
  ApplicationOutput,
  ApplicationStatus,
  UpdateApplicationInput,
} from "../../../models/company.models";
import "./applications.scss";
import ApplicationTable from "./../applicationTable/index";
import ApplicantProfileView from "../applicantProfileView";
import { AlertType } from "../../../context/models";
import { Context } from "../../../context/provider";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useSearchParams } from "react-router-dom";

interface CompanyApplicationProps {
  applications: ApplicationOutput[];
  onSendMessage: (id: string) => void;
  showPublishedOnes: (published: boolean) => void;
  onUpdateApplication: (
    args: UpdateApplicationInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  onDelete: (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  sendReminderEmail: (
    applicationId: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
}
interface ModalInfoProps {
  selectedApplicationInfo: ApplicationOutput | undefined;
  modalVisible: boolean;
}

export interface onUpdateButtonsProps {
  buttonText: string;
  type: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
  onclick: (id: string) => void;
}

type ApplicantTabsModel = {
  tabName: string;
  key: ApplicationStatus;
  permissions: ApplicationStatus;
  tabAction: (application: ApplicationOutput) => ItemType[];
  profileReviewActionButtons?: {
    buttonText: string;
    type: string;
    onclick: Function;
  }[];
};

const CompanyApplications: FC<CompanyApplicationProps> = ({
  applications,
  showPublishedOnes,
  onSendMessage,
  onDelete,
  onUpdateApplication,
  sendReminderEmail,
}) => {
  const [searchParams] = useSearchParams();

  const { Alert, sendRating, Confirm } = useContext(Context);
  const [profileViewModalInfo, setProfileViewModalInfo] =
    useState<ModalInfoProps>({
      selectedApplicationInfo: undefined,
      modalVisible: false,
    });

  const [activeTab, setActiveTab] = useState(searchParams?.get("activeTab") as ApplicationStatus || ApplicationStatus.Pending);

  const showMessagePopup = () => {
    const applicantIdForMessage = searchParams?.get("applicantIdForMessage");

    if (applicantIdForMessage) {
      onSendMessage(applicantIdForMessage);
    }
  };

  const showProfilePopup = () => {
    const showProfile = searchParams?.get("showProfile");
    const applicationId = searchParams?.get("applicationId");
    if (showProfile && applicationId) {
      const application = applications?.find(
        (child) => child.id === applicationId
      );

      if (application) {
        setProfileViewModalInfo({
          selectedApplicationInfo: application,
          modalVisible: true,
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      showProfilePopup();
      showMessagePopup();
    }, 300);
  }, [applications]);

  const setTabActions = (
    application: ApplicationOutput,
    status: ApplicationStatus
  ): ItemType[] => {
    let output: ItemType[] = [
      {
        key: "1",
        label: "Preview profile",
        onClick: () =>
          setProfileViewModalInfo({
            selectedApplicationInfo: application,
            modalVisible: true,
          }),
      },
      {
        key: "2",
        label: "Send message",
        onClick: () => onSendMessage(application.applicant.id),
      },
    ];

    if (status === ApplicationStatus.Active) {
      output = [
        ...output,
        {
          key: "3",
          label: "Complete",
          onClick: () =>
            handleUpdateApplication(
              application.id,
              ApplicationStatus.Completed
            ),
        },
      ];
    }

    if (status === ApplicationStatus.Completed) {
      output = [
        ...output,
        {
          key: "4",
          label: "Review Applicant",
          onClick: () =>
            sendRating(application.internship.id, application.applicant.id),
        },
      ];
    }

    if (status === ApplicationStatus.Rejected) {
      output = [
        ...output,
        {
          key: "5",
          label: "Change as Pending",
          onClick: () =>
            handleUpdateApplication(application.id, ApplicationStatus.Pending),
        },
        {
          key: "6",
          label: "Delete",
          onClick: () => handleDeleteApplication(application.id),
        },
      ];
    }

    if (status === ApplicationStatus.Approved) {
      const differenceOfPaymentDateInMiliseconds =
        new Date(application.approvalDate).getTime() - new Date().getTime();
      const differenceOfPaymentDateInDay =
        differenceOfPaymentDateInMiliseconds / (24 * 60 * 60 * 1000);

      const isOverPaymentDue = differenceOfPaymentDateInMiliseconds <= 0;
      const isLastDayForPayment =
        differenceOfPaymentDateInDay <= 1 && differenceOfPaymentDateInDay >= 0;

      output = [
        ...output,
        {
          key: "7",
          label: "Activate",
          disabled: application.isFreeOfCharge,
          onClick: () =>
            handleUpdateApplication(application.id, ApplicationStatus.Active),
          title: application.isFreeOfCharge
            ? "You can't activate free internships without payment."
            : "",
        },
        {
          key: "8",
          label: "Decline",
          onClick: () =>
            handleUpdateApplication(application.id, ApplicationStatus.Rejected),
        },
        {
          key: "9",
          label: "Change payment date",
          onClick: () => handleExtendTime(application.id),
          disabled: !isOverPaymentDue,
          title: !isOverPaymentDue
            ? "You can change when payment date is over"
            : "",
        },
        {
          key: "10",
          label: "Send reminder email",
          onClick: () => onRemind(application.id),
          disabled: !isLastDayForPayment,
          title: !isLastDayForPayment
            ? "You can send reminder email at last day for payment"
            : "",
        },
      ];
    }

    return output;
  };

  const appLicantTabs: ApplicantTabsModel[] = [
    {
      tabName: "Request",
      key: ApplicationStatus.Pending,
      permissions: ApplicationStatus.Pending,
      tabAction: (application) =>
        setTabActions(application, ApplicationStatus.Pending),
      profileReviewActionButtons: [
        {
          buttonText: "Approve",
          type: "primary",
          onclick: (id: string) =>
            handleUpdateApplication(id, ApplicationStatus.Approved),
        },
      ],
    },
    {
      tabName: "Approved",
      key: ApplicationStatus.Approved,
      permissions: ApplicationStatus.Approved,
      tabAction: (application) =>
        setTabActions(application, ApplicationStatus.Approved),
    },
    {
      tabName: "Active",
      key: ApplicationStatus.Active,
      permissions: ApplicationStatus.Active,
      tabAction: (application) =>
        setTabActions(application, ApplicationStatus.Active),
    },
    {
      tabName: "Completed",
      key: ApplicationStatus.Completed,
      permissions: ApplicationStatus.Completed,
      tabAction: (application) =>
        setTabActions(application, ApplicationStatus.Completed),
    },
    {
      tabName: "Declined",
      key: ApplicationStatus.Rejected,
      permissions: ApplicationStatus.Rejected,
      tabAction: (application) =>
        setTabActions(application, ApplicationStatus.Rejected),
    },
  ];

  const onRemind = (id: string) => {
    Confirm("Are you sure?", () => {
      sendReminderEmail(
        id,
        () => {
          Alert("Reminder email sent successfully.", AlertType.Success);
        },
        (err: Error) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const showReviewModal = (applicationId: string) => {
    const application = applications.find(
      (child) => child.id === applicationId
    );
    if (application) {
      sendRating(application.internship.id, application.applicant.id);
    }
  };

  const handleUpdateApplication = (id: string, status: ApplicationStatus) => {
    const message =
      status === ApplicationStatus.Approved
        ? "Are you sure you want to approve this applicant to start an internship with your company?"
        : status === ApplicationStatus.Rejected
        ? "Are you sure you want to decline this applicant?"
        : status === ApplicationStatus.Completed
        ? "Are you sure you want to mark this internship as Completed?"
        : `Are you sure you want to change the application status to ${status}?`;
    Confirm(message, () => {
      let args: UpdateApplicationInput = { id: id, status: status };
      if (status === ApplicationStatus.Approved) {
        args = {
          ...args,
          approvalDate: new Date(new Date().getTime() + 72 * 60 * 60 * 1000),
        };
      }
      if (status === ApplicationStatus.Completed) {
        args = {
          ...args,
          reviewExpiredDate: new Date(
            new Date().getTime() + 10 * 24 * 60 * 60 * 1000
          ),
        };
      }
      onUpdateApplication(
        args,
        () => {
          let alertMessage = "Application has been updated successfully.";

          if (status === ApplicationStatus.Completed) {
            alertMessage =
              "Congratulations! You Internship was marked as complete.";
          }

          Alert(alertMessage, AlertType.Success);
          setProfileViewModalInfo({
            modalVisible: false,
            selectedApplicationInfo: undefined,
          });

          if (status === ApplicationStatus.Completed) {
            showReviewModal(id);
          }
        },
        (err) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const handleExtendTime = (id: string) => {
    const newDate = new Date(new Date().getTime() + 72 * 60 * 60 * 1000);
    Confirm(
      `Are you sure want to update payment due date to ${newDate.toLocaleString()}`,
      () => {
        onUpdateApplication(
          { id: id, approvalDate: newDate },
          () => {
            Alert("Payment due date updated successfully", AlertType.Success);
          },
          (err) => {
            Alert(err.message, AlertType.Error);
          }
        );
      }
    );
  };

  const handleDeleteApplication = (id: string) => {
    Confirm("Are you sure?", () => {
      onDelete(
        id,
        () => {
          Alert("The application deleted successfully.", AlertType.Success);
        },
        (err: any) => {
          Alert(err.message, AlertType.Error);
        }
      );
    });
  };

  const onReject = (
    id: string,
    rejectReason: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    Confirm("Are you sure want to decline this applicant?", () => {
      onUpdateApplication(
        { id, rejectReason, status: ApplicationStatus.Rejected },
        () => {
          successCallback();
          setProfileViewModalInfo({
            modalVisible: false,
            selectedApplicationInfo: undefined,
          });
        },
        (err) => {
          errCallback(err);
        }
      );
    });
  };

  return (
    <>
      <div className="applicationsContainer">
        <div className="listContainer boxContainer">
          <Tabs
            activeKey={activeTab}
            onChange={(activeKey) => {
              showPublishedOnes(activeKey === ApplicationStatus.Pending);
              setActiveTab(activeKey as ApplicationStatus);
            }}
          >
            {appLicantTabs.map((item) => {
              return (
                <Tabs.TabPane tab={item.tabName} key={item.key}>
                  <div className="tabContainer">
                    <ApplicationTable
                      sendRating={sendRating}
                      showRequestItems={item.tabName === "Request"}
                      showApprovalItems={item.tabName === "Approved"}
                      data={applications
                        .filter(
                          (application) => application.status === item.permissions
                        )
                        .map((application) => {
                          return {
                            approvalDate: application.approvalDate,
                            profileImg: application.applicant?.profilePhotoURL,
                            name:
                              application.applicant?.firstName +
                              " " +
                              application.applicant?.lastName,
                            appliedFor: application.internship?.title,
                            actionButtons: (
                              <Dropdown
                                overlay={
                                  <Menu items={item.tabAction(application)} />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                              >
                                <Button
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faEllipsisVertical}
                                    />
                                  }
                                  size="small"
                                />
                              </Dropdown>
                            ),
                            date: application.createdAt.toString(),
                            key: application.id,
                            isPaid: application.isPaymentComplete
                              ? "Paid"
                              : "Un-paid",
                            grade: application.grade,
                          };
                        })}
                    />
                  </div>
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
      {profileViewModalInfo.selectedApplicationInfo && (
        <ApplicantProfileView
          onReject={onReject}
          onUpdateApplication={onUpdateApplication}
          onUpdateButtons={
            appLicantTabs.find(
              (aplication) =>
                aplication.permissions ===
                profileViewModalInfo.selectedApplicationInfo?.status
            )?.profileReviewActionButtons as onUpdateButtonsProps[] | undefined
          }
          applications={profileViewModalInfo.selectedApplicationInfo}
          modalVisible={profileViewModalInfo.modalVisible}
          onClickCancel={() =>
            setProfileViewModalInfo({
              selectedApplicationInfo: undefined,
              modalVisible: false,
            })
          }
        />
      )}
    </>
  );
};

export default CompanyApplications;
