import { Formik } from 'formik';
import { FC, useState, useContext } from 'react';
import './login.scss';
import * as yup from 'yup';
import { Form, Button, Input, Checkbox } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LoginInput } from '../../models/account.models';
import { Context } from '../../context/provider';
import { AlertType } from '../../context/models';

interface ILoginProps {
  onLogin: (
    values: LoginInput,
    rememberMe: boolean,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => void;
  formValues: LoginInput;
}

const Login: FC<ILoginProps> = ({ onLogin, formValues }) => {
  const { setCurrentUserInfoUpdated, Alert } = useContext(Context);
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().email("Invalid email format.").required("Required"),
      password: yup
        .string()
        .min(8, "Minimum 8 characters!")
        .max(32, "Maximum 32 characters!")
        .matches(
          /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must include uppercase and lowercase letters and at least one number or punctuation mark."
        )
        .required("Required"),
    });

  const onSubmit = (values: LoginInput) => {
    setLoading(true);
    onLogin(
      values,
      rememberMe,
      () => {
        const redirection_path = searchParams?.get("redirection_path");
        if (redirection_path) {
          navigate(redirection_path);
        } else {
          navigate("/");
        }
        setCurrentUserInfoUpdated((prevState) => !prevState);
        Alert('Logged in successfully.', AlertType.Success, 2000);
      },
      (error) => {
        Alert(error.message, AlertType.Error);
        setLoading(false);
      }
    );
  };

  return (
    <div className="loginPageContainer">
      <Formik
        initialValues={formValues}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid
        }) => {
          return (
            <div className="loginForm">
              <Form
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 20, offset: 2 }}
                initialValues={{ remember: true }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSubmit();
                }}
              >
                <Form.Item
                  validateStatus={
                    errors.email && touched.email ? "error" : "success"
                  }
                >
                  <Input
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  ></Input>
                  {touched.email && errors?.email && (
                    <div className="errorMessage">{errors?.email}</div>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={errors.password && touched.password ? 'error' : 'success'}>
                  <Input.Password
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    placeholder="Password"></Input.Password>
                  {touched.password && errors?.password && (
                    <div className="errorMessage">{errors?.password}</div>
                  )}
                </Form.Item>
                <Form.Item className="formBottom">
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  >
                    Remember Me
                  </Checkbox>
                  <Link className="forgotPassword" to="/forget_password">
                    Forgot password?
                  </Link>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={loading}
                    disabled={loading || !isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
