import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Pagination,
  Row,
  Select,
} from "antd";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/provider";
import {
  InternshipOutput,
  SearchInternshipParamsModel,
} from "../../models/company.models";
import InternshipListItem from "../internshipListItem";
import PageLoader from "../pageLoader";
import FilterSection from "./filterSection";
import "./searchInternship.scss";

interface SearchInternshipProps {
  params: SearchInternshipParamsModel;
  onApply: (params: SearchInternshipParamsModel) => void;
  onSkip: (pageIndex: number) => void;
  internships: InternshipOutput[];
  locationOptions: string[];
  total: number;
  loading: boolean;
  pageSize: number;
  favoriteInternshipIDs: string[];
  markUnmarkInternship: (id: string) => void;
  onReset: () => void;
}

const SearchInternship: FC<SearchInternshipProps> = ({
  params,
  onApply,
  onSkip,
  internships,
  locationOptions,
  total,
  loading,
  pageSize,
  favoriteInternshipIDs,
  markUnmarkInternship,
  onReset,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(Context);

  const [searchParams, setSearchParams] = useState<SearchInternshipParamsModel>(
    {} as SearchInternshipParamsModel
  );
  const [sortByFavorite, setSortByFavorite] = useState(false);
  const [sortedInternships, setSortedInternships] = useState<
    InternshipOutput[]
  >([]);
  const [showMobileFilterSection, setShowMobileFilterSection] = useState(false);

  useEffect(() => {
    if (sortByFavorite && currentUser.isLoggedIn && !currentUser.isCompany) {
      const favoriteOnes = internships.filter((child) =>
        favoriteInternshipIDs.some((id) => id === child.id)
      );
      const unFavoriteOnes = internships.filter(
        (child) => !favoriteInternshipIDs.some((id) => id === child.id)
      );
      setSortedInternships([...favoriteOnes, ...unFavoriteOnes]);
    } else {
      setSortedInternships([...internships]);
    }
  }, [internships, sortByFavorite, favoriteInternshipIDs, currentUser]);

  useEffect(() => {
    setSearchParams((prevState) => ({ ...prevState, ...params }));
  }, [params]);

  const onChangeParams = (key: string, value: string | number) => {
    setSearchParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const onClickInternship = (id: string) => {
    navigate(`/internships/${id}`);
  };

  return (
    <div className="searchInternshipContainer">
      <div className="filterSection">
        <FilterSection
          onChangeParams={onChangeParams}
          onApply={onApply}
          onReset={onReset}
          searchParams={searchParams}
          locationOptions={locationOptions}
        />
      </div>
      <div className="mobileFilterSection">
        <Button
          icon={<FontAwesomeIcon icon={faFilter} />}
          type="primary"
          onClick={() => setShowMobileFilterSection((prevState) => !prevState)}
        />
        {showMobileFilterSection && (
          <FilterSection
            onChangeParams={onChangeParams}
            onApply={onApply}
            onReset={onReset}
            searchParams={searchParams}
            locationOptions={locationOptions}
          />
        )}
      </div>
      <div className="resultSection">
        <Row
          className={`resultTopSection ${
            currentUser.isLoggedIn && !currentUser.isCompany && "spaceBetween"
          }`}
        >
          {currentUser.isLoggedIn && !currentUser.isCompany && (
            <Checkbox
              checked={sortByFavorite}
              onChange={() => setSortByFavorite((prevState) => !prevState)}
            >
              Sort by Favorite Ones
            </Checkbox>
          )}
          <Pagination
            total={total}
            defaultPageSize={pageSize}
            showSizeChanger={false}
            defaultCurrent={1}
            onChange={(page) => onSkip(page - 1)}
          />
        </Row>
        <Divider />
        {loading ? (
          <PageLoader active avatar numOfSkeleton={2} />
        ) : sortedInternships.length === 0 ? (
          <Empty description="Not found any result." />
        ) : (
          sortedInternships?.map((child) => (
            <InternshipListItem
              showMark={!(currentUser.isLoggedIn && currentUser.isCompany)}
              marked={favoriteInternshipIDs.some((id) => id === child.id)}
              onMark={() => markUnmarkInternship(child.id)}
              body={child}
              onClick={() => onClickInternship(child.id)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SearchInternship;
