import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Divider, Empty, Row, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { InternshipOutput } from "../../../models/company.models";
import InternshipListItem from "../../internshipListItem";
import "./favoriteInternships.scss";

interface FavoriteInternshipsProps {
  internships: InternshipOutput[];
  unmarkInternship: (id: string) => void;
}

const { Title } = Typography;

const FavoriteInternships: FC<FavoriteInternshipsProps> = ({
  internships,
  unmarkInternship,
}) => {
  const navigate = useNavigate();

  const onClickListItem = (id: string) => {
    navigate(`/internships/${id}`);
  };

  const onClickSearchInternship = () => {
    navigate(`/searchInternship`);
  };

  return (
    <div className="internshipsContainer">
      <div className="listContainer boxContainer">
        <Row>
          <Col span={24}>
            <div className="favoriteHeader">
              <Title level={2} className="favoriteInternshipTitle">
                Favorite Internships
              </Title>
              <Button
                onClick={onClickSearchInternship}
                type="primary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            {internships?.length === 0 && <Empty description='Not found any favorite internship.' />}
            {internships?.map((child) => (
              <InternshipListItem
                body={child}
                marked
                showMark
                onMark={() => unmarkInternship(child.id)}
                onClick={() => onClickListItem(child.id)}
                cutLongTexts
              />
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FavoriteInternships;
