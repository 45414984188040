import { ReactNode, FC, createContext, useState } from "react";
import AlertConfirm from "../../components/alertConfirm";
import MessageBox from "../../modals/messageBox";
import RatingBox from "../../modals/ratingBox";
import { NotificationOutput } from "../../models/notification.models";
import {
  BreadCrumbPageModel,
  CurrentUserModel,
  AlertConfirmModel,
  AlertConfirmType,
  AlertType,
  MessageModel,
  MessageType,
  RatingModel,
} from "../models";

interface ProviderProps {
  children: ReactNode;
}

export const currentUserDefaultValue: CurrentUserModel = {
  isLoggedIn: false,
  isCompany: false,
  displayName: '',
  photoUrl: '',
  accountId: '',
  applicantId: '',
  companyId: '',
  isProfileCompleted: false,
};

const useContext = () => {
  const [alertConfirm, setAlertConfirm] = useState<AlertConfirmModel>({
    type: null,
    alertType: null,
    show: false,
    text: "",
    okClickFunc: () => null,
    timeout: 5000,
  });
  const [currentUser, setCurrentUser] = useState<CurrentUserModel>(
    currentUserDefaultValue
  );
  const [currentUserInfoUpdated, setCurrentUserInfoUpdated] = useState(false);
  const [breadCrumbPageList, setBreadCrumbPageList] = useState<
    BreadCrumbPageModel[]
  >([]);
  const [notifications, setNotifications] = useState<NotificationOutput[]>([]);
  const [message, setMessage] = useState<MessageModel>({
    show: false,
    type: MessageType.Create,
    toId: null,
    subject: '',
    onClose: () =>
      setMessage((prevState) => ({
        ...prevState,
        show: false,
        type: MessageType.Create,
        toId: null,
        subject: '',
      })),
  });
  const [rating, setRating] = useState<RatingModel>({
    show: false,
    internshipId: null,
    applicantOrCompanyId: null,
    onClose: () =>
      setRating((prevState) => ({
        ...prevState,
        show: false,
        internshipId: null,
        applicantOrCompanyId: null,
      })),
  });
  const [updatedMessages, setUpdatedMessages] = useState(0);
  const [updatedApplications, setUpdatedApplications] = useState(0);
  const [updatedRates, setUpdatedRates] = useState(0);
  const [updatedEarnings, setUpdatedEarnings] = useState(0);
  const [notificationReceivedTitleText, setNotificationReceivedTitleText] = useState('');

  const sendRating = (internshipId: string, applicantOrCompanyId: string) => {
    setRating((prevState) => ({
      ...prevState,
      internshipId,
      applicantOrCompanyId,
      show: true,
    }));
  };

  const sendMessage = (toId: string, type: MessageType, subject = '') => {
    setMessage((prevState) => ({
      ...prevState,
      type,
      toId,
      show: true,
      subject,
    }));
  };

  const Alert = (text: string, alertType: AlertType, timeout = 5000): void => {
    setAlertConfirm((prevState) => ({
      ...prevState,
      type: AlertConfirmType.Alert,
      alertType,
      text: text,
      show: true,
      timeout,
    }));
  };

  const Confirm = (text: string, func: Function): void => {
    setAlertConfirm((prevState) => ({
      ...prevState,
      type: AlertConfirmType.Confirm,
      text: text,
      show: true,
      okClickFunc: func,
    }));
  };

  const notificationReceived = () => {
    const text = 'New notification received...';
    setNotificationReceivedTitleText(text);
    setTimeout(() => {
      setNotificationReceivedTitleText('');
    }, 3000);
    Alert(text, AlertType.Warning, 2000);
  }

  return {
    notificationReceived,
    notificationReceivedTitleText,
    updatedEarnings, 
    setUpdatedEarnings,
    updatedRates, 
    setUpdatedRates,
    updatedApplications, 
    setUpdatedApplications,
    rating,
    sendRating,
    updatedMessages, 
    setUpdatedMessages,
    sendMessage,
    message,
    breadCrumbPageList,
    setBreadCrumbPageList,
    alertConfirm,
    setAlertConfirm,
    Alert,
    Confirm,
    currentUser,
    setCurrentUser,
    currentUserInfoUpdated,
    setCurrentUserInfoUpdated,
    notifications,
    setNotifications,
  };
};

export const Context = createContext({} as ReturnType<typeof useContext>);

const Provider: FC<ProviderProps> = ({ children }) => {
  return (
    <Context.Provider value={useContext()}>
      <AlertConfirm />
      <MessageBox />
      <RatingBox />
      {children}
    </Context.Provider>
  );
};

export default Provider;
