import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import "./messages.scss";
import { Button, Col, Divider, List, Row, Tooltip, Badge, Select } from "antd";
import { MessageDto } from "../../models/message.models";
import Message from "./message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckDouble,
  faReply,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../actionButton";
import {dateFormat} from "../../utils/date";

interface MessagesProps {
  inboxMessages: MessageDto[];
  outboxMessages: MessageDto[];
  markAsRead: (id: string, showAlert?: boolean) => void;
  deleteMessage: (id: string) => void;
  replyMessage: (messageId: string) => void;
  users: MessageUserModel[];
}

export type MessageDataModel = {
  actions: ReactNode[];
  author: string;
  avatar: string;
  content: ReactNode;
  datetime: ReactNode;
};

export type MessageUserModel = { name: string; id: string };

const { Option } = Select;

const Messages: FC<MessagesProps> = ({
  inboxMessages,
  outboxMessages,
  markAsRead,
  deleteMessage,
  replyMessage,
  users,
}) => {
  const [showInbox, setShowInbox] = useState(true);
  const [data, setData] = useState<MessageDataModel[]>([]);
  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {
    if (showInbox) {
      setData(
        inboxMessages
          .filter(
            (child) =>
              child.fromAccount.id === selectedUserId || selectedUserId === ""
          )
          .map((child) => ({
            actions: [
              <span key="comment-list-reply-to-0">
                <ActionButton
                  title="Reply"
                  hoverColor="var(--blue-5)"
                  icon={faReply}
                  onClick={() => replyMessage(child.id)}
                />
              </span>,
              <span key="comment-list-reply-to-0">
                <ActionButton
                  title="Delete"
                  hoverColor="var(--red-5)"
                  icon={faTrash}
                  onClick={() => deleteMessage(child.id)}
                />
              </span>,
              <span key={`comment-list-reply-to-0`}>
                <ActionButton
                  title={!child.isRead ? "Mark as read" : ""}
                  className={`${child.isRead && "disableAction"}`}
                  hoverColor="var(--green-5)"
                  icon={!child.isRead ? faCheck : faCheckDouble}
                  onClick={() => {
                    if (!child.isRead) {
                      markAsRead(child.id);
                    }
                  }}
                />
              </span>,
            ],
            author: child?.fromAccount?.name ?? "",
            avatar: child?.fromAccount?.photo ?? "/avatar.webp",
            content: (
              <div id={child.id}>
                <div>
                  <b>{child?.subject ?? ""}</b>
                </div>
                <div>{child?.body ?? ""}</div>
              </div>
            ),
            datetime: (
              <Tooltip
                title={`${dateFormat(child.createdAt)}`}
              >
                <span>{dateFormat(child.createdAt)}</span>
              </Tooltip>
            ),
          }))
      );
    } else {
      setData(
        outboxMessages
          .filter(
            (child) =>
              child.toAccount.id === selectedUserId || selectedUserId === ""
          )
          .map((child) => ({
            actions: [
              <span key="comment-list-reply-to-0">
                <ActionButton
                  title="Delete"
                  hoverColor="var(--red-5)"
                  icon={faTrash}
                  onClick={() => deleteMessage(child.id)}
                />
              </span>,
              <span key={`comment-list-reply-to-0`}>
                <ActionButton
                  className="disableAction"
                  icon={!child.isRead ? faCheck : faCheckDouble}
                  onClick={() => null}
                />
              </span>,
            ],
            author: child?.toAccount?.name ?? "",
            avatar: child?.toAccount?.photo ?? "/avatar.webp",
            content: (
              <div>
                <div>
                  <b>{child?.subject ?? ""}</b>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: child?.body ?? "" }}
                ></div>
              </div>
            ),
            datetime: (
              <Tooltip
                title={dateFormat(child.createdAt)}
              >
                <span>{dateFormat(child.createdAt)}</span>
              </Tooltip>
            ),
          }))
      );
    }
  }, [showInbox, inboxMessages, outboxMessages, selectedUserId]);

  const changeBox = () => {
    setShowInbox((prevState) => !prevState);
  };

  return (
    <div className="messagesContainer">
      <List
        className="comment-list"
        header={
          <Row className="buttonContainer">
            <Col span={24}>
              <Select
                value={selectedUserId}
                onChange={(value) => setSelectedUserId(value)}
              >
                {users.map((child) => (
                  <Option value={child.id}>{child.name}</Option>
                ))}
              </Select>
              <span className="messageCount">{`${data.length} message(s)`}</span>
              <Button
                type={!showInbox ? "primary" : "default"}
                onClick={changeBox}
              >
                Sent Messages
              </Button>
              <Button
                type={showInbox ? "primary" : "default"}
                onClick={changeBox}
              >
                Inbox
              </Button>
            </Col>
          </Row>
        }
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <Fragment>
            <Message
              item={item}
              id={showInbox ? inboxMessages[index]?.id : ""}
              markAsRead={markAsRead}
              replyMessage={replyMessage}
            />
            <Divider />
          </Fragment>
        )}
      />
    </div>
  );
};

export default Messages;
