import { Avatar, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  createRef,
  FC,
  ReactNode,
  RefObject,
  useEffect,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { helpers } from "../../../utils/helpers";
import {dateFormat} from "../../../utils/date";

interface TableProps {
  data: DataType[];
  showApprovalItems: boolean;
  showRequestItems: boolean;
  sendRating: (internshipId: string, applicantOrCompanyId: string) => void;
}

interface DataType {
  key: string;
  profileImg: string;
  name: string;
  appliedFor: string;
  date: string;
  isPaid?: string;
  actionButtons: ReactNode;
  approvalDate: Date | null;
  grade: string | null;
}
const ApplicationTable: FC<TableProps> = ({
  data,
  showApprovalItems,
  showRequestItems,
  sendRating,
}) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getFirstLetterOfDisplayname, uniqueObjectArray } = helpers;
  const refs: RefObject<HTMLDivElement>[] = data.map((child) =>
    createRef<HTMLDivElement>()
  );

  const showRatingPopup = () => {
    const ratingInternshipId = searchParams?.get("ratingInternshipId");
    const ratingApplicantId = searchParams?.get("ratingApplicantId");
    if (ratingInternshipId && ratingApplicantId) {
      sendRating(ratingInternshipId, ratingApplicantId);
    }
  }

  const scrollToApplication = () => {
    const applicationId = searchParams?.get("applicationId");
    let matched = data.findIndex((child) => child.key === applicationId);
    const ref = refs[matched];
    if (matched !== -1 && ref.current && applicationId) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
      ref.current.parentElement?.parentElement?.classList.add(
        "matchedApplication"
      );
      setTimeout(() => {
        ref.current?.parentElement?.parentElement?.classList.remove(
          "matchedApplication"
        );
        navigate("/bookings");
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToApplication();
      showRatingPopup();
    }, 300);
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "profileImg",
      key: "profileImg",
      render: (imageUrl, record) => (
        <Avatar
          src={imageUrl}
          shape="circle"
          size="small"
          ref={refs[data.findIndex((child) => child.key === record.key)]}
        >
          {getFirstLetterOfDisplayname(record.name ?? "A")}
        </Avatar>
      ),
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      filterMode: "tree",
      filters: uniqueObjectArray(
        data.map((child) => ({ text: child.name, value: child.name }))
      ),
      onFilter: (value, record) => record.name === value,
    },
    {
      title: "Applied for",
      dataIndex: "appliedFor",
      key: "appliedFor",
      sorter: (a, b) => a.appliedFor.localeCompare(b.appliedFor),
      sortDirections: ["descend", "ascend"],
      filterMode: "tree",
      filters: uniqueObjectArray(
        data.map((child) => ({
          text: child.appliedFor,
          value: child.appliedFor,
        }))
      ),
      onFilter: (value, record) => record.appliedFor === value,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => new Date(value).toLocaleDateString("en-US", {year: "numeric", month: "long", day: "numeric"}),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "",
      dataIndex: "actionButtons",
      key: "actionButtons",
      fixed: "right",
      width: "50px",
    },
  ];

  const setPaymentDueTooltipTitle = (value: Date): string => {
    const currentDate = new Date();
    let output = "";
    if (value) {
      if (currentDate.getTime() > new Date(value).getTime()) {
        output = "Time is over!";
      } else {
        const difference = new Date(value).getTime() - currentDate.getTime();
        const hours = Math.floor(difference / (60 * 60 * 1000));
        const minutes = Math.floor(
          (difference % (60 * 60 * 1000)) / (60 * 1000)
        );
        output = `${hours} hours ${minutes} minutes`;
      }
    }
    return output;
  };

  if (showRequestItems) {
    columns.splice(4, 0, {
      title: "Evaluation",
      dataIndex: "grade",
      key: "grade",
      render: (value) => <span>{value}</span>,
      filterMode: "tree",
      filters: [
        {
          text: "Like",
          value: "like",
        },
        {
          text: "Maybe",
          value: "maybe",
        },
        {
          text: "Dislike",
          value: "dislike",
        },
      ],
      onFilter: (value: any, record) => record.grade?.indexOf(value) === 0,
    });
  }

  if (showApprovalItems) {
    columns.splice(4, 0, {
      title: "Payment",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (value) => (
        <span
          style={{
            display: value ? "inline-flex" : "none",
            color: value === "paid" ? "green" : "red",
          }}
        >
          {value}
        </span>
      ),
    });

    columns.splice(5, 0, {
      title: "Payment Due",
      dataIndex: "approvalDate",
      key: "approvalDate",
      render: (value) => (
        <Tooltip title={setPaymentDueTooltipTitle(value)}>
          <span>
            {value ? dateFormat(value.createdAt) : "Invalid Date"}
          </span>
        </Tooltip>
      ),
    });
  }

  return (
    <div className="tableContainer">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default ApplicationTable;
