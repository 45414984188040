import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CreateUpdateInternship from "../../components/companyComponents/createUpdateInternship";
import PageLoader from "../../components/pageLoader";
import { Context } from "../../context/provider";
import { CreateInternshipInput } from "../../models/company.models";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";
import NotFoundPage from "../notFoundPage";

const UpdateInternshipPage: FC = () => {
    const { currentUser } = useContext(Context);
  const { id } = useParams();
  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({} as CreateInternshipInput);

  const getInternship = async () => {
    try {
      const res = await companyService.getInternship({ id: id! });
      setFormValues(res as CreateInternshipInput);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const updateInternship = async (
    inputs: CreateInternshipInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      const args = { ...inputs, id: id! };
      await companyService.updateInternship(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  useEffect(() => {
    getInternship();
  }, [id]);

  if (loading) {
    return <PageLoader avatar active />;
  }

  if (!currentUser.isCompany) {
    return <NotFoundPage />;
  }

  return (
    <CreateUpdateInternship
      inputs={formValues}
      isEditMode
      onSubmit={updateInternship}
    />
  );
};

export default UpdateInternshipPage;
