import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Popover } from "antd";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../../context/provider";
import NotificationService from "../../services/notification";
import { printError } from "../../utils/logger";
import "./notification.scss";
import NotificationContent from "./notificationContent";

interface NotificationProps {
  showLightLogo: () => boolean;
  isDeviceDesktop: boolean;
}

const Notification: FC<NotificationProps> = ({
  showLightLogo,
  isDeviceDesktop,
}) => {
  const { notifications, setNotifications } = useContext(Context);
  const notificationService = useMemo(() => {
    return new NotificationService();
  }, []);
  const [unreadNotificationLength, setUnreadNotificationLength] = useState(0);

  const getNotifications = async () => {
    try {
      const res = await notificationService.getNotifications();
      setNotifications(res);
    } catch (error) {
      printError([error]);
    }
  };

  const markAsRead = (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      notificationService.markAsReadNotification({ id }).then((value) => {
        let existNotifications = notifications;
        const notificationIndex = notifications.findIndex(
          (child) => child.id === id
        );
        if (notificationIndex !== -1) {
          existNotifications[notificationIndex].isRead = true;
          setNotifications([...existNotifications]);
          successCallback();
        }
      });
    } catch (error) {
      errCallback(error as Error);
      printError([error]);
    }
  };

  const deleteNotification = (
    id: string,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      notificationService.deleteNotification({ id });
      let existNotifications = notifications;
      existNotifications = existNotifications.filter(
        (child) => child.id !== id
      );
      setNotifications([...existNotifications]);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
      printError([error]);
    }
  };

  const onClick = (id: string) => {
    markAsRead(
      id,
      () => {},
      (err) => printError([err])
    );
  };

  useEffect(() => {
    const unReadOnes = notifications.filter((child) => !child.isRead);
    setUnreadNotificationLength(unReadOnes.length);
  }, [notifications]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="notificationBellContainer">
      <Popover
        placement={isDeviceDesktop ? "leftTop" : "top"}
        content={
          <NotificationContent
            onClick={onClick}
            notifications={notifications}
            markAsRead={markAsRead}
            deleteNotification={deleteNotification}
          />
        }
        trigger="click"
      >
        <Badge count={unreadNotificationLength}>
          <FontAwesomeIcon
            icon={faBell}
            size="2x"
            color={showLightLogo() ? "var(--white)" : "var(--primary-color)"}
            className="bell"
          />
        </Badge>
      </Popover>
    </div>
  );
};

export default Notification;
