import { FC } from 'react';
import { Typography } from 'antd';
import './howInternseaWorks.scss';

const { Title, Text } = Typography;

const HowInternseaWorks: FC = () => {
  return (
    <div className='howInternseaWorksContainer'>
      <div className="howInternseaWorksContent boxContainer">
        <Title>
          You’re just 3 steps away from your ideal internship
        </Title>
        <Title level={2}>
          1. Browse
        </Title>
        <Text>
          Start by exploring Internships. Apply filters like internship duration, industry, or remote allowed to narrow your options.
        </Text>
        <Title level={2}>
          2. Book
        </Title>
        <Text>
          Once you’ve found what you’re looking for, learn about your company host, read through the internship details—then book in just a few clicks.
        </Text>
        <Title level={2}>
          3. Go
        </Title>
        <Text>
          You’re all set! Connect with your company for questions or advice before the internship date. You can also contact Internsea anytime for additional support. 
        </Text>
      </div>
    </div>
  )
}

export default HowInternseaWorks;