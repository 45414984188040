import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress } from "antd";
import { FC } from "react";
import './reviewStarCountRow.scss';

interface ReviewStarCountRowProps {
  star: 1 | 2 | 3 | 4 | 5;
  starCount: number;
  percentage: number;
}

const ReviewStarCountRow: FC<ReviewStarCountRowProps> = ({
  star,
  starCount,
  percentage,
}) => {
  return (
    <div className="startCountRow">
      <span>{star} {star === 1 ? 'Star' : 'Stars'}{star === 1 && <span className="sPlaceholder">s</span> }</span>
      <FontAwesomeIcon icon={faStar} />
      <Progress percent={percentage} showInfo={false} strokeColor='#FADB14' />
      <span>{`(${starCount})`}</span>
    </div>
  );
};

export default ReviewStarCountRow;
