import { FC, useContext, useEffect, useMemo, useState, Fragment } from "react";
import FavoriteInternships from "../../components/applicantComponents/favoriteInternships";
import Internships from "../../components/companyComponents/internships";
import PageLoader from "../../components/pageLoader";
import { AlertType } from "../../context/models";
import { Context } from "../../context/provider";
import {
  GetInternshipsInput,
  InternshipOutput,
} from "../../models/company.models";
import ApplicantService from "../../services/applicant";
import CompanyService from "../../services/company";
import { printError } from "../../utils/logger";

const InternshipsPage: FC = () => {
  const { currentUser, Alert, Confirm } = useContext(Context);
  const companyService = useMemo(() => {
    return new CompanyService();
  }, []);

  const applicantService = useMemo(() => {
    return new ApplicantService();
  }, []);

  const [companyInternships, setCompanyInternships] = useState<
    InternshipOutput[]
  >([]);
  const [applicantInternships, setApplicantInternships] = useState<
    InternshipOutput[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [totalCountCompanyInternships, settotalCountCompanyInternships] =
    useState(0);
  const [skip, setSkip] = useState(0);
  const [isPublished, setIsPublished] = useState(true);
  const [companyInternshipsUpdated, setCompanyInternshipsUpdated] = useState(false);
  const pageLimit = 10;

  const onSkip = (pageIndex: number) => {
    setSkip(pageIndex * pageLimit);
  };

  const showPublishedOnes = (published: boolean) => {
    setIsPublished(published);
  };

  const getCompanyInternships = async () => {
    try {
      const input: GetInternshipsInput = {
        limit: pageLimit,
        page: skip / pageLimit + 1,
        companyId: currentUser.companyId,
        isPublished,
      };
      const res = await companyService.getAllInternships(input);
      setCompanyInternships(res.items);
      settotalCountCompanyInternships(res.totalItems!);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const getApplicantInternships = async () => {
    try {
      const res = await applicantService.getFavoriteInternships();
      const puplishedOnes = res.filter((child) => child.isPublished);
      setApplicantInternships(puplishedOnes);
      setLoading(false);
    } catch (error) {
      printError([error]);
    }
  };

  const unmarkInternship = (id: string) => {
    Confirm("Are you sure?", async () => {
      try {
        await applicantService.unmarkFavoriteInternship(id);
        const filtered = applicantInternships.filter(
          (child) => child.id !== id
        );
        setApplicantInternships([...filtered]);
        Alert(
          "The internsip removed from favorite list successfully.",
          AlertType.Success
        );
      } catch (error) {
        Alert((error as Error).message, AlertType.Error);
      }
    });
  };

  const onPublish = (internshipId: string, publish: boolean) => {
    Confirm(`Are you sure you want to ${publish ? 'publish' : 'unpublish'} this internship?`, async () => {
      try {
        await companyService.publishInternship({ id: internshipId, isPublished: publish });
        const alertMessage = publish ? 'Congrats! Your internship was successfully published.' : 'Your internship is unpublished.';
        Alert(
          alertMessage,
          AlertType.Success
        );
        setCompanyInternshipsUpdated((prevState) => !prevState);
      } catch (error) {
        Alert((error as Error).message, AlertType.Error);
      }
    });
  };

  const onDeleteInternship = (internshipId: string) => {
    Confirm('Are you sure?', async () => {
      try {
        await companyService.deleteInternship({ id: internshipId });
        Alert(
          `Your internship was deleted.`,
          AlertType.Success
        );
        setCompanyInternshipsUpdated((prevState) => !prevState);
      } catch (error) {
        Alert((error as Error).message, AlertType.Error);
      }
    });
  };

  useEffect(() => {
    if (currentUser.isCompany) {
      getCompanyInternships();
    } else {
      getApplicantInternships();
    }
  }, [currentUser.isCompany, skip, isPublished, companyInternshipsUpdated]);

  if (loading) {
    return <PageLoader active avatar />;
  }

  return (
    <Fragment>
      {currentUser.isCompany ? (
        <Internships
          internships={companyInternships}
          totalCount={totalCountCompanyInternships}
          showPublishedOnes={showPublishedOnes}
          onSkip={onSkip}
          pageLimit={pageLimit}
          onPublish={onPublish}
          onDelete={onDeleteInternship}
        />
      ) : (
        <FavoriteInternships
          internships={applicantInternships}
          unmarkInternship={unmarkInternship}
        />
      )}
    </Fragment>
  );
};

export default InternshipsPage;
