import { FC, ReactNode, Fragment, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { Context } from "../../context/provider";
import {
  NotificationType,
  ReceivedSocketNotificationModel,
} from "../../models/notification.models";
import { helpers } from "../../utils/helpers";

interface SocketReceiverProps {
  children: ReactNode;
}

const baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:3030";
const socket = io(baseURL);

const SocketReceiver: FC<SocketReceiverProps> = ({ children }) => {
  const {
    currentUser,
    setNotifications,
    setUpdatedMessages,
    setUpdatedApplications,
    setUpdatedRates,
    setUpdatedEarnings,
    notificationReceived
  } = useContext(Context);
  const { uniqueObjectArray } = helpers;

  useEffect(() => {
    if (currentUser.accountId) {
      socket.connect();
      socket.emit("join", { accountId: currentUser.accountId });
    } else {
      socket.disconnect();
    }
  }, [currentUser.accountId]);

  useEffect(() => {
    socket.on("notification", (payload: ReceivedSocketNotificationModel) => {
      notificationReceived();
      if (payload.notificationType === NotificationType.Message) {
        setUpdatedMessages((prevState) => ++prevState);
      } else if (payload.notificationType === NotificationType.Application) {
        setUpdatedApplications((prevState) => ++prevState);
      } else if (payload.notificationType === NotificationType.Rating) {
        setUpdatedRates((prevState) => ++prevState);
      } else if (payload.notificationType === NotificationType.Earning) {
        setUpdatedEarnings((prevState) => ++prevState);
      }
      setNotifications((prevState) =>
        uniqueObjectArray([payload, ...prevState])
      );
    });
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default SocketReceiver;
