import { CSSProperties } from "react";
import { internshipPartTimeFullTimeOptions } from "./constants";

export const helpers = {
    errorHandler: (error: unknown): string => {
        let output = 'Somethings went wrong.';
        if ((error as Error).message === "Network Error") {
            output = 'Can not connect with the server at the moment, please try again later';
        } else if(Object.keys(error as Object).some((child) => child === 'response')) {
            if (Array.isArray((error as any).response.data.message)) {
                output = ((error as any).response.data.message as Array<string>).join(", ");
            } else {
                output = (error as any).response.data.message;
            }
        } else if (Object.keys(error as Object).some((child) => child === 'message')) {
            output = (error as Error).message;
        }
        return output;
    },

    selectOptionsWithPlaceholderValue: (
      args: string[],
      placeholder: string
    ): { value: string; label: string }[] => [
      { value: "", label: placeholder },
      ...args.map((child) => ({ value: child, label: child })),
    ],

    convertFiletoBase64: async (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }),

    getFirstLetterOfDisplayname: (name: string): string => name.substring(0, 1).toUpperCase(),

    longtextCutter: (text: string, length: number): string => {
        let output = text;

        if (text.length > length) {
            output = `${text.substring(0, length)}...`;
        }

        return output;
    },

    uniqueObjectArray: (args: any[]): any[] => {
        const stringifiedArray = args.map((child) => JSON.stringify(child));
        const uniqueOnes = stringifiedArray.filter((value, index, self) => self.indexOf(value) === index);
        const parsedArray = uniqueOnes.map((child) => JSON.parse(child));
        return parsedArray;
    },

    pluralText: (time: number, text: string, showTime = false) => {
        text = text.replace('(s)', '');
        if (time > 1) {
            return showTime ? `${time} ${text}s` : `${text}s`;
        } else {
            return showTime ? `${time} ${text}` : text;
        }
    },

    setTypeStyle: (type: string): CSSProperties => {
        const [ partTime, fullTime ] = internshipPartTimeFullTimeOptions;
        let defaultBackgroundColor = 'var(--white)';
        let defaultColor = 'var(--black)';
        if (type === partTime) {
            defaultBackgroundColor = '#1890FF';
            defaultColor = 'var(--white)';
        } else if (type === fullTime) {
            defaultBackgroundColor = '#FF5728';
            defaultColor = 'var(--white)';
        }
        return {
            backgroundColor: defaultBackgroundColor,
            color: defaultColor,
        };
    },

    priceFormatter: (price: number) => {
        return `$ ${(price || 0).toFixed(2)}`
    }
};


