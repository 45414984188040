import { FC, useContext, useState } from "react";
import "./main.scss";
import { Typography, Input, Select, Button, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { helpers } from "../../utils/helpers";
import { InternshipOutput } from "../../models/company.models";
import PageLoader from "../pageLoader";
import { useNavigate } from "react-router-dom";
import InternshipCard from "../internshipCard";
import { Context } from "../../context/provider";

interface MainProps {
  locationOptions: string[];
  onSearch: (title: string, location: string) => void;
  internships: InternshipOutput[];
  loading: boolean;
  favoriteInternshipIDs: string[];
  markUnmarkInternship: (id: string) => void;
}

const { Title } = Typography;

const { Option } = Select;

const Main: FC<MainProps> = ({
  locationOptions,
  onSearch,
  internships,
  loading,
  favoriteInternshipIDs,
  markUnmarkInternship,
}) => {
  const { currentUser } = useContext(Context);

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const { selectOptionsWithPlaceholderValue } = helpers;
  const navigate = useNavigate();

  const onSubmit = () => {
    onSearch(title, location);
  };

  const onClickInternship = (id: string) => {
    navigate(`/internships/${id}`);
  };

  const onClickBrowseAll = () => {
    navigate(`/searchInternship`);
  };

  return (
    <div className="mainPageContainer">
      <div
        className="topSection"
        style={{ backgroundImage: "url(/main.jfif)" }}
      >
        <div className="blur">
          <div className="searchSection">
            <Title>
              Book an <span>Internship</span> like you
            </Title>
            <Title>book a home on Airbnb.</Title>
            <Title level={3}>
              Stand out from millions of applicants & start your internship this
              week.
            </Title>
            <div className="searchEngine">
              <Input
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Search Job Titles, Company Names..."
              />
              <Select
                showSearch
                value={location}
                onChange={(value) => setLocation(value)}
                placeholder="Location"
              >
                {selectOptionsWithPlaceholderValue(
                  locationOptions,
                  "Search Location"
                )?.map((child, index) => (
                  <Option key={index} value={child.value}>
                    {child.label}
                  </Option>
                ))}
              </Select>
              <Button
                onClick={onSubmit}
                icon={
                  <FontAwesomeIcon icon={faSearch} color={"var(--white)"} />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="internshipSection">
        {loading ? (
          <PageLoader avatar active numOfSkeleton={6} />
        ) : internships.length === 0 ? (
          <Empty description='Not found any internship.' />
        ) : (
          internships?.map((child) => (
            <InternshipCard
              body={child}
              onClickApply={() => onClickInternship(child.id)}
              marked={favoriteInternshipIDs.some((id) => id === child.id)}
              onMark={() => markUnmarkInternship(child.id)}
              showActionButtons={!(currentUser.isLoggedIn && currentUser.isCompany)}
            />
          ))
        )}
      </div>
      <div className="bottomSection">
        <Button type="primary" size="large" onClick={onClickBrowseAll}>
          Browse All
        </Button>
      </div>
    </div>
  );
};

export default Main;
