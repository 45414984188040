import { CurrentUserModel } from '../../../context/models';
import { FC, Fragment } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AppAvatar from '../avatar';
import Notification from '../../notification';

interface IMenuContentProps {
  currentUser: CurrentUserModel;
  isHeaderVisible: () => boolean;
  isDeviceDesktop: boolean;
  showLightLogo: () => boolean;
  closeSidebar?: () => void;
}

const MenuContent: FC<IMenuContentProps> = ({ currentUser, isHeaderVisible, isDeviceDesktop, showLightLogo, closeSidebar = () => null }) => {
  const navigate = useNavigate();

  const onClickMenuItem = (path: string) => {
    navigate(path);
    closeSidebar();
  };

  return (
    <Fragment>
      {!currentUser.isLoggedIn ? (
        <Fragment>
          <Button
            onClick={() => onClickMenuItem('/login')}
            type="primary"
            icon={<FontAwesomeIcon icon={faUser} size="1x" />}>
            Log In
          </Button>
          <Button
            onClick={() => onClickMenuItem('/signup')}
            type="primary"
            icon={<FontAwesomeIcon icon={faBriefcase} size="1x" />}>
            Sign Up
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            className={`menuItem ${
              !isHeaderVisible() && isDeviceDesktop && 'menuItemWithLightColor'
            }`}
            type="text"
            onClick={() => onClickMenuItem('/bookings')}>
            Applications
          </Button>
          {currentUser.isCompany && (
            <Button
              className={`menuItem ${
                !isHeaderVisible() && isDeviceDesktop && 'menuItemWithLightColor'
              }`}
              type="text"
              onClick={() => onClickMenuItem('/earnings')}>
              Earnings
            </Button>
          )}
          <Button
            className={`menuItem ${
              !isHeaderVisible() && isDeviceDesktop && 'menuItemWithLightColor'
            }`}
            type="text"
            onClick={() => onClickMenuItem('/internships')}>
            {currentUser.isCompany ? 'Internships' : 'Wishlist'}
          </Button>
          <Button
            className={`menuItem ${
              !isHeaderVisible() && isDeviceDesktop && 'menuItemWithLightColor'
            }`}
            type="text"
            onClick={() => onClickMenuItem('/reviews')}>
            Reviews
          </Button>
          <Button
            className={`menuItem ${
              !isHeaderVisible() && isDeviceDesktop && 'menuItemWithLightColor'
            }`}
            type="text"
            onClick={() => onClickMenuItem('/messages')}>
            Messages
          </Button>
          <AppAvatar currentUser={currentUser} showLightLogo={showLightLogo} closeSidebar={closeSidebar} />
          <Notification showLightLogo={showLightLogo} isDeviceDesktop={isDeviceDesktop} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default MenuContent;
