import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Divider, List, Row, Tooltip, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyAccountOutput } from "../../../models/company.models";
import ActionBox from "../../actionBox";
import "./billingAccounts.scss";

interface BillingAccountsProps {
  accounts: CompanyAccountOutput[];
  makeDefault: (id: string) => void;
}

const { Title } = Typography;

const BillingAccounts: FC<BillingAccountsProps> = ({
  accounts,
  makeDefault,
}) => {
  const navigate = useNavigate();

  const setTitle = (account: CompanyAccountOutput) =>
    `${account.title} ${account.isDefault ? "(Default)" : ""}`;

  const onClickCreate = () => {
    navigate("/create-billing-account");
  };

  const onClickEdit = (id: string) => {
    navigate(`/update-billing-account/${id}`);
  };

  const setActionItems = (item: CompanyAccountOutput): ItemType[] => {
    let items: ItemType[] = [
      {
        key: "1",
        label: <span>Edit</span>,
        onClick: () => onClickEdit(item.id),
      },
    ];
    if (!item.isDefault) {
      items = [
        ...items,
        {
          key: "2",
          label: <span>Make Default</span>,
          onClick: () => {
            if (!item.isDefault) {
              makeDefault(item.id);
            }
          },
        },
      ];
    }
    return items;
  };

  return (
    <div className="billingAccountsContainer">
      <div className="billingContent boxContainer">
        <Row>
          <Col span={24}>
            <Title level={2}>Accounts</Title>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <Button type="primary" onClick={onClickCreate}>
              Create New Account
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={accounts}
              renderItem={(item) => (
                <ActionBox items={setActionItems(item)}>
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          {item.title}{" "}
                          {item.isDefault && (
                            <Tooltip title="Default Account">
                              <FontAwesomeIcon
                                className="formRequiredStar"
                                icon={faStar}
                                color="var(--orange-5)"
                              />
                            </Tooltip>
                          )}{" "}
                        </div>
                      }
                      description={item?.accountNumber ?? ""}
                    />
                  </List.Item>
                </ActionBox>
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BillingAccounts;
