import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Button, Dropdown, Menu, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
  createRef,
  FC,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MessageType } from "../../../context/models";
import { Context } from "../../../context/provider";
import {
  ApplicationOutput,
  ApplicationStatus,
} from "../../../models/company.models";
import { helpers } from "../../../utils/helpers";
import "./applicantApplications.scss";
import { dateFormat } from "../../../utils/date";

interface ApplicantApplicationsProps {
  applications: ApplicationOutput[];
  deleteApplication: (id: string) => void;
}

interface DataType {
  key: React.Key;
  logo: ReactNode;
  company: string;
  internship: string;
  status: ApplicationStatus;
  date: string;
  payment: ReactNode;
  paymentId: string;
  actions: ReactNode;
}

const ApplicantApplications: FC<ApplicantApplicationsProps> = ({
  applications,
  deleteApplication,
}) => {
  const { sendMessage, sendRating, Confirm } = useContext(Context);
  const [data, setData] = useState<DataType[]>([]);
  const { getFirstLetterOfDisplayname, uniqueObjectArray } = helpers;
  const refs: RefObject<HTMLDivElement>[] = applications.map((child) =>
    createRef<HTMLDivElement>()
  );
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onDelete = (id: string) => {
    Confirm("Are you sure?", () => {
      deleteApplication(id);
    });
  };

  const showSendMessagePopup = () => {
    const companyIdForSendMessage = searchParams?.get("companyIdForMessage");
    if (companyIdForSendMessage) {
      sendMessage(companyIdForSendMessage, MessageType.Create);
    }
  };

  const showRatingPopup = () => {
    const ratingInternshipId = searchParams?.get("ratingInternshipId");
    const ratingCompanyId = searchParams?.get("ratingCompanyId");
    if (ratingInternshipId && ratingCompanyId) {
      sendRating(ratingInternshipId, ratingCompanyId);
    }
  };

  const scrollToApplication = () => {
    const applicationId = searchParams?.get("applicationId");
    let matched = applications.findIndex((child) => child.id === applicationId);
    const ref = refs[matched];
    if (matched !== -1 && ref.current && applicationId) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
      ref.current.parentElement?.parentElement?.classList.add(
        "matchedApplication"
      );
      setTimeout(() => {
        ref.current?.parentElement?.parentElement?.classList.remove(
          "matchedApplication"
        );
        navigate("/bookings");
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToApplication();
      showSendMessagePopup();
      showRatingPopup();
    }, 300);
  }, [applications]);

  const setActions = (item: ApplicationOutput): ItemType[] => {
    let output: ItemType[] = [
      {
        key: "1",
        label: "Send Message",
        onClick: () =>
          sendMessage(item?.internship?.company?.id, MessageType.Create),
      },
    ];
    if (item.status === ApplicationStatus.Pending) {
      output = [
        ...output,
        {
          key: "2",
          label: "Delete",
          onClick: () => onDelete(item.id),
        },
      ];
    }
    if (item.status === ApplicationStatus.Approved) {
      output = [
        ...output,
        {
          key: "3",
          label: "Make Payment",
          onClick: () => navigate(`/earnings?application_id=${item.id}`),
        },
      ];
    }
    if (item.status === ApplicationStatus.Completed) {
      output = [
        ...output,
        {
          key: "4",
          label: "Review Company",
          onClick: () =>
            sendRating(item.internship.id, item.internship.company.id),
        },
      ];
    }
    return output;
  };

  const setStatusClassname = (
    status: ApplicationStatus
  ):
    | "pendingApplication"
    | "declinedApplication"
    | "approvedApplication"
    | "activeApplication"
    | "completedApplication" => {
    let classname: ReturnType<typeof setStatusClassname> = "pendingApplication";

    if (status === ApplicationStatus.Pending) {
      classname = "pendingApplication";
    } else if (status === ApplicationStatus.Rejected) {
      classname = "declinedApplication";
    } else if (status === ApplicationStatus.Approved) {
      classname = "approvedApplication";
    } else if (status === ApplicationStatus.Active) {
      classname = "activeApplication";
    } else if (status === ApplicationStatus.Completed) {
      classname = "completedApplication";
    }

    return classname;
  };

  useEffect(() => {
    setData(
      applications?.map((child, index) => ({
        key: child.id,
        date: new Date(child.createdAt).toDateString(),
        internship: child?.internship?.title ?? "",
        status: child?.status ?? "",
        paymentId: child.transactionResponse
          ? JSON.parse(child.transactionResponse)?.id
          : "-",
        actions: (
          <Dropdown
            overlay={<Menu items={setActions(child)} />}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button
              icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
              size="small"
            />
          </Dropdown>
        ),
        company: child?.internship?.company?.name,
        logo: (
          <Avatar
            src={child?.internship?.company?.logoURL}
            shape="circle"
            size="small"
            ref={refs[index]}
          >
            {getFirstLetterOfDisplayname(
              child?.internship?.company?.name ?? "C"
            )}
          </Avatar>
        ),
        payment: (
          <div className="badgeContainer">
            <Tooltip
              title={
                !child.isPaymentComplete &&
                child.status === ApplicationStatus.Approved &&
                child.approvalDate &&
                `Payment due: ${dateFormat(child.approvalDate)}`
              }
            >
              {child.isPaymentComplete ? (
                <div className="badge paid">Paid</div>
              ) : (
                <div className="badge unpaid">Un-paid</div>
              )}
            </Tooltip>
          </div>
        ),
      })) ?? []
    );
  }, [applications]);

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "logo",
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "company",
      filterMode: "tree",
      filters: uniqueObjectArray(
        applications.map((child) => ({
          text: child.internship?.company?.name,
          value: child.internship?.company?.name,
        }))
      ),
      onFilter: (value, record) => record.company === value,
      sorter: (a, b) => a.company.localeCompare(b.company),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Applied for",
      dataIndex: "internship",
      filterMode: "tree",
      filters: uniqueObjectArray(
        applications.map((child) => ({
          text: child.internship?.title,
          value: child.internship?.title,
        }))
      ),
      onFilter: (value, record) => record.internship === value,
      sorter: (a, b) => a.internship.localeCompare(b.internship),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      filterMode: "tree",
      filters: [
        {
          text: "Pending",
          value: ApplicationStatus.Pending,
        },
        {
          text: "Active",
          value: ApplicationStatus.Active,
        },
        {
          text: "Approved",
          value: ApplicationStatus.Approved,
        },
        {
          text: "Completed",
          value: ApplicationStatus.Completed,
        },
        {
          text: "Declined",
          value: ApplicationStatus.Rejected,
        },
      ],
      onFilter: (value: any, record) => record.status.indexOf(value) === 0,
      render: (_, { status }) => (
        <div className="badgeContainer">
          <div className={`badge ${setStatusClassname(status)}`}>
            {status ?? ""}
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (value) =>
        new Date(value).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Payment",
      dataIndex: "payment",
    },
    {
      title: "Payment Id",
      dataIndex: "paymentId",
    },
    {
      title: "",
      dataIndex: "actions",
      fixed: "right",
      width: "50px",
    },
  ];

  return (
    <div className="applicantApplicationsContainer">
      <div className="listContainer boxContainer">
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ApplicantApplications;
