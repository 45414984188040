import { FC, useContext, useEffect, useMemo, useState } from 'react';
import BillingAccounts from '../../components/companyComponents/billingAccounts';
import PageLoader from '../../components/pageLoader';
import { AlertType } from '../../context/models';
import { Context } from '../../context/provider';
import { CompanyAccountOutput } from '../../models/company.models';
import CompanyService from '../../services/company';
import { printError } from '../../utils/logger';
import NotFoundPage from '../notFoundPage';

const BillingAccountsPage: FC = () => {
    const { currentUser, Alert, Confirm } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState<CompanyAccountOutput[]>([]);
    const [accountsUpdated, setAccountsUpdated] = useState(false);
    const companyService = useMemo(() => {
        return new CompanyService();
    }, []);

    const getAccounts = async () => {
        try {
            const res = await companyService.getAllAccounts();
            const defaultAccounts = res.filter((child) => child.isDefault);
            const notDefaultAcconuts = res.filter((child) => !child.isDefault);
            const allAccounts = [ ...defaultAccounts, ...notDefaultAcconuts ];
            setAccounts(allAccounts);
            setLoading(false);
        } catch (error) {
            printError([error]);
        }
    };

    const makeDefault = (id: string) => {
        Confirm('Are you sure?', async () => {
            try {
                await companyService.makeDefaultAccount(id);
                Alert('The account made as default successfully.', AlertType.Success);
                setAccountsUpdated((prevState) => !prevState);
            } catch (error) {
                Alert((error as Error).message, AlertType.Error);
            }
        });
    };

    useEffect(() => {
        getAccounts();
    }, [accountsUpdated]);

    if (loading) {
        return <PageLoader avatar active />;
    }

    if (!currentUser.isCompany) {
        return <NotFoundPage />;
    }

  return (
    <BillingAccounts accounts={accounts} makeDefault={makeDefault} />
  );
};

export default BillingAccountsPage;