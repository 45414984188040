import ResetExistPassword from '../../components/resetExistPassword';
import { FC, useMemo } from 'react';
import AccountService from '../../services/account';
import { ResetExistingPasswordInput } from '../../models/account.models';

const ResetExistPasswordPage: FC = () => {
  const authService = useMemo(() => {
    return new AccountService();
  }, []);

  const onSubmit = async (
    args: ResetExistingPasswordInput,
    successCallback: () => void,
    errCallback: (err: Error) => void
  ) => {
    try {
      await authService.resetExistingPassword(args);
      successCallback();
    } catch (error) {
      errCallback(error as Error);
    }
  };

  return <ResetExistPassword onSubmit={onSubmit} />;
};

export default ResetExistPasswordPage;
