import { FC } from "react";
import { Layout, Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import MenuContent from "../menuContent";
import { CurrentUserModel } from "../../../context/models";

interface IHeaderProps {
  isHeaderVisible: () => boolean;
  showSidebar: boolean;
  onClickBurgerMenu: () => void;
  showLightLogo: () => boolean;
  currentUser: CurrentUserModel;
  isDeviceDesktop: boolean;
  closeSidebar: () => void;
}

const { Header } = Layout;

const { Title } = Typography;

const AppHeader: FC<IHeaderProps> = ({
  isHeaderVisible,
  showSidebar,
  onClickBurgerMenu,
  showLightLogo,
  currentUser,
  isDeviceDesktop,
  closeSidebar,
}) => {
  const navigate = useNavigate();

  return (
    <Header className={`header ${isHeaderVisible() && "showHeader"}`}>
      <Button
        icon={
          <FontAwesomeIcon
            icon={!showSidebar ? faBars : faTimes}
            size="2x"
            color="#3571fb"
          />
        }
        className="mobileMenu"
        onClick={onClickBurgerMenu}
      />
      <img
        src={showLightLogo() ? "/logo-light.png" : "/logo.png"}
        alt="logo"
        className="logo"
        onClick={() => [navigate("/"), closeSidebar()]}
      />
      {showLightLogo() && (
        <Title level={5}>A new way to get an internship.</Title>
      )}
      <div className="menu">
        <MenuContent
          currentUser={currentUser}
          isHeaderVisible={isHeaderVisible}
          isDeviceDesktop={isDeviceDesktop}
          showLightLogo={showLightLogo}
        />
      </div>
    </Header>
  );
};

export default AppHeader;
