import { Input, Modal } from "antd";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { AlertType, MessageType } from "../../context/models";
import { Context } from "../../context/provider";
import { CreateMessageInput } from "../../models/message.models";
import MessageService from "../../services/message";
import "./messageBox.scss";

const MessageBox: FC = () => {
  const { message, Alert, setUpdatedMessages } = useContext(Context);
  const messageService = useMemo(() => {
    return new MessageService();
  }, []);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSubject(message.subject);
  }, [message.subject]);

  const onClickOk = async () => {
    if (!subject || !body) {
      Alert("Please fill subject and message fields!", AlertType.Warning);
      return;
    }
    try {
      if (message.toId) {
        setLoading(true);
        let args: CreateMessageInput = {} as CreateMessageInput;
        args = { ...args, body, subject, toAccountId: message.toId };
        await messageService.createMessage(args);
        Alert("Your message was sent successfully.", AlertType.SuccessForMessageSending);
        setSubject("");
        setBody("");
        setUpdatedMessages((prevState) => ++prevState);
        message.onClose();
        setLoading(false);
      }
    } catch (error) {
      Alert((error as Error).message, AlertType.Error);
      setLoading(false);
    }
  };

  return (
    <Modal
      className="messageBox"
      title={message.type === MessageType.Create ? "Create Message" : "Reply"}
      open={message.show}
      onOk={onClickOk}
      onCancel={message.onClose}
      okButtonProps={{
        loading,
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <Input
        value={subject}
        placeholder="Subject"
        onChange={(e) => setSubject(e.target.value)}
      />
      <textarea
        placeholder="Message"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        className="richTextEditor"
      />
    </Modal>
  );
};

export default MessageBox;
