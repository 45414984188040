export default class StorageModel {
    private _document: any;
    constructor(document?: any) {
        this._document = document;
    }

    setCookie(cname: string, cvalue: string, exdays?: number) {
        if (exdays) {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            const expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
        } else {
            document.cookie = cname + '=' + cvalue + ';path=/';
        }
    }

    getCookie(cname:  string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    deleteCookie(cname: string) {
        document.cookie = cname + '=;path=/';
    }
}