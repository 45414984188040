import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography, Button, Col, Row, Avatar } from "antd";
import { FC, Fragment, ReactNode } from "react";
import {
  ApplicantOutput,
  EducationParsedModel,
} from "../../../models/applicant.models";
import { helpers } from "../../../utils/helpers";
import "./applicantProfile.scss";

interface ApplicantProfileProps {
  applicantProfileInfo: ApplicantOutput;
  children?: ReactNode;
}

const { Title, Text } = Typography;

const ApplicantProfile: FC<ApplicantProfileProps> = ({
  applicantProfileInfo,
  children,
}) => {
  const { getFirstLetterOfDisplayname } = helpers;

  const parsedEducation: EducationParsedModel[] =
    applicantProfileInfo?.education
      ? JSON.parse(applicantProfileInfo.education)
      : [];

  return (
    <div className="applicantProfileContainer">
      <div className="profileContent">
        <Title level={4}>PERSONAL INFO</Title>
        <div className="row">
          <div className="col">
            <Avatar
              src={applicantProfileInfo?.profilePhotoURL}
              shape="square"
              size="small"
            >
              {getFirstLetterOfDisplayname(
                applicantProfileInfo?.firstName ?? "A"
              )}
            </Avatar>
          </div>
          <div className="col">
            <Title level={5}>FIRST NAME</Title>
            <Text>{applicantProfileInfo?.firstName ?? ""}</Text>
          </div>
          <div className="col">
            <Title level={5}>LAST NAME</Title>
            <Text>{applicantProfileInfo?.lastName ?? ""}</Text>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Title level={5}>GENDER</Title>
            <Text>{applicantProfileInfo?.gender ?? ""}</Text>
          </div>
          <div className="col">
            <Title level={5}>NATIONALITY</Title>
            <Text>{applicantProfileInfo?.nationality ?? ""}</Text>
          </div>
          <div className="col">
            <Title level={5}>BIRTH DATE</Title>
            <Text>
              {new Date(applicantProfileInfo?.birthDate).getFullYear() ?? ""}
            </Text>
          </div>
        </div>
        <Divider />
        <Title level={4}>CONTACT INFO</Title>
        <div className="row">
          <div className="col">
            <Title level={5}>LOCATION</Title>
            <Text>{applicantProfileInfo?.city ?? ""}</Text>
          </div>
          <div className="col">
            <Title level={5}>ZIP CODE</Title>
            <Text>{applicantProfileInfo?.zipCode ?? ""}</Text>
          </div>
          <div className="col">
            <Title level={5}>PHONE</Title>
            <Text>{applicantProfileInfo?.phone ?? ""}</Text>
          </div>
        </div>
        <Divider />
        <Title level={4}>ABOUT ME</Title>
        <div className="descriptionContainer">
          <div className="description">
            <Text>{applicantProfileInfo?.about ?? ""}</Text>
          </div>
        </div>
        <Divider />
        <Title level={4}>EDUCATION</Title>
        {parsedEducation?.map((child) => (
          <Row>
            <Col span={8}>
              <Title level={5}>SCHOOL</Title>
              <Text>{child.school ?? ""}</Text>
            </Col>
            <Col span={8}>
              <Title level={5}>DEGREE</Title>
              <Text>{child.degree ?? ""}</Text>
            </Col>
            <Col span={8}>
              <Title level={5}>FIELD OF STUDY</Title>
              <Text>{child.fieldOfStudy ?? ""}</Text>
            </Col>
          </Row>
        ))}
        {applicantProfileInfo?.resumeURL && (
          <Fragment>
            <Divider />
            <Row>
              <Title level={4}>RESUME</Title>
            </Row>
            <Row>
              <a
                href={applicantProfileInfo?.resumeURL}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" icon={<FontAwesomeIcon icon={faEye} />}>
                  Preview
                </Button>
              </a>
            </Row>
          </Fragment>
        )}
        <div className="children">{children}</div>
      </div>
    </div>
  );
};

export default ApplicantProfile;
